import React from 'react';
import { Button, Form, Row, Col, InputGroup, Table } from 'react-bootstrap';
import { TagsInput } from "react-tag-input-component";
import Select from 'react-select';

// icon
import Icon from '@mdi/react';
import { mdiPlus } from '@mdi/js';
import { toast } from 'react-toastify';

export default function FormInputs({ rows, handleInputChange, handleSubmit, Buttons }) {

    const handleonExisting = () =>{
          toast.error("Tag already exists");
    };
    
    return (
        <>
            <Form onSubmit={handleSubmit}>
                {rows.map((row, rowIndex) => (
                    <Row key={rowIndex}>
                        {row.columns.map((col, colIndex) => (
                            <Col key={colIndex} {...col.size}>
                                {col.type === 'selectwithbtn' ? (
                                    <div className={`mb-3 d-flex ${col.errormsg ? 'error-input' : ''}`}>
                                        <div className="custom-form-input w-100">
                                            <Form.Label>{col.label}</Form.Label>
                                            <Form.Select
                                                name={col.name}
                                                id={col.id}
                                                value={col.value}
                                                className={col.aditionalClass || ''}
                                                disabled={col.disabled}
                                                onChange={(e) => handleInputChange(row, col.name, e.target.value)}
                                            >
                                                {col.options.map((option, optIndex) => (
                                                    <option key={optIndex} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                        </div>
                                        <Button
                                            variant="light custom-form-input-btn ms-2 mt-2"
                                            onClick={col.handleShow}
                                        >
                                            <Icon path={mdiPlus} size={1} />
                                        </Button>
                                        {col.errormsg && (
                                            <span className="input-error-msg">{col.errormsg}</span>
                                        )}
                                    </div>
                                ) : col.type === 'chargesInput' ? (
                                    <InputGroup className="mb-3 custom-form-input">
                                        <Form.Control
                                            type="number"
                                            value={col.value}
                                            name={col.name}
                                            id={col.id}
                                            disabled={col.disabled}
                                            className={col.aditionalClass || ''}
                                            onChange={(e) => handleInputChange(row, col.name, e.target.value)}
                                        />
                                        <Form.Label>{col.label}</Form.Label>
                                        <Form.Select
                                            name={col.name}
                                            id={col.id}
                                            value={col.value}
                                            disabled={col.disabled}
                                            className={col.aditionalClass || ''}
                                            onChange={(e) => handleInputChange(row, col.name, e.target.value)}
                                        >
                                            {col.options.map((option, optIndex) => (
                                                <option key={optIndex} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </InputGroup>
                                ) : col.type === 'multiSelect' ? (
                                    <div className="mb-3 custom-form-input">
                                        <Form.Label className='z-1'>{col.label}</Form.Label>
                                        <Select
                                            options={col.options}
                                            defaultValue={col.value}
                                            placeholder={`Select ${col.label}...`}
                                            name={col.name}
                                            id={col.id}
                                            className={col.aditionalClass || ''}
                                            onChange={(e) => handleInputChange(row, col.name, e)}
                                            isMulti
                                            isDisabled={col.disabled}
                                        />
                                        {col.errormsg && (
                                            <span className="input-error-msg">{col.errormsg}</span>
                                        )}
                                    </div>
                                ) : col.type === 'tagInputs' ? (
                                    <div className="mb-3 custom-form-input">
                                        <Form.Label>{col.label}</Form.Label>
                                        <TagsInput
                                            value={col.value}
                                            id={col.id}
                                            onChange={(newTags) => handleInputChange(row, col.name, newTags)}
                                            name={col.name}
                                            placeHolder={col.placeholder}
                                            onExisting = {handleonExisting}
                                            beforeAddValidate={(tag, existingTags) => {
                                            // Check if the tag is empty or contains only spaces
                                            if (tag.trim() === '') {
                                             toast.error("Tag cannot be empty or contain only spaces.");
                                              return false; // Don't allow the tag to be added
                                            }
                                                return true; // Allow the tag to be added if it passes validation
                                            }}
                                            
                                            />
                                        {col.errormsg && (
                                            <span className="input-error-msg">{col.errormsg}</span>
                                        )}
                                    </div>
                                ) : col.type === 'checkboxes' ? (
                                    <ul key={colIndex} className={`list-inline mb-3 ${col.class ? col.class : ''}`}>
                                        {col.options.map((option, optIndex) => (
                                            <li key={optIndex} className="list-inline-item me-4">
                                                <Form.Check
                                                    type="checkbox"
                                                    label={option.label}
                                                    value={option.value}
                                                    checked={col.value === option.value}
                                                    onChange={(e) => handleInputChange(row, col.name, e.target.value)}
                                                    id={`${col.name}-${option.value}`}
                                                    disabled={col.disabled}
                                                />
                                            </li>
                                        ))}
                                    </ul>
                                ) : col.type === 'radios' ? (
                                    <ul key={colIndex} className={`list-inline mb-3 ${col.class ? col.class : ''}`}>
                                        {col.options.map((option, index) => (
                                            <li key={index} className="list-inline-item me-4">
                                                <Form.Check
                                                    key={index}
                                                    type="radio"
                                                    label={option.label}
                                                    value={option.value}
                                                    checked={col.value === option.value}
                                                    onChange={(e) => handleInputChange(row, col.name, e.target.value)}
                                                    id={`${col.name}-${option.value}`}
                                                    name={col.name}
                                                    disabled={col.disabled}
                                                />
                                            </li>
                                        ))}
                                    </ul>
                                ) : col.type === 'table' ? (
                                    <Table responsive bordered size={col.tableSize}>
                                        <thead className='table-light'>
                                            <tr>
                                                {col.thead.map((header, index) => (
                                                    <th key={index}>{header}</th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {col.tbodydata.map((row, index) => (
                                                <tr key={index}>
                                                    {Object.keys(row).map((key, subIndex) => (
                                                        <td key={subIndex}>{row[key]}</td>
                                                    ))}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                ) : (
                                    <div className={`mb-3 custom-form-input ${col.errormsg ? 'error-input' : ''}`}>
                                        <Form.Label>{col.label}</Form.Label>
                                        {col.type === 'text' && (
                                            <Form.Control
                                                type="text"
                                                placeholder={col.placeholder}
                                                name={col.name}
                                                id={col.id}
                                                value={col.value}
                                                className={col.aditionalClass || ''}
                                                disabled={col.disabled}
                                                onChange={(e) => handleInputChange(row, col.name, e.target.value)}
                                                onKeyDown={(e) => col.onKeyPress ? col.onKeyPress(e) : undefined}
                                            />
                                        )}
                                        {col.type === 'email' && (
                                            <Form.Control
                                                type="email"
                                                placeholder={col.placeholder}
                                                name={col.name}
                                                id={col.id}
                                                value={col.value}
                                                className={col.aditionalClass || ''}
                                                disabled={col.disabled}
                                                onChange={(e) => handleInputChange(row, col.name, e.target.value)}
                                            />
                                        )}
                                        {col.type === 'password' && (
                                            <Form.Control
                                                type="password"
                                                placeholder={col.placeholder}
                                                name={col.name}
                                                id={col.id}
                                                value={col.value}
                                                className={col.aditionalClass || ''}
                                                disabled={col.disabled}
                                                onChange={(e) => handleInputChange(row, col.name, e.target.value)}
                                            />
                                        )}
                                        {col.type === 'number' && (
                                            <Form.Control
                                                type="number"
                                                placeholder={col.placeholder}
                                                name={col.name}
                                                id={col.id}
                                                value={col.value}
                                                className={col.aditionalClass || ''}
                                                disabled={col.disabled}
                                                onChange={(e) => handleInputChange(row, col.name, e.target.value)}
                                            />
                                        )}
                                        {col.type === 'date' && (
                                            <Form.Control
                                                type="date"
                                                name={col.name}
                                                id={col.id}
                                                value={col.value}
                                                className={col.aditionalClass || ''}
                                                disabled={col.disabled}
                                                max = '9999-12-31' // Restricting year to 4 digits 
                                                onChange={(e) => handleInputChange(row, col.name, e.target.value)}
                                            />
                                        )}
                                        {col.type === 'select' && (
                                            <Form.Select
                                                name={col.name}
                                                id={col.id}
                                                value={col.value}
                                                className={col.aditionalClass || ''}
                                                disabled={col.disabled}
                                                onChange={(e) => handleInputChange(row, col.name, e.target.value)}
                                            >
                                                {col.options.map((option, optIndex) => (
                                                    <option key={optIndex} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                        )}
                                        {col.type === 'textarea' && (
                                            <textarea
                                                className={`form-control ${col.aditionalClass || ''}`}
                                                id={col.id}
                                                name={col.name}
                                                placeholder={col.placeholder}
                                                rows={col.formrow || 3}
                                                value={col.value}
                                                disabled={col.disabled}
                                                onChange={(e) => handleInputChange(row, col.name, e.target.value)}
                                            ></textarea>
                                        )}
                                        {col.type === 'file' && (
                                            <Form.Control
                                                type="file"
                                                name={col.name}
                                                accept={col.accept}
                                                id={col.id}
                                                className={col.aditionalClass || ''}
                                                disabled={col.disabled}
                                                onChange={(e) => handleInputChange(row, col.name, e.target.value)}
                                            />
                                        )}
                                        {col.errormsg && (
                                            <span className="input-error-msg">{col.errormsg}</span>
                                        )}
                                    </div>
                                )}

                            </Col>

                        ))}
                    </Row>
                ))}
                <div className="text-end btn-list">
                    {Buttons.map((button, index) => (
                        <Button key={index} variant={button.variant} onClick={button.onClick} className="ms-2">{button.text}</Button>
                    ))}
                </div>
            </Form>
        </>
    )
}
