import React, { useContext, useState, useEffect } from 'react';
import { Card, Button, Table } from 'react-bootstrap';

import AccountCategoryData from "./account-category-data.json";

// Icon
import Icon from '@mdi/react';
import { mdiSquareEditOutline, mdiTrashCanOutline } from '@mdi/js';
import { getFromAPI, deleteFromAPI, showAlert, putToAPI, formatISODateWithTimezone, handleDateFormat } from '../../../Utils/utils';
import { toast } from "react-toastify";
import { AccountCategoryProvider, useAccountCategory } from './getAccountCategory';
import { accountCategoryContext1 } from './accountCategoryContext';
import FormModal from '../../../Utils/FormModal';

export default function ViewAccountCategory({setSelectedAccountCategory}) {
    const selectedAccountCategory = useContext(accountCategoryContext1)
    const [tableData, setTableData] = useState([]);
    const { accountCategoryData,fetchAccountCategoryData } = useAccountCategory();
    const [account, setAccount] = useState([]);
    const [selectedAccountType, setSelectedAccountType] = useState(null);
    const initialInputData = {
        name: '',
        code: 'NA',
        index_no: 0.0,
        bs_anx: 0,
        account_master_id: 0,
        type: 0,
        created_branch_id: 0,
        created_user_id: 0,
        last_modfied_user_id: 0,
        created_date_time: formatISODateWithTimezone(new Date()),
        last_modified_date_time: formatISODateWithTimezone(new Date),
        isdeleted: 0,
        reserve_varchar1: 'NA',
        reserve_varchar2: 'NA',
        reserve_float1: 0.0,
        reserve_float2: 0.0,
        reserve_int1: 0,
        reserve_int2: 0,
        reserve_double1: 0,
        reserve_date1: 0.0
    }

    const [inputData, setInputData] = useState(initialInputData)

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getFromAPI(`FtsAccountCategory/?id=${selectedAccountCategory}`);
                if (response && response.length > 0) {
                    setInputData({
                        name: response[0].name,
                        code: response[0].code,
                        account_master_id: response[0].account_master_id,
                        type: response[0].type,
                    });
                }
            } catch (e) {
                toast.error('Error fetching Account Category data');
            }
        };

        if (selectedAccountCategory) {
            fetchData();
        }
    }, [selectedAccountCategory]);

    const fetchAccountTable = async () => {
        try {
            const response = await getFromAPI(`FtsAccountCategory/?id=${selectedAccountCategory}`);
            let accountType = '';
            if (response[0].account_master_id === 1) {
                accountType = 'Trading Account';
            }
            else if (response[0].account_master_id === 2) {
                accountType = 'Balance Sheet';
            }
            else if (response[0].account_master_id === 3) {
                accountType = 'Profit and Loss';
            }

            let account = '';
            if (response[0].account_master_id === 1 && response[0].type === 1) {
                account = 'Expense';
            }
            else if (response[0].account_master_id === 1 && response[0].type === 2) {
                account = 'Income';
            }
            else if (response[0].account_master_id === 2 && response[0].type === 1) {
                account = 'Assets';
            }
            else if (response[0].account_master_id === 2 && response[0].type === 2) {
                account = 'Liabilities';
            }
            else if (response[0].account_master_id === 3 && response[0].type === 1) {
                account = 'Expense';
            }
            else if (response[0].account_master_id === 3 && response[0].type === 2) {
                account = 'Income';
            }



            const mappedData = [
                { tableheading1: "Name :", tablesubtext1: response[0].name },
                { tableheading1: "Code :", tablesubtext1: response[0].code },
                { tableheading1: "Created User Id :", tablesubtext1: response[0].created_user_id },
                { tableheading1: "Created Date Time:", tablesubtext1: handleDateFormat(formatISODateWithTimezone(response[0].created_date_time)) },
                { tableheading1: "Account Type:", tablesubtext1: accountType },
                { tableheading1: "Type :", tablesubtext1: account },

            ]
            setTableData(mappedData);
        } catch (error) {
            toast.error('Error fetching Account Category data');
            return null;
        }
    };

    useEffect(() => {
        fetchAccountTable();
    }, [selectedAccountCategory]);

    const handleDelete = async (itemId) => {
        try {
            await deleteFromAPI(`FtsAccountCategory/${itemId}/`);
            toast.success('Account Category deleted successfully');
            handleFormModalClose();
            if(accountCategoryData.length>0)
            {
                setSelectedAccountCategory(accountCategoryData[0].id);
            }
            else
            {
                setSelectedAccountCategory('');
            }
        } catch (error) {
            toast.error('Error deleting Account Category');
            return null;
        }
    };

    // Handle Delete Customer Entry
    const handleDeleteCategory = async (Id) => {
        try {
            showAlert('Are you sure you want to delete this Account Category?', 'confirm', (result) => {
                if (result) {
                    // User confirmed the deletion
                    handleDelete(Id);
                } else {
                    // User canceled the deletion
                    // You can handle this case if needed
                }
            });
        } catch (error) {
            toast.error('Error deleting Account Category', 'error');
            return null;
        }
    };

    const handleSaveChanges = async () => {

        try {
            await putToAPI(`FtsAccountCategory/${selectedAccountCategory}/`, inputData);
            toast.success('Account Category details updated successfully', 'success');
            handleFormModalClose();
            fetchAccountCategoryData();
            fetchAccountTable();
        } catch (error) {
            toast.error('Failed to update Account Category details', 'error');
            return null;
        }
    };

    // Function to handle account type selection
    const handleAccountTypeChange = (value) => {
        const selectedAccountId = value;
        setInputData({ ...inputData, account_master_id: selectedAccountId });

        // Find the selected account in the list and update the account type state
        const selectedAccount = account.find(acc => acc.id === parseInt(selectedAccountId, 10));
        if (selectedAccount) {
            setSelectedAccountType(selectedAccount.type);
        }
    };

    // Function to fetch account data
    const fetchAccountData = async () => {
        try {
            const response = await getFromAPI('FtsAccountMaster/');
            if (response) {
                setAccount(response);
            } else {
                toast.error('Failed to fetch Account data');
            }
        } catch (e) {
            toast.error('Failed to fetch Account data');
        }
    };

    // useEffect to fetch data on component mount
    useEffect(() => {
        fetchAccountData();
    }, []); // Empty dependency array ensures this runs only once on mount

    // Edit Branch modal
    const [formModalShow, setShowFormModal] = useState(false);
    const handleAcCategoryEdit = () => setShowFormModal(true);
    const handleFormModalClose = () => setShowFormModal(false);

    const handleInputChange = (index, field, value) => {
        console.log("field : ", field, "value : ", value);
        if (field === 'account_master_id') {
            if (value === '') value = 0;
            handleAccountTypeChange(value);
        }
        setInputData({ ...inputData, [field]: value });
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        setShowFormModal(false);
    };

    const rows = [
        {
            columns: [
                { size: { md: 12 }, label: 'Account Category Name', type: 'text', name: 'name', id: 'name', placeholder: 'Enter account category name...', value: inputData.name, onChange: e => setInputData({ ...inputData, name: e.target.value }), errormsg: '', },
                { size: { lg: 6 }, label: 'Code', type: 'text', name: 'code', id: 'code', placeholder: 'Enter code...', value: inputData.code, onChange: e => setInputData({ ...inputData, code: e.target.value }), errormsg: '', },
                {
                    size: { lg: 6 }, label: 'Account type', type: 'select', name: 'account_master_id', id: 'account_master_id', value: inputData.account_master_id, onChange: handleAccountTypeChange, errormsg: '',
                    options: [
                        { value: '', label: 'Select Account Type' },
                        ...account.map(item => ({ value: item.id, label: item.name }))
                    ],
                },
                {
                    size: { md: 12 }, type: 'radios', name: 'type', id: 'account-radio', value: inputData.type, class: inputData.account_master_id === 0 ? 'd-none' : '',
                    options: inputData.account_master_id !== 0 ? [
                        { value: '1', label: selectedAccountType === 2 ? 'Expense' : selectedAccountType === 1 ? 'Liability' : '' },
                        { value: '2', label: selectedAccountType === 2 ? 'Income' : selectedAccountType === 1 ? 'Assets' : '' },
                    ] : [],
                },
            ]
        }
    ];
    const Buttons = [
        { variant: "primary", text: "Update", onClick: handleSaveChanges },
        { variant: "secondary", text: "Cancel", onClick: handleFormModalClose },
    ]

    return (
        <>
            <Card className='right-content-details'>
                <Card.Body>
                    <div className='d-flex justify-content-between align-items-center mb-3'>
                        <Card.Title className='mb-0'>View Account Category</Card.Title>
                        <div>
                            <Button variant="soft-info p-0 me-3" onClick={handleAcCategoryEdit} disabled={!selectedAccountCategory} >
                                <div className='avatar avatar-sm'>
                                    <Icon path={mdiSquareEditOutline} className='avatar-icon' />
                                </div>
                            </Button>
                            <Button variant="soft-danger p-0" onClick={() => handleDeleteCategory(selectedAccountCategory)} disabled={!selectedAccountCategory}>
                                <div className='avatar avatar-sm'>
                                    <Icon path={mdiTrashCanOutline} className='avatar-icon' />
                                </div>
                            </Button>
                        </div>
                    </div>
                    <Table responsive className='table-lg border table-nowrap mb-0'>
                        <tbody>
                            {tableData.map((data, i) =>
                                <tr key={i}>
                                    <th>{data.tableheading1}</th>
                                    <td>{data.tablesubtext1}</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card >

            {/* From Modal */}
            <FormModal title="Edit Account Category" formModalShow={formModalShow} Buttons={Buttons} handleFormModalClose={handleFormModalClose} rows={rows} handleInputChange={handleInputChange} handleSubmit={handleSubmit} />
        </>
    )
}
