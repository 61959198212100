import React, { createContext, useState, useContext } from 'react';
import { getFromAPI } from '../../../Utils/utils';

// Create Context
export const PaymentModeContext = createContext();

// Custom hook to use the AccountCategoryContext
export const usePaymentMode = () => useContext(PaymentModeContext);


// Create a Context Provider component
export const PaymentModeProvider = ({ children }) => {
    const [paymentModeData, setPaymentModeData] = useState([]);

    const fetchPaymentModeData = async () => {
        try {
            const response = await getFromAPI('FtsPaymentModes/');
            setPaymentModeData(response);
        } catch (error) {
            console.error('Error fetching Payment mode data:', error);
        }
    };

    return (
        <PaymentModeContext.Provider value={{ paymentModeData, fetchPaymentModeData }}>
            {children}
        </PaymentModeContext.Provider>
    );
};
