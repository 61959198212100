import React, { useState, useEffect } from 'react';
import Footer from '../layout/Footer';
import Details from './view_customer/Details';
import Membership from './view_customer/Membership';
import Invoices from './view_customer/Invoices';
import Benefits from './view_customer/Benefits';
import Notes from './view_customer/Notes';
import CoMembers from './view_customer/CoMembers';
import Wallet from './view_customer/Wallet';
import { Container, Row, Col, Card, Form, Nav } from 'react-bootstrap';
import SimpleBarReact from "simplebar-react";
import { CustomerContext1 } from './customerContext';
import { CustomerProvider, useCustomer } from './getCustomerData';

// icon
import Icon from '@mdi/react';
import { mdiMagnify, mdiAccountDetails, mdiPlus, mdiApplicationImport } from '@mdi/js';
import { getFromAPI } from '../../Utils/utils';
import NewCustomer from './NewCustomer';
import ImportCustomer from './view_customer/ImportCustomer';

function Customers() {
    const { customerData, fetchCustomerData } = useCustomer();
    const [selectedCustomerId, setSelectedCustomerId] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    
    useEffect(() => {
        fetchCustomerData();
    }, [selectedCustomerId]);

    const filterCustomerData = customerData.filter(admin =>
        admin.name.toLowerCase().includes(searchTerm.toLowerCase())
      );

    
    useEffect(() => {
        const sidebarItems = document.querySelectorAll(".left-link-item"); // Use querySelectorAll for better targeting
    
        // Loop over all sidebar items
        sidebarItems.forEach((item) => {
            item.addEventListener('click', function () {
                // Remove active class from all sidebar links
                const activeLink = document.querySelector(".left-link-item a.active");
                if (activeLink) activeLink.classList.remove("active");
    
                // Add active class to the clicked item
                const link = this.querySelector('a');
                if (link) link.classList.add('active');
    
                // Update selected vendor ID
                const vendorId = this.getAttribute('data-id');
                setSelectedCustomerId(vendorId);
    
                // Trigger click on the "View Vendor" tab
                const viewVendorTab = document.getElementById("pills-view-customer-tab");
                if (viewVendorTab) {
                    viewVendorTab.click();  // Ensure the tab is switched programmatically
                }
    
                // Trigger click on the "pills-details-vendor" element
                const detailsVendorTab = document.getElementById("pills-details-tab");
                if (detailsVendorTab) {
                    detailsVendorTab.click();  // Ensure the second tab is switched programmatically
                }
            });
        });
    
        // Cleanup listeners on component unmount
        return () => {
            sidebarItems.forEach((item) => {
                item.removeEventListener('click', () => {});
            });
        };
    }, [filterCustomerData]);
    
    useEffect(() => {
    if (customerData.length>0&&!selectedCustomerId) {
        // No vendors available, reset selectedCustomerId
        setSelectedCustomerId(customerData[0].userid);
    }
  }, [customerData,setSelectedCustomerId]);  // Trigger on changes to 'vendor' and 'selectedCustomerId'

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const [key, setKey] = React.useState(0);

    const handleTabClick = () => {
        setKey(prevKey => prevKey + 1); // Increment the key to force remount
    };


    return (
        <>
            <CustomerContext1.Provider value={selectedCustomerId}>
            <div className="page-wrapper">
                <div className='page-content'>
                    <Container>
                        <Row>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <div className='d-lg-flex justify-content-between align-items-center'>
                                            <Form className='custom-form-width'>
                                                <div className='custom-search-bar'>
                                                    <Icon path={mdiMagnify} size={1} className="custom-search-input-icon" />
                                                    <Form.Control
                                                    type="text"
                                                    placeholder="Search customers..."
                                                    name="customersSearch"
                                                    id="customersSearch"
                                                    value={searchTerm}
                                                    onChange={handleSearchChange}
                                                />
                                                </div>
                                            </Form>
                                            <div className="custom-top-nav-tab mt-3 mt-lg-0">
                                                <ul className="nav nav-pills btn-list" id="pills-tab" role="tablist">
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link active" id="pills-view-customer-tab" data-bs-toggle="pill" data-bs-target="#pills-view-customer" type="button" role="tab" aria-controls="pills-view-customer" aria-selected="true">
                                                            <Icon path={mdiAccountDetails} className="btn-icon me-1" />View Customer
                                                        </button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link" id="pills-new-customer-tab" data-bs-toggle="pill" data-bs-target="#pills-new-customer" type="button" role="tab" aria-controls="pills-new-customer" aria-selected="false" onClick={handleTabClick}>
                                                            <Icon path={mdiPlus} className="btn-icon me-1" />New Customer
                                                        </button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link" id="pills-import-customer-tab" data-bs-toggle="pill" data-bs-target="#pills-import-customer" type="button" role="tab" aria-controls="pills-import-customer" aria-selected="false">
                                                            <Icon path={mdiApplicationImport} className="btn-icon me-1" />Import Customer
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={3}>
                                <Card className='procust-left-list leftbar-list'>
                                    <SimpleBarReact>
                                        <Card.Body>
                                            <div className='left-list-bar'>
                                                {filterCustomerData
                                                    .map((data, i) => (
                                                        <Nav.Item className='left-link-item' key={i} data-id={data.userid}> {/* Add data-id attribute */}
                                                            <Nav.Link className={i === 0 ? "active" : ""}>
                                                                <h5 className='product-tab-title'>{data.name}</h5>
                                                            </Nav.Link>
                                                        </Nav.Item>
                                                    ))}
                                            </div>
                                        </Card.Body>
                                    </SimpleBarReact>
                                </Card>
                            </Col>

                            <Col lg={9}>
                                <div className="tab-content custom-top-nav-tab" id="pills-tabContent">
                                    <div className="tab-pane fade show active" id="pills-view-customer" role="tabpanel" aria-labelledby="pills-view-customer-tab" tabIndex="0">
                                        <Card className='right-content-details'>
                                            <Card.Body>
                                                <ul className="nav nav-pills nav-fill btn-list mb-3" id="pills-tab" role="tablist">
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link active" id="pills-details-tab" data-bs-toggle="pill" data-bs-target="#pills-details" type="button" role="tab" aria-controls="pills-details" aria-selected="true">Details</button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link" id="pills-membership-tab" data-bs-toggle="pill" data-bs-target="#pills-membership" type="button" role="tab" aria-controls="pills-membership" aria-selected="false">Membership</button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link" id="pills-packages-tab" data-bs-toggle="pill" data-bs-target="#pills-packages" type="button" role="tab" aria-controls="pills-packages" aria-selected="false" disabled>Packages</button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link" id="pills-invoices-tab" data-bs-toggle="pill" data-bs-target="#pills-invoices" type="button" role="tab" aria-controls="pills-invoices" aria-selected="false">Invoices</button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link" id="pills-benefits-tab" data-bs-toggle="pill" data-bs-target="#pills-benefits" type="button" role="tab" aria-controls="pills-benefits" aria-selected="false">Benefits</button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link" id="pills-notes-tab" data-bs-toggle="pill" data-bs-target="#pills-notes" type="button" role="tab" aria-controls="pills-notes" aria-selected="false">Notes</button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link" id="pills-co-members-tab" data-bs-toggle="pill" data-bs-target="#pills-co-members" type="button" role="tab" aria-controls="pills-co-members" aria-selected="false">Co-Members</button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link" id="pills-wallet-tab" data-bs-toggle="pill" data-bs-target="#pills-wallet" type="button" role="tab" aria-controls="pills-wallet" aria-selected="false">Wallet</button>
                                                    </li>
                                                </ul>

                                                <div className="tab-content" id="pills-tabContent">
                                                    <div className="tab-pane fade show active" id="pills-details" role="tabpanel" aria-labelledby="pills-details-tab" tabIndex="0">
                                                        <Details setSelectedCustomerId={setSelectedCustomerId}/>
                                                    </div>
                                                    <div className="tab-pane fade" id="pills-membership" role="tabpanel" aria-labelledby="pills-membership-tab" tabIndex="0">
                                                        <Membership />
                                                    </div>
                                                    {/* <div className="tab-pane fade" id="pills-packages" role="tabpanel" aria-labelledby="pills-packages-tab" tabIndex="0"></div> */}
                                                    <div className="tab-pane fade" id="pills-invoices" role="tabpanel" aria-labelledby="pills-invoices-tab" tabIndex="0">
                                                        <Invoices />
                                                    </div>
                                                    <div className="tab-pane fade" id="pills-benefits" role="tabpanel" aria-labelledby="pills-benefits-tab" tabIndex="0">
                                                        <Benefits />
                                                    </div>
                                                    <div className="tab-pane fade" id="pills-notes" role="tabpanel" aria-labelledby="pills-notes-tab" tabIndex="0">
                                                        <Notes />
                                                    </div>
                                                    <div className="tab-pane fade" id="pills-co-members" role="tabpanel" aria-labelledby="pills-co-members-tab" tabIndex="0">
                                                        <CoMembers />
                                                    </div>
                                                    <div className="tab-pane fade" id="pills-wallet" role="tabpanel" aria-labelledby="pills-wallet-tab" tabIndex="0">
                                                        <Wallet />
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className="tab-pane fade" id="pills-new-customer" role="tabpanel" aria-labelledby="pills-new-customer-tab" tabIndex="0">
                                        <Card className='right-content-details'>
                                        <NewCustomer key={key} />
                                    </Card>
                                    </div>
                                    <div className="tab-pane fade" id="pills-import-customer" role="tabpanel" aria-labelledby="pills-import-customer-tab" tabIndex="0">
                                        <Card className='right-content-details'>
                                            <ImportCustomer />
                                        </Card>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer />
            </div>
            </CustomerContext1.Provider>
        </>
    )
}

export default function CustomersWithProvider() {
    return (
        <CustomerProvider>
            <Customers />
        </CustomerProvider>
    );
}