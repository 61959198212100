import React, { useState, useEffect, useContext } from 'react';
import { Card, Modal, Form, Row, Col, Button, Table } from 'react-bootstrap';

import GSTConfigurationData from "./gst-configuration-data.json";

// Icon
import Icon from '@mdi/react';
import { mdiSquareEditOutline, mdiTrashCanOutline } from '@mdi/js';
import { gstContext1 } from './gstContext';
import { GSTProvider, useGST } from './getGST';
import { toast } from "react-toastify";
import { fetchTaxData } from '../../../Utils/function_list';
import { getFromAPI, deleteFromAPI, showAlert, putToAPI, handleDateFormat, formatISODateWithTimezone } from '../../../Utils/utils';


export default function ViewTax({setSelectedGSTId}) {
    // Default Modal
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const selectedGSTId = useContext(gstContext1);
    const { gstData, fetchGSTData } = useGST();
    const [tableData, setTableData] = useState([]);

    const initialGstInputData = {
        name: '',
        taxrate: '',
        country_id: 0,
        applicable_date: formatISODateWithTimezone(new Date()),
        iscombined: 1, // Default to 1 (Simple Tax)
        child_ids: 'NA',
        isdefault: 0,
        type: 0,
        description: 'NA',
        created_branch_id: 0,
        isdeleted: 0,
        created_user_id: 0,
        last_modified_user_id: 0,
        last_modified_date_time: formatISODateWithTimezone(new Date()),
        created_date_time: formatISODateWithTimezone(new Date()),
        reserve_varchar1: 'NA',
        reservs_varchar2: 'NA',
        reserve_float1: 0.0,
        reserve_float2: 0.0,
        reserve_int1: 0,
        reserve_int2: 0,
        reserve_double1: 0.0,
        reserve_date1: '2024-09-18',

    }

    const [gstInputData, setGstInputData] = useState(initialGstInputData);
    const [countryData, setCountryData] = useState();
    const [getGST, setGST] = useState([]);

    const handleCancel = () => {
        setGstInputData(initialGstInputData);
        setTaxType('simple');
        setEnterTaxRate1('');
        setEnterTaxRate2('');
    };
    // New state variables
    const [taxType, setTaxType] = useState('simple'); // 'simple' or 'combine'
    const [enterTaxRate1, setEnterTaxRate1] = useState('');
    const [enterTaxRate2, setEnterTaxRate2] = useState('');
    const [isTaxRateDisabled, setTaxRateDisabled] = useState(false);
    const [Tax, setTax] = useState([]);

    useEffect(() => {
        fetchGSTData(setGST);
    }, [selectedGSTId]);



    useEffect(() => {
        const fetchUnitDetails = async () => {
            try {
                const response = await getFromAPI(`FtsTaxes/?id=${selectedGSTId}`);
                if (response && response.length > 0) {
                    const fetchedData = response[0];

                    // Split the child_ids and map to TaxRate1 and TaxRate2
                    const childIdsArray = fetchedData.child_ids ? fetchedData.child_ids.split(',') : [];
                    const taxRate1 = childIdsArray[0] || ''; // First ID maps to TaxRate1
                    const taxRate2 = childIdsArray[1] || ''; // Second ID maps to TaxRate2

                    // Combine the taxRate1 and taxRate2 values, separated by a comma
                    const combinedChildIds = `${enterTaxRate1},${enterTaxRate2}`;

                    // Split the IDs and map them to names
                    const names = combinedChildIds.split(',').map(id => {
                        const taxRate = Tax.find(rate => rate.id === parseInt(id.trim()));
                        return taxRate ? taxRate.name : 'NA';
                    });

                    // Join the names with ' + ' instead of a comma
                    const childDescriptionString = names.join(' + ');

                    setGstInputData({
                        name: fetchedData.name,
                        taxrate: fetchedData.taxrate,
                        type: fetchedData.type,
                        child_ids: combinedChildIds,
                        iscombined: fetchedData.iscombined, // Set the isCombined field
                        isdefault: fetchedData.isdefault,
                        created_branch_id: fetchedData.created_branch_id,
                        applicable_date: handleDateFormat(fetchedData.applicable_date),
                        isdeleted: fetchedData.isdeleted,
                    });

                    // Set the tax type based on iscombined field
                    if (fetchedData.iscombined === 1) {
                        setTaxType('simple');
                    } else if (fetchedData.iscombined === 2) {
                        setTaxType('combine');
                    }

                    // Set Tax Rate 1 and 2
                    setEnterTaxRate1(taxRate1);
                    setEnterTaxRate2(taxRate2);
                }
            } catch (error) {
                return null;
            }
        };

        if (selectedGSTId) {
            fetchUnitDetails();
        }
    }, [selectedGSTId]);

    const fetchUnitTable = async () => {
        try {
            const response = await getFromAPI(`FtsTaxes/?id=${selectedGSTId}`);
            let responseType = '';
            if (response[0].type === 1) {
                responseType = 'Inclusive';
            }
            else if (response[0].type === 2) {
                responseType = 'Exclusive';
            }
            else {
                responseType = 'Inclusive'
            }

            let iscombinedData = 'False';
            if (response[0].iscombined === 2) {
                iscombinedData = 'True';
            }
            else {
                iscombinedData = 'False';
            }
            // Map API response to match the table data structure
            const mappedData = [
                { tableheading1: "Tax Name :", tablesubtext1: response[0].name },
                { tableheading1: "Tax Rate :", tablesubtext1: response[0].taxrate },
                { tableheading1: "Combine Tax:", tablesubtext1: iscombinedData },
                { tableheading1: "Combine Tax Detail :", tablesubtext1: response[0].description },
                { tableheading1: "Default Tax :", tablesubtext1: 'NA' },
                { tableheading1: "Tax Type :", tablesubtext1: responseType },

            ]

            setTableData(mappedData); // Update state with the mapped data
        } catch (error) {
            return null;
        }
    };

    useEffect(() => {
        fetchUnitTable();
        fetchGSTData();
    }, [selectedGSTId]);

    // Handle Tax Type Change (Simple or Combine)
    const handleTaxTypeChange = (e) => {
        const selectedTaxType = e.target.value;
        setTaxType(selectedTaxType);

        if (selectedTaxType === 'simple') {
            setGstInputData({
                ...gstInputData,
                iscombined: 1,
                child_ids: '',
            });
        } else if (selectedTaxType === 'combine') {
            setGstInputData({
                ...gstInputData,
                iscombined: 2,
                taxrate: '', // Clear tax rate for combine tax
            });
        }
    };

    // Handle checkbox change
    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setGstInputData({
            ...gstInputData,
            [name]: checked ? 1 : 0,
        });
    };

    // Handle input changes for GST data
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setGstInputData({
            ...gstInputData,
            [name]: value,
        });
    };

    useEffect(() => {
        fetchTaxData(setTax);
    }, []);

    const handleDelete = async (itemId) => {
        try {
            const response = await deleteFromAPI(`FtsTaxes/${itemId}/`);
            if (response) {
                toast.success('Gst deleted successfully', 'success');
                fetchGSTData(); // Update the customer list
                fetchUnitTable();
                if(gstData.length>0)
                {
                    setSelectedGSTId(gstData[0].id);
                }
                else
                {
                    setSelectedGSTId('');
                }
            }
        } catch (error) {
            toast.error('GST Not Deleted', 'error');
            return null;
        }

    };

    // Handle Delete Customer Entry
    const handleDeleteGST = async (Id) => {
        try {
            showAlert('Are you sure you want to delete this GST?', 'confirm', (result) => {
                if (result) {
                    // User confirmed the deletion
                    handleDelete(Id);
                } else {
                    // User canceled the deletion
                    // You can handle this case if needed
                }
            });
        } catch (error) {
            toast.error('Error deleting GST', 'error');
            return null;
        }
    };

    const handleEdit = () => {
        setShow(true);
    };

    const handleSaveChanges = async () => {

        try {
            await putToAPI(`FtsTaxes/${selectedGSTId}/`, gstInputData);
            toast.success('GST details updated successfully', 'success');
            setShow(false);
            fetchGSTData();
            fetchTaxData(); // Update the customer list
            fetchUnitTable();
        } catch (error) {
            console.error('Error updating GST details:', error);
            toast.error('Failed to update GST details', 'error');
        }
    };

    // table data
    const [gstConfigurationTableData] = useState(GSTConfigurationData);

    return (
        <>
            <Card className='right-content-details'>
                <Card.Body>
                    <div className='d-flex justify-content-between align-items-center mb-3'>
                        <Card.Title className='mb-0'>View Tax</Card.Title>
                        <div>
                            <Button variant="soft-info p-0 me-3" onClick={handleEdit} disabled={!selectedGSTId} >
                                <div className='avatar avatar-sm'>
                                    <Icon path={mdiSquareEditOutline} className='avatar-icon' />
                                </div>
                            </Button>
                            <Button variant="soft-danger p-0" onClick={() => handleDeleteGST(selectedGSTId)} disabled={!selectedGSTId}>
                                <div className='avatar avatar-sm'>
                                    <Icon path={mdiTrashCanOutline} className='avatar-icon' />
                                </div>
                            </Button>
                        </div>
                    </div>

                    <Table responsive className='table-lg border table-nowrap mb-0'>
                        <tbody>
                            {tableData.map((data, i) =>
                                <tr key={i}>
                                    <th>{data.tableheading1}</th>
                                    <td>{data.tablesubtext1}</td>
                                    <th>{data.tableheading2}</th>
                                    <td>{data.tablesubtext2}</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>

            <Modal centered show={show} size="xl" onHide={handleClose}>
                <Modal.Header className='custom-close-btn' closeButton></Modal.Header>
                <Modal.Body>
                    <h5 className='mb-4'>Edit Tax</h5>

                    <Form>
                        <Row className='align-items-center'>
                            <Col xl={8}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Tax Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder='Enter tax name...'
                                        name='name'
                                        id='name'
                                        value={gstInputData.name}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </Col>
                            <Col xl={4}>
                                <ul className='list-inline text-xl-center my-3'>
                                    <li className='list-inline-item me-4'>
                                        <Form.Check
                                            type="radio"
                                            name="Tax"
                                            id="simple-tax-check"
                                            label="Simple Tax"
                                            value="simple"
                                            checked={taxType === 'simple'}
                                            onChange={handleTaxTypeChange}
                                        />
                                    </li>
                                    <li className='list-inline-item'>
                                        <Form.Check
                                            type="radio"
                                            name="Tax"
                                            id="combine-tax-check"
                                            label="Combine Tax"
                                            value="combine"
                                            checked={taxType === 'combine'}
                                            onChange={handleTaxTypeChange}
                                        />
                                    </li>
                                </ul>
                            </Col>
                            {/* Tax Rate */}
                            <Col xl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Tax Rate</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter tax rate..."
                                        name="taxrate"
                                        value={gstInputData.taxrate}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </Col>
                            {/* Tax Rate 1 and Tax Rate 2 */}
                            <Col md={6} xl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Tax Rate 1</Form.Label>
                                    <Form.Select
                                        name="enterTaxRate1"
                                        id="enterTaxRate1"
                                        onChange={(e) => setEnterTaxRate1(e.target.value)} // Bind to state
                                        disabled={gstInputData.iscombined === 1}  // Disabled for Combine Tax
                                        value={enterTaxRate1}
                                    >
                                        <option value="">Select Tax Rate 1</option>
                                        {Tax.map(item => (
                                            <option key={item.id} value={item.id}>{item.name}</option>
                                        ))}
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col md={6} xl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Tax Rate 2</Form.Label>
                                    <Form.Select
                                        name="taxRate2"
                                        id="enterTaxRate2"
                                        disabled={gstInputData.iscombined === 1}  // Disabled for Combine Tax
                                        onChange={(e) => setEnterTaxRate2(e.target.value)} // Bind to state
                                        value={enterTaxRate2}                                                                    >
                                        <option value="">Select Tax Rate 2</option>
                                        {Tax.map(item => (
                                            <option key={item.id} value={item.id}>{item.name}</option>
                                        ))}
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col sm={12}>
                                <ul className='list-inline mb-3'>
                                    <li className='list-inline-item me-4'>
                                        <Form.Check
                                            type="radio"
                                            name="type"
                                            id="inclusive-check"
                                            label="Inclusive"
                                            value="1"
                                            checked={gstInputData.type === '1'}
                                            onChange={handleInputChange}
                                        />
                                    </li>
                                    <li className='list-inline-item'>
                                        <Form.Check
                                            type="radio"
                                            name="type"
                                            id="exclusive-check"
                                            label="Exclusive"
                                            value="2"
                                            checked={gstInputData.type === '2'}
                                            onChange={handleInputChange}
                                        />
                                    </li>
                                </ul>
                                <Form.Check
                                    type="checkbox"
                                    id="isdefault"
                                    name="isdefault"
                                    label={`Set this tax as default tax. It will be used by default for business.`}
                                    checked={gstInputData.isdefault === 1}
                                    onChange={handleCheckboxChange}
                                />
                            </Col>
                        </Row>
                        <div className='text-end btn-list'>
                            <Button variant="primary" onClick={handleSaveChanges}>Update</Button>{' '}
                            <Button variant="secondary" onClick={handleClose}>Cancel</Button>{' '}
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}
