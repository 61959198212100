import React, { useState, useEffect, useContext } from 'react';
import { Table, Card, Button, Form } from 'react-bootstrap';
import { getFromAPI, deleteFromAPI, showAlert, putToAPI, formatISODateWithTimezone, handleDateFormat } from '../../../Utils/utils';
// icon
import Icon from '@mdi/react';
import { mdiSquareEditOutline, mdiTrashCanOutline } from '@mdi/js';
import { CustomerContext1 } from '../customerContext';
import { useCustomer, CustomerProvider } from '../getCustomerData'; // Import the useCustomer hook
import { toast, ToastContainer } from "react-toastify";
import FormModal from '../../../Utils/FormModal';
import { fetchBankData,fetchCountryData,fetchTaxData,fetchCutsomerData,fetchBranchData } from '../../../Utils/function_list';

export default function CustomerDetails({setSelectedCustomerId}) {
    const selectedCustomerId = useContext(CustomerContext1);
    const [showEditModal, setShowEditModal] = useState(false);
    const [tableData, setTableData] = useState([]); // Initialize state with an empty array
    const { customerData,fetchCustomerData } = useCustomer();
    const [country, setCountry] = useState([]);
    const [State, setState] = useState([]);
    const [city, setCity] = useState([]);
    const [Customer, setCustomer] = useState([]);
    const [Bank, setBank] = useState([]);
    const [Tax, setTax] = useState([]);
    const [branchData, setBranchData] = useState([]);
    const getLoginBranchId = localStorage.getItem('createdBranchId');

    // Initialize branch input data state
    const [branchInputData, setBranchInputData] = useState({
        bcountryCode: '',
        bStateCode: '',
        bcityCode: ''
    });
    const [inputData, setInputData] = useState({
        name: '',
        email: '',
        phone_number2: '',
        gender: '',
        birth_date: '',
        anniversary_date: '',
        address: '',
        reffered_by_id: 0,
        gst: '',
        country: 0,
        state: 0,
        city: 0,
        tax: 0,
        pan: '',
        opening_balance: 0.0,
        bank_name: 0,
        ifsc_code: '',
        account_number: '',
    });


    useEffect(() => {
        const fetchCustomerDetails = async () => {
            try {
                const response = await getFromAPI(`FtsClients/?userid=${selectedCustomerId}`);
                if (response && response.length > 0) {
                    const customerData = response[0];
                    setInputData({
                        name: customerData.name,
                        email: customerData.email,
                        phone_number2: customerData.phone_number2,
                        gender: customerData.gender,
                        birth_date: customerData.birth_date,
                        anniversary_date: customerData.anniversary_date,
                        address: customerData.address,
                        reffered_by_id: customerData.referred_by,
                        gst: customerData.gst,
                        country: customerData.country,
                        state: customerData.state,
                        city: customerData.city,
                        tax: customerData.tax,
                        pan: customerData.pan,
                        opening_balance: customerData.opening_balance,
                        bank_name: customerData.bank_name,
                        ifsc_code: customerData.ifsc_code,
                        account_number: customerData.account_number,
                    });

                    // Fetch state based on country
                    if (customerData.country) {
                        const stateResponse = await getFromAPI(`FtsState/?country_code=${customerData.country}`);
                        setState(stateResponse); // Set state dropdown
                    }

                    // Fetch city based on state
                    if (customerData.state) {
                        const cityResponse = await getFromAPI(`FtsCity/?state_id=${customerData.state}`);
                        setCity(cityResponse); // Set city dropdown
                    }
                }
            } catch (error) {
                return null;
            }
        };

        if (selectedCustomerId) {
            fetchCustomerDetails();
        }
    }, [selectedCustomerId]);

    // Add new state for tax options
    const [taxOptions] = useState([
            { id: 1, name: 'B2G', code: 'b2c' },
            { id: 2, name: 'B2B', code: 'b2b' },
            { id: 3, name: 'B2B-IGST', code: 'b2b-igst' },
            { id: 4, name: 'B2C-IGST', code: 'b2c-igst' }
    ]);

    // Add this helper function to map tax ID to name
    const getTaxNameById = (taxID) => {
        const taxOption = taxOptions.find(option => option.id === taxID);
        return taxOption ? taxOption.name : "NA";
    };

    const fetchCustomerData1 = async () => {
        try {
            const response = await getFromAPI(`FtsClients/?userid=${selectedCustomerId}`);
            let country = response[0].country;
            const countryName = await getCountryName(country);
            const stateCode = response[0].state; // Assuming state contains the state_code
            const stateName = await getStateNameByCode(stateCode);
            const cityID = response[0].city; // Assuming city contains the id
            const cityName = await getCityByName(cityID);
            const Tax = response[0].tax
            const taxName = getTaxNameById(Tax);
            const bankName = await getBankByName(response[0].bank_name)
            const referredName = await getReferredByName(response[0].reffered_by_id)
            // Fetch state name based on state code

            // Map API response to match the table data structure
            const mappedData = [
                { customerheading1: "Customer Name :", customersubtext1: response[0].name, customerheading2: "Mobile Number :", customersubtext2: response[0].phone_number2 },
                { customerheading1: "Email :", customersubtext1: response[0].email, customerheading2: "Gender :", customersubtext2: response[0].gender },
                { customerheading1: "Birthday :", customersubtext1: response[0].birth_date, customerheading2: "Anniversary :", customersubtext2: response[0].anniversary_date },
                { customerheading1: "Address :", customersubtext1: response[0].address, customerheading2: "Referred By :", customersubtext2: referredName },
                { customerheading1: "State :", customersubtext1: stateName, customerheading2: "City :", customersubtext2: cityName },
                { customerheading1: "Tax :", customersubtext1: taxName, customerheading2: "GST :", customersubtext2: response[0].gst },
                { customerheading1: "PAN :", customersubtext1: response[0].pan, customerheading2: "Opening Balance :", customersubtext2: response[0].opening_balance },
                { customerheading1: "First Visit Date :", customersubtext1: "NA", customerheading2: "First Visit Branch :", customersubtext2: "NA" },
                { customerheading1: "Last Visit Date :", customersubtext1: "NA", customerheading2: "Last Visit Branch :", customersubtext2: "NA" },
                { customerheading1: "Last Appointment :", customersubtext1: "NA", customerheading2: "Total visits :", customersubtext2: "NA" },
                { customerheading1: "Total Service Spending :", customersubtext1: "NA", customerheading2: "Total Product Spending :", customersubtext2: "NA" },
                { customerheading1: "Total Membership Spending :", customersubtext1: "NA", customerheading2: "Current Active Membership :", customersubtext2: "NA" },
                { customerheading1: "Total Gift Card Spending :", customersubtext1: "NA", customerheading2: "Cashback Rs. :", customersubtext2: "NA" },
                { customerheading1: "Total Points :", customersubtext1: "NA", customerheading2: "Wallet Balance :", customersubtext2: "NA" },
                { customerheading1: "Total Referral :", customersubtext1: "NA", customerheading2: "Due Amount :", customersubtext2: "NA" },
                { customerheading1: "Bank Name :", customersubtext1: bankName, customerheading2: "IFSC Code :", customersubtext2: response[0].ifsc_code },
                { customerheading1: "Account Number :", customersubtext1: response[0].account_number, customerheading2: "Current balance :", customersubtext2: "NA" }
            ];

            setTableData(mappedData); // Update state with the mapped data
        } catch (error) {
            return null;
        }
    };


    useEffect(() => {
        fetchCustomerData1();
        fetchCustomerData();
    }, [selectedCustomerId]);

    useEffect(() => {
        fetchCustomerData();
    }, [selectedCustomerId]);


    // Fetch initial data
    useEffect(() => {
            fetchBranchData(setBranchData);
            fetchCountryData(setCountry);
            fetchBankData(setBank);
            fetchTaxData(setTax);
            fetchCutsomerData(setCustomer);
    }, []);

    // Handle Delete Customer Entry
    const handleDeleteLocker = async (Id) => {
        try {
            showAlert('Are you sure you want to delete this Customer?', 'confirm', (result) => {
                if (result) {
                    // User confirmed the deletion
                    handleDeleteCustomer(Id);
                } else {
                    // User canceled the deletion
                    // You can handle this case if needed
                }
            });
        } catch (error) {
            toast.error('Error deleting Customer', 'error');
            return null;
        }
    };

    // Handle input specifically for Mobile Number and Email
    const handleMobileChange = (e) => {
        const { name, value } = e.target;
        // Remove spaces from the input value
        setInputData((prev) => ({
            ...prev,
            [name]: value.replace(/\s+/g, ''), // Remove all spaces
        }));
    };

    useEffect(() => {
        const fetchCountryData = async () => {
            try {
                const response = await getFromAPI("FtsCountries/");
                setCountry(response); // Save country data
            } catch (error) {
                return null;
            }
        };

        fetchCountryData();
    }, []);
 
    const handleCountryChange = async (e) => {
        const selectedCountry = e.target.value;
        setInputData(prevState => ({ ...prevState, country: selectedCountry }));

        try {
            const response = await getFromAPI(`FtsState/?country_code=${selectedCountry}`);
            setState(response); // Save state data
            setCity([]); // Reset city data
        } catch (error) {
            return null;
        }
    };

    const handleStateChange = async (e) => {
        const selectedState = e.target.value;
        setInputData(prevState => ({ ...prevState, state: selectedState }));

        try {
            const response = await getFromAPI(`FtsCity/?state_id=${selectedState}`);
            setCity(response);
        } catch (error) {
            return null;
        }
    };

    const handleDeleteCustomer = async (itemId) => {
        try {
            const response = await deleteFromAPI(`FtsClients/userid/${itemId}/`);
            if (response) {
                toast.success('Customer deleted successfully', 'success');
                fetchCustomerData1()
                if(customerData.length>0)
                {
                    setSelectedCustomerId(customerData[0].id)
                }
                else
                {
                    setSelectedCustomerId('')
                }
                // Optionally, redirect or update state to reflect deletion
                setTableData([]); // Clear table data or handle as needed
            }
        } catch (error) {
            toast.error('Customer Not Deleted', 'error');
            return null;
        }

        fetchCustomerData(); // Update the customer list
    };

    // const handleEdit = () => {
    //     setShowEditModal(true);
    // };

    const handleSaveChanges = async () => {
        try {
            await putToAPI(`FtsClients/userid/${selectedCustomerId}/`, inputData);
            toast.success('Customer details updated successfully', 'success');
            setShowFormModal(false);
            fetchCustomerData();
            fetchCustomerData1();
        } catch (error) {
            toast.error('Failed to update customer details', 'error');
            return null;
        }
    };

    const handleCloseModal = () => {
        setShowFormModal(false);
    };

    const getStateNameByCode = async (code) => {
        if (!code) return null;
        try {
            const stateResponse = await getFromAPI(`FtsState/?state_code=${code}`);
            // Assuming stateResponse is an array of states
            if (stateResponse) {
                return stateResponse[0].state_name; // Return the state name
            } else {
                return null; // Return null if not found
            }
        } catch (error) {
            return null; // Return null in case of an error
        }
    };

    const getCityByName = async (code) => {
        if (!code) {
            return null; // Return null if code is not provided
        }
        try {
            const CityResponse = await getFromAPI(`FtsCity/?id=${code}`);

            if (CityResponse) {
                return CityResponse[0].city_name; // Return the state name
            } else {
                return null; // Return null if not found
            }
        } catch (error) {
            return null; // Return null in case of an error
        }
    };

    const getCountryName = async (code) => {
        if (!code) return null;
        try {
            const countryResponse = await getFromAPI(`FtsCountries/?calling_code=${code}`);
            if (countryResponse) {
                return countryResponse[0].short_name; // Return the state name
            } else {
                return null; // Return null if not found
            }
        } catch (error) {
            return null; // Return null in case of an error
        }
    };

    const getBankByName = async (id) => {
        if (!id) return null
        try {
            const bankResponse = await getFromAPI(`FtsBanks/?bank_id=${id}`);
            if (bankResponse) {
                return bankResponse[0].bank_name; // Return the state name
            }
            else {
                return null;
            }
        } catch (error) {
            return null; // Return null in case of an error
        }
    };

    const getReferredByName = async (code) => {
        if (!code) return null;
        try {
            const Response = await getFromAPI(`FtsClients/?userid=${code}`);
            // Assuming stateResponse is an array of states
            if (Response) {
                return Response[0].name; // Return the state name
            } else {
                return null; // Return null if not found
            }
        } catch (error) {
            return null; // Return null in case of an error
        }
    };

    // Add Invoice modal
    const [formModalShow, setShowFormModal] = useState(false);
    const handleCdEdit = () => setShowFormModal(true);
    const handleFormModalClose = () => setShowFormModal(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        setShowFormModal(false);
    };

    // Function to determine appropriate tax based on states
    const determineAppropiateTax = (customerState, branchState) => {
        if (customerState && branchState) {
            if (customerState === branchState) {
                return taxOptions.find(tax => tax.code === 'b2b-igst')?.id || '';
            } else {
                return taxOptions.find(tax => tax.code === 'b2b')?.id || '';
            }
        }
        return taxOptions.find(tax => tax.code === 'b2b')?.id || '';;
    };


    // Function to extract state code and PAN from GST number
    const handleGSTChange = async (gstValue) => {
        if (!gstValue) return; // Ensure gstValue is defined

    //    console.log(e.message);
    //    const gstNumber = e.target.value;
        setInputData(prevState => ({ ...prevState, gst: gstValue }));

        if (gstValue.length >= 15) { // Standard GST number length is 15
            const stateCode = gstValue.substring(0, 2);
            const panNumber = gstValue.substring(2, 12);

            // Update PAN automatically
            setInputData(prevState => ({
                ...prevState,
                pan: panNumber
            }));

            try {
                // First get the country code for India (assuming GST is Indian)
                const countryResponse = await getFromAPI('FtsCountries/?calling_code=91');
                if (countryResponse.length > 0) {
                    const countryCode = countryResponse[0].calling_code;

                    // Set country to India
                    setInputData(prevState => ({
                        ...prevState,
                        country: countryCode
                    }));

                    // Fetch state data using the state code from GST
                    const stateResponse = await getFromAPI(`FtsState/?state_id=${stateCode}`);
                    if (stateResponse.length > 0) {
                        const stateData = stateResponse[0];
                        // Update tax based on GST state code
                        const newTax = determineAppropiateTax(stateData.state_code, branchInputData.bStateCode);


                        // Update state
                        setInputData(prevState => ({
                            ...prevState,
                            state: stateData.state_id,
                            pan: panNumber,
                            tax: newTax,
                        }));

                        // Fetch and set cities for the selected state
                        const cityResponse = await getFromAPI(`FtsCity/?state_id=${stateData.state_code}`);
                        setCity(cityResponse);
                        // Set the first city as default if cities are available
                        if (cityResponse && cityResponse.length > 0) {
                            setInputData(prevState => ({
                                ...prevState,
                                city: cityResponse[0].id
                            }));
                        }
                    }
                }
            } catch (error) {
                toast.error('Error processing GST number');
            }
        }
    };

    // Modify the useEffect for branch data to include tax handling
    useEffect(() => {
            if (branchData.length) {
                const filteredBranchData = branchData.find(
                    branch => branch.id === parseInt(getLoginBranchId, 10)
                );
    
                if (filteredBranchData) {
                    const { country, state, city } = filteredBranchData;
                    setBranchInputData({
                        bcountryCode: country,
                        bStateCode: state,
                        bcityCode: city,
                    });
    
                    // Set initial tax based on branch state
                    const initialTax = determineAppropiateTax(state, state);
                    
                    setInputData(prev => ({
                        ...prev,
                        country: country,
                        state: state,
                        city: city,
                        tax: initialTax
                    }));
    
                    handleCountryChange({ target: { value: country } });
                    handleStateChange({ target: { value: state } });
                }
            }
     }, [branchData, getLoginBranchId]);


    const handleInputChange = (index, field, value) => {
        if (field === 'gst') {
            handleGSTChange(value); // Pass the new GST value to handleGSTChange
        }
        setInputData({ ...inputData, [field]: value });
    };

    const rows = [
        {
            columns: [
                {
                    size: { md: 6, xl: 4 },
                    label: (
                        <>
                            Customer Name<span className="text-danger">*</span>
                        </>
                    ),
                    type: 'text',
                    name: 'name',
                    id: 'name',
                    placeholder: 'Enter Customer Name...',
                    value: inputData.name,
                    onChange: e => setInputData({ ...inputData, name: e.target.value }),
                    errormsg: '',
                },
                {
                    size: { md: 6, xl: 5 }, label: (
                        <>
                            Email
                        </>
                    ), type: 'email', name: 'email', id: 'email', placeholder: 'Enter Email Address...', value: inputData.email, onChange: e => setInputData({ ...inputData, email: e.target.value }), errormsg: '',
                },
                { size: { md: 6, xl: 3 }, label: (<>Mobile Number <span className='text-danger'>*</span></>), type: 'number', name: 'phone_number2', id: 'phone_number2', placeholder: 'Please Enter Mobile Number...', value: inputData.phone_number2, onChange: handleMobileChange, errormsg: '', },
                {
                    size: { sm: 6, xl: 4 }, label: 'Gender', type: 'select', name: 'gender', id: 'gender', value: inputData.gender, onChange: e => setInputData({ ...inputData, gender: e.target.value }), errormsg: '',
                    options: [
                        { value: '', label: 'Select Gender' },
                        { value: 'Male', label: 'Male' },
                        { value: 'Female', label: 'Female' },
                        { value: 'Other', label: 'Other' }
                    ]
                },
                { size: { md: 6, xl: 4 }, label: 'Birthday', type: 'date', name: 'birth_date', id: 'birthday', placeholder: 'DD/MM/YYYY', value: inputData.birth_date, onChange: e => setInputData({ ...inputData, birth_date: e.target.value }), errormsg: '', },
                { size: { md: 6, xl: 4 }, label: 'Anniversary', type: 'date', name: 'anniversary_date', id: 'anniversary', placeholder: 'DD/MM/YYYY', value: inputData.anniversary_date, onChange: e => setInputData({ ...inputData, anniversary_date: e.target.value }), errormsg: '', },
                { size: { md: 12 }, label: 'Address', type: 'text', name: 'adrdess', id: 'address', placeholder: 'Enter address...', value: inputData.address, onChange: e => setInputData({ ...inputData, address: e.target.value }), errormsg: '', },
                {
                    size: { sm: 6 }, label: 'Referred By', type: 'select', name: 'reffered_by_id', id: 'referred_by', value: inputData.reffered_by_id, errormsg: '',
                    options: [
                        { value: '', label: 'None' },
                        ...Customer.map(item => ({ value: item.userid, label: item.name }))
                    ],
                },
                { size: { sm: 6 }, label: 'GST', type: 'text', name: 'gst', id: 'gst', placeholder: 'Enter GST number...', value: inputData.gst, onChange: e => setInputData({ ...inputData, gst: e.target.value }),maxlength:15,errormsg: '' },
                {
                    size: { md: 6, xl: 4 }, label: 'Country', type: 'select', name: 'country', id: 'country', value: inputData.country, onChange: handleCountryChange, errormsg: '',
                    options: [
                        { value: '', label: 'Select Country' },
                        ...country.map((c, i) => ({ value: c.calling_code, label: c.short_name }))
                    ],
                },
                {
                    size: { md: 6, xl: 4 }, label: 'State', type: 'select', name: 'state', id: 'state', value: inputData.state, onChange: handleStateChange, errormsg: '',
                    options: [
                        { value: '', label: 'Select State' },
                        ...State.map((s, i) => ({ value: s.state_code, label: s.state_name }))
                    ],
                },
                {
                    size: { md: 6, xl: 4 }, label: 'City', type: 'select', name: 'city', id: 'city', value: inputData.city, errormsg: '',
                    options: [
                        { value: '', label: 'Select City' },
                        ...city.map((c, i) => ({ value: c.id, label: c.city_name }))
                    ],
                },
                {
                    size: { lg: 3 }, label: 'Tax', type: 'select', name: 'tax', id: 'tax', value: inputData.tax, onChange: e => setInputData({ ...inputData, tax: e.target.value }), errormsg: '',
                    options: [
                        { value: '', label: 'Select Tax' },
                        ...taxOptions.map(item => ({ value: item.id, label: item.name }))
                    ],
                },
                { size: { md: 6, xl: 4 }, label: 'PAN', type: 'text', name: 'pan', id: 'pan', placeholder: 'Enter PAN number...', value: inputData.pan, onChange: e => setInputData({ ...inputData, pan: e.target.value }), errormsg: '', },
                { size: { md: 6, xl: 4 }, label: 'Opening Balance', type: 'text', name: 'opening_balance', id: 'opening_balance', placeholder: 'Enter opening balance...', value: inputData.opening_balance, onChange: e => setInputData({ ...inputData, opening_balance: e.target.value }), errormsg: '', },
                {
                    size: { md: 6, xl: 4 }, label: 'Bank Name', type: 'select', name: 'bank_name', id: 'bank_name', value: inputData.bank_name, errormsg: '',
                    options: [
                        { value: '', label: 'Select Bank Name' },
                        ...Bank.map(item => ({ value: item.bank_id, label: item.bank_name }))
                    ],
                },
                { size: { md: 6, xl: 4 }, label: 'IFSC Code', type: 'text', name: 'ifsc_code', id: 'ifsc_code', placeholder: 'Enter IFSC code...', value: inputData.ifsc_code, onChange: e => setInputData({ ...inputData, ifsc_code: e.target.value }), errormsg: '', },
                { size: { md: 6, xl: 4 }, label: 'Account Number', type: 'text', name: 'account_number', id: 'AccountNumber', placeholder: 'Enter Account Number...', value: inputData.account_number, onChange: e => setInputData({ ...inputData, account_number: e.target.value }), errormsg: '', },
            ]
        },
        // Testing Code
        // {
        //     columns: [
        //         {
        //             size: { md: 6, xxl: 4 }, label: 'Bank Name', type: 'selectwithbtn', name: 'bank_name', id: 'bank_name', value: inputData.bank_name, errormsg: '',
        //             options: [
        //                 { value: '', label: 'Select Bank Name' },
        //                 ...Bank.map(item => ({ value: item.bank_id, label: item.bank_name }))
        //             ],
        //         },
        //         {
        //             size: { md: 6, xxl: 5 }, label: 'Freight Charges', type: 'chargesInput', name: 'freight_Charges', id: 'freight_Charges', value: inputData.freight_Charges, errormsg: '',
        //             options: [
        //                 { value: '', label: 'Select Tax' },
        //                 ...Tax.map((data, i) => ({ value: data.id, datataxrate: data.taxrate, label: data.name }))
        //             ],
        //         },
        //         {
        //             size: { md: 6, xxl: 3 }, label: 'Tax Type', type: 'select', name: 'tax_type', id: 'tax_type', value: inputData.tax_type, errormsg: '',
        //             options: [
        //                 { value: '1', label: 'Exclusive' }, { value: '2', label: 'Inclusive' }
        //             ],
        //         },
        //         {
        //             size: { md: 6, xxl: 4 }, label: 'Overall Discount', type: 'chargesInput', name: 'overall_discount', id: 'overall_discount', value: inputData.overall_discount, errormsg: '',
        //             options: [
        //                 { value: '1', label: '% Off' }, { value: '2', label: 'Rs. Off' }
        //             ],
        //         },
        //         { size: { sm: 6, xxl: 4 }, label: 'Account Number', type: 'text', name: 'AccountNumber', disabled: true, id: 'AccountNumber', placeholder: 'Enter Account Number...', value: inputData.account_number, onChange: e => setInputData({ ...inputData, account_number: e.target.value }), errormsg: '', },
        //         {
        //             size: { md: 6, xxl: 4 }, label: 'Branch', type: 'multiSelect', name: 'branch', id: 'branch', value: inputData.branch, errormsg: '',
        //             options: [
        //                 { value: "All Branch", label: "All Branch" },
        //                 { value: "NSF Adajan", label: "NSF Adajan" },
        //                 { value: "NFS Parle Point", label: "NFS Parle Point" },
        //                 { value: "NFS Uniform", label: "NFS Uniform" },
        //                 { value: "NFS Warehouse", label: "NFS Warehouse" },
        //             ],
        //         },
        //         {
        //             size: { md: 6, xxl: 4 },
        //             type: 'checkboxes',
        //             name: 'checkboxOptions',
        //             id: 'checkboxOptions',
        //             options: [
        //                 { value: 'option1', label: 'Option 1' },
        //                 { value: 'option2', label: 'Option 2' },
        //                 { value: 'option3', label: 'Option 3' },
        //             ],
        //         },
        //         {
        //             size: { md: 6, xxl: 4 }, type: 'radios', name: 'radioOptions', id: 'radioOptions',
        //             options: [
        //                 { value: 'option1', label: 'Option 1' },
        //                 { value: 'option2', label: 'Option 2' },
        //                 { value: 'option3', label: 'Option 3' }
        //             ]
        //         },
        //         { size: { sm: 6, xxl: 4 }, label: 'Choose File', type: 'file', name: 'file_upload', id: 'file_upload', placeholder: 'Enter PAN number...', },
        //         { size: { md: 12 }, label: 'Massage', type: 'textarea', name: 'type_msg', formrow: '3', id: 'type_msg', placeholder: 'Enter massage...', },
        //     ]
        // },
    ];
    const Buttons = [
        { variant: "primary", text: "Save Changes", onClick: handleSaveChanges },
        { variant: "secondary", text: "Close", onClick: handleCloseModal },
    ]

    return (
        <>
            <div className='d-flex justify-content-between align-items-center mb-3'>
                <Card.Title className='mb-0'>Details</Card.Title>
                <div className='d-flex align-items-center gap-3'>
                    <Button variant="soft-info p-0" onClick={handleCdEdit} disabled={!selectedCustomerId} >
                        <div className='avatar avatar-sm'>
                            <Icon path={mdiSquareEditOutline} className='avatar-icon' />
                        </div>
                    </Button>
                    <Button variant="soft-danger p-0" onClick={() => handleDeleteLocker(selectedCustomerId)} disabled={!selectedCustomerId}>
                        <div className='avatar avatar-sm'>
                            <Icon path={mdiTrashCanOutline} className='avatar-icon' />
                        </div>
                    </Button>
                </div>
            </div>

            <Table responsive className='table-lg border mb-0'>
                <tbody>
                    {tableData.map((data, i) => (
                        <tr key={i} className='table-details-list'>
                            <th>{data.customerheading1}</th>
                            <td>{data.customersubtext1}</td>
                            <th>{data.customerheading2}</th>
                            <td>{data.customersubtext2}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            {/* <Modal size="xl" centered show={showEditModal} onHide={handleCloseModal}>
                <Modal.Header className='custom-close-btn' closeButton></Modal.Header>
                <Modal.Body>
                    <h5 className='mb-4'>Edit Customer</h5>
                    <Form>
                        <Row>
                            <Col md={6} xxl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Customer Name</Form.Label>
                                    <Form.Control type="text" name='customer_name' id='customer_name' placeholder="Enter Customer Name..." value={inputData.name}
                                        onChange={e => setInputData({ ...inputData, name: e.target.value })}
                                    />
                                </div>
                            </Col>
                            <Col md={6} xxl={5}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Email Address</Form.Label>
                                    <Form.Control type="email" name='email' id='email' placeholder="Enter Email Address..." value={inputData.email}
                                        onChange={e => setInputData({ ...inputData, email: e.target.value })}
                                    />
                                </div>
                            </Col>
                            <Col sm={6} xxl={3}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Mobile Number</Form.Label>
                                    <Form.Control type="text" name='Mobile_Number' id='Mobile_Number' placeholder="Please Enter Mobile Number..." value={inputData.phone_number2}
                                        onChange={handleMobileChange}
                                    />
                                </div>
                            </Col>
                            <Col sm={6} xxl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Gender</Form.Label>
                                    <Form.Select name='gender' id='gender' value={inputData.gender}
                                        onChange={e => setInputData({ ...inputData, gender: e.target.value })}
                                    >
                                        <option value="">Select Gender</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                        <option value="Other">Other</option>
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col sm={6} xxl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Birthday</Form.Label>
                                    <Form.Control type="date" name='birthday' id='birthday' placeholder="DD/MM/YYYY" value={inputData.birth_date}
                                        onChange={e => setInputData({ ...inputData, birth_date: e.target.value })}
                                    />
                                </div>
                            </Col>
                            <Col sm={6} xxl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Anniversary</Form.Label>
                                    <Form.Control type="date" name='anniversary' id='anniversary' placeholder="DD/MM/YYYY" value={inputData.anniversary_date}
                                        onChange={e => setInputData({ ...inputData, anniversary_date: e.target.value })}
                                    />
                                </div>
                            </Col>
                            <Col md={12}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Address</Form.Label>
                                    <Form.Control type="text" name='address' id='address' placeholder="Enter address..." value={inputData.address}
                                        onChange={e => setInputData({ ...inputData, address: e.target.value })}
                                    />
                                </div>
                            </Col>
                            <Col sm={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Referred By</Form.Label>
                                    <Form.Select name='referred_by' id='referred_by' value={inputData.reffered_by_id}
                                        onChange={e => setInputData({ ...inputData, reffered_by_id: e.target.value })}
                                    >
                                        <option value="">None</option>
                                        {Customer.map(item => (
                                            <option key={item.id} value={item.userid}>{item.name}</option>
                                        ))}
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col sm={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>GST</Form.Label>
                                    <Form.Control type="text" name='GST' id='GST' placeholder="Enter GST number..." value={inputData.gst}
                                        onChange={e => setInputData({ ...inputData, gst: e.target.value })}
                                    />
                                </div>
                            </Col>
                            <Col md={6} xxl={4}>
                                <Form.Group className="mb-3 custom-form-input">
                                    <Form.Label>Country</Form.Label>
                                    <Form.Select aria-label="Country" name='country' value={inputData.country} onChange={handleCountryChange}>
                                        <option value="">Select Country</option>
                                        {country.map((c, i) => (
                                            <option key={i} value={c.calling_code}>{c.short_name}</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col md={6} xxl={4}>
                                <Form.Group className="mb-3 custom-form-input">
                                    <Form.Label>State</Form.Label>
                                    <Form.Select aria-label="State" name='state' value={inputData.state} onChange={handleStateChange}>
                                        <option value="">Select State</option>
                                        {State.map((s, i) => (
                                            <option key={i} value={s.state_code}>{s.state_name}</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col md={6} xxl={4}>
                                <Form.Group className="mb-3 custom-form-input">
                                    <Form.Label>City</Form.Label>
                                    <Form.Select aria-label="City" name='city' value={inputData.city}
                                        onChange={e => setInputData({ ...inputData, city: e.target.value })}
                                    >
                                        <option value="">Select City</option>
                                        {city.map((c, i) => (
                                            <option key={i} value={c.id}>{c.city_name}</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col sm={6} xxl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Tax</Form.Label>
                                    <Form.Select name='tax' id='tax' value={inputData.tax}
                                        onChange={e => setInputData({ ...inputData, tax: e.target.value })}
                                    >
                                        <option value="">Select Tax</option>
                                        {Tax.map(item => (
                                            <option key={item.id} value={item.id}>{item.name}</option>
                                        ))}
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col md={6} xxl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>PAN</Form.Label>
                                    <Form.Control type="text" name='pan' id='pan' placeholder="Enter PAN number..." value={inputData.pan}
                                        onChange={e => setInputData({ ...inputData, pan: e.target.value })}
                                    />
                                </div>
                            </Col>
                            <Col md={6} xxl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Opening Balance</Form.Label>
                                    <Form.Control type="text" name='opening_balance' id='opening_balance' placeholder="Enter opening balance..."
                                        value={inputData.opening_balance}
                                        onChange={e => setInputData({ ...inputData, opening_balance: e.target.value })}
                                    />
                                </div>
                            </Col>
                            <Col md={6} xxl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Bank Name</Form.Label>
                                    <Form.Select name='bank_name' id='bank_name' value={inputData.bank_name}
                                        onChange={e => setInputData({ ...inputData, bank_name: e.target.value })}
                                    >
                                        <option value="">Select Bank Name</option>
                                        {Bank.map(item => (
                                            <option key={item.id} value={item.bank_id}>{item.bank_name}</option>
                                        ))}
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col md={6} xxl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>IFSC Code</Form.Label>
                                    <Form.Control type="text" name='ifsc_code' id='ifsc_code' placeholder="Enter IFSC code..." value={inputData.ifsc_code}
                                        onChange={e => setInputData({ ...inputData, ifsc_code: e.target.value })}
                                    />
                                </div>
                            </Col>
                            <Col md={6} xxl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Account Number</Form.Label>
                                    <Form.Control type="text" name='AccountNumber' id='AccountNumber' placeholder="Enter Account Number..." value={inputData.account_number}
                                        onChange={e => setInputData({ ...inputData, account_number: e.target.value })}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Form>
                    <div className='text-end btn-list'>
                        <Button variant="primary" onClick={handleSaveChanges}>Save Changes</Button>
                        <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
                    </div>
                </Modal.Body>
            </Modal> */}

            {/* From Modal */}
            <FormModal title="Edit Customer" formModalShow={formModalShow} Buttons={Buttons} handleFormModalClose={handleFormModalClose} rows={rows} handleInputChange={handleInputChange} handleSubmit={handleSubmit} />
        </>
    );
}
