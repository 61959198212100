import React, { useState, useEffect,useRef,useCallback } from 'react';
import { Container, Row, Col, Card, Table, Button } from 'react-bootstrap';
import Footer from '../layout/Footer';
import FormModal from '../../Utils/FormModal';
import LockerTableData from './locker-data.json';
import { postToAPI, getFromAPI,deleteFromAPI,showAlert,putToAPI } from '../../Utils/utils';
import {toast} from 'react-toastify';
// icon
import Icon from '@mdi/react';
import { mdiPlus } from '@mdi/js';

// Datatable
import JSZip from 'jszip';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import DataTable from 'datatables.net-dt';

export default function Locker() {
    
    // datatable
    const [lockerData, setLockerData] = useState([]);
    const [editedTeamID, setEditedTeamID] = useState('');

   // Assume loggedInStaffId is available (e.g., fetched from a user session or token)
   const loggedInStaffId = localStorage.getItem("loggedInStaffId"); // Example: Fetch from localStorage

    const fetchLockerData = async () => {
        try {
            const response = await getFromAPI('FtsLocker/');
            if (loggedInStaffId) {
                // Filter lockers that belong to the logged-in staff
                const filteredData = response.filter(locker => 
                    locker.Staffname?.split(',').includes(loggedInStaffId)
                );
                setLockerData(filteredData);
            } else {
                // Handle case where loggedInStaffId is not available
                console.warn("Logged-in staff ID not found.");
                setLockerData([]);
            }
        } catch (error) {
            console.error('Error fetching locker data:', error);
        }
    };

  
      // Fetch Staff Data
      const fetchStaffData = async () => {
          try {
              const response = await getFromAPI('FtsStaff/');
              const staffOptions = response.map(staff => ({
                  value: staff.staffid,
                  label: staff.firstname,
              }));
              setStaffData(staffOptions);
          } catch (error) {
              console.error('Error fetching staff data:', error);
          }
      };
  
    useEffect(() => {
        const fetchData = async () => {
            await fetchStaffData(); // Ensure staff data is available first
            fetchLockerData(); // Fetch locker data after staff data is available
        };
    
        fetchData();
    }, []);
    
 // Handle Delete Locker Entry
 const handleDeleteLocker = async (Id) => {
    try {
      showAlert('Are you sure you want to delete this Locker?', 'confirm', (result) => {
        if (result) {
          // User confirmed the deletion
          deleteLocker(Id);
        } else {
          // User canceled the deletion
          // You can handle this case if needed
        }
      });
    } catch (error) {
      console.error('Error deleting Locker:', error);
      toast.error('Error deleting Locker', 'error');
    }
  };

  //Function for the deleteing locker fromt the database 
  const deleteLocker = async (Id) => {
    try {
      const response = await deleteFromAPI(`FtsLocker/${Id}/`);
      if (response.status) {
        fetchLockerData();
        toast.success('Locker deleted successfully!', 'success');
      } else    {
        showAlert('Error deleting Locker', 'error');
      }
    } catch (error) {
      console.error('Error deleting Locker:', error);
      toast.error('Error deleting Locker', 'error');
    }
  };

   // Add/Edit modal
   const [formModalShow, setShowFormModal] = useState(false);
   const handleLockerModal = () => setShowFormModal(true);
   const handleFormModalClose = () =>{
     setShowFormModal(false);
     setData(initialLockerData)
     setEditedTeamID('');
   }

  // Cleanup DataTable on component unmount
  useEffect(() => {
        return () => {
            if ($.fn.dataTable.isDataTable('#mainDatatableLocker')) {
                $('#mainDatatableLocker').DataTable().destroy();
            }
        };

    }, [lockerData]);
   
    DataTable.Buttons.jszip(JSZip);
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    useEffect(() => {
        if (!$.fn.dataTable.isDataTable('#mainDatatableLocker')) {
            $('#mainDatatableLocker').DataTable({
                dom: "Bflrtip",
                buttons: [
                    {
                        extend: 'excel',
                        className: 'btn btn-outline-secondary',
                        text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon me-1"><path d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M12.9 14.5L15.8 19H14L12 15.6L10 19H8.2L11.1 14.5L8.2 10H10L12 13.4L14 10H15.8L12.9 14.5Z" style="fill: currentcolor;"></path></svg> Export to Excel',
                        filename: 'myRetails_Locker', // Set the filename for the Excel export
                        title: 'myRetails Locker' // Remove title from the Excel sheet if not needed
                    }
                ],
                // TODO: Add loader while fetching the data
                // use <div className="mt-4 loader"></div> for loader
                data: lockerData.map((data, i) => [
                    i + 1,
                    data.title,
                    `<a href="${data.link}" target="_blank" rel="noopener noreferrer">${data.link}</a>`,
                    `${data.tags.split(',').map(tag => `<span class="badge bg-soft-success">${tag.trim()}</span>`).join(' ')}`,
                    data.description,
                    `<span class="password-cell" title="${data.password}">${'*'.repeat(data.password.length)}</span>`,
                    data.id                        
                ]),
                columns: [
                    { title: "Sr.No" },
                    { title: "Title" },
                    { title: "Link" },
                    { title: "Tag",
                        createdCell: function (td) {
                            td.classList.add('table-data-width');
                        },
                     },
                    { title: "Description", 
                        createdCell: function (td) {
                            td.classList.add('table-data-width');
                        },
                    },
                    {
                        title: "Password",
                        createdCell: function (td) {
                            td.classList.add('table-ps-clm');
                        },
                    },
                    {
                        title: "Action",
                        createdCell: function (td) {
                            td.classList.add('py-2');
                        },
                        render: function (data, type, row) {
                            return `
                            <div class="d-flex gap-2">
                                <button class="btn btn-soft-info p-0 table-btn lkr-edit" data-id="${row[6]}">
                                    <div class="avatar avatar-xs lkr-edit" data-id="${row[6]}">
                                        <svg viewBox="0 0 24 24" data-id="${row[6]}" role="presentation" class="avatar-icon lkr-edit"><path d="M5,3C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V12H19V19H5V5H12V3H5M17.78,4C17.61,4 17.43,4.07 17.3,4.2L16.08,5.41L18.58,7.91L19.8,6.7C20.06,6.44 20.06,6 19.8,5.75L18.25,4.2C18.12,4.07 17.95,4 17.78,4M15.37,6.12L8,13.5V16H10.5L17.87,8.62L15.37,6.12Z" style="fill: currentcolor;"></path></svg>
                                    </div>
                                </button>
                                <button class="btn btn-soft-danger p-0 table-btn lkr-delete" data-id="${row[6]}">
                                    <div class="avatar avatar-xs lkr-delete" data-id="${row[6]}">
                                        <svg viewBox="0 0 24 24" data-id="${row[6]}" role="presentation" class="avatar-icon lkr-delete"><path d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z" style="fill: currentcolor;"></path></svg>
                                    </div>
                                </button>
                            </div>`;
                        }
                    },
                ],
                autoWidth: false,
                language: {
                    search: '',
                    searchPlaceholder: "Search...",
                    paginate: {
                        previous: '«',
                        next: '»'
                    },
                },
            });
        }

        if (document.getElementsByClassName("dt-buttons").length) {
            document.getElementById("mainDatatableLocker_wrapper").classList.add("dtl-right");
        } else {
            document.getElementById("mainDatatableLocker_wrapper").classList.remove("dtl-right");
        }
        document.querySelector('#mainDatatableLocker tbody').addEventListener('click', function (event) {
            if (event.target && event.target.classList.contains('lkr-edit')) {
                const pi_id = event.target.getAttribute('data-id');
                handleEditClick(pi_id);
            }
        });
        document.querySelector('#mainDatatableLocker tbody').addEventListener('click', function (event) {
            if (event.target && event.target.classList.contains('lkr-delete')) {
                const pi_id = event.target.getAttribute('data-id');
                handleDeleteLocker(pi_id);
            }
        });

    }, [lockerData]);

    const initialLockerData = {
        lkrTitle: '',
        lkrLink: '',
        lkrUserId: '',
        lkrPassword: '',
        lkrDes: '',
        lkrTag: [],
        lkrStaff: [],
    };

    // input value
    const [data, setData] = useState(initialLockerData);
    const [staffData,setStaffData] = useState([])

   
    const handleInputChange = (index, field, value) => {
        setData((prevData) => {
            if (field === "lkrStaff") {
                // Handle the select change
                return {
                    ...prevData,
                    [field]: value,
                };
            }
            // Handle regular input changes
            return {
                ...prevData,
                [field]: value,
            };
        });
    };
          
    const handleSubmit = async () => {
        const lockerData = {
            title: data.lkrTitle,
            link: data.lkrLink,
            uid: data.lkrUserId,
            password: data.lkrPassword,
            description: data.lkrDes,
            tags: Array.isArray(data.lkrTag) ? data.lkrTag.join(',') : '',
            Staffname: data.lkrStaff.map(staff => staff.value).join(','),
        };

        try {
            const response = await postToAPI("FtsLocker/", lockerData);

            if (response) {
                toast.success('Locker Added successfully!', 'success');
                fetchLockerData();
                setData(initialLockerData)
                // Refresh DataTable or reload locker data here if needed
            } else {
                console.error("Failed to add locker:");
            }
        } catch (error) {
            console.error("Error occurred while adding locker:", error);
        }

    };

   
        // Handle Edit Click
        const handleEditClick = (id) => {
          const locker = lockerData.find(item => String(item.id) === String(id));
          if (!locker) return;
  
          const preselectedEmployees = (locker.Staffname || '')
              .split(',')
              .filter(Boolean)
              .map(employeeId => {
                  const employee = staffData.find(emp => emp.value === parseInt(employeeId, 10));
                  return employee ? { value: employee.value, label: employee.label } : null;
              })
              .filter(Boolean);
  
          setData({
              lkrTitle: locker.title,
              lkrLink: locker.link,
              lkrUserId: locker.uid,
              lkrPassword: locker.password,
              lkrTag: (locker.tags || '').split(','),
              lkrStaff: preselectedEmployees,
              lkrDes: locker.description,
          });
          setShowFormModal(true);
          setEditedTeamID(id)
      };
    
     // function for the updateing data add & edit time in database 
     const updateDataInDatabase = useCallback (async(itemDetails) => {
        const updateUrl = `FtsLocker/${editedTeamID}/`;
    
          const requestBody = {
            title: itemDetails.lkrTitle || null,
            description: itemDetails.lkrDes || null,
            link: itemDetails.lkrLink || null,
            password: itemDetails.lkrPassword || null,
            uid: itemDetails.lkrUserId || null,
            tags: Array.isArray(itemDetails.lkrTag) ? data.lkrTag.join(',') : '',
            Staffname: itemDetails.lkrStaff ?itemDetails.lkrStaff.map(employee => employee.value).join(','):null,
          };
  
          try{
            const data = await putToAPI(updateUrl,requestBody);
              if(data.message === 'Item updated successfully'){
                fetchLockerData();
                setData(initialLockerData)
                toast.success('Locker updated successfully!', 'success');
              }else {
                console.error('Unexpected response:', data.message);
              }
            } catch (error) {
                // Handle network or other errors
                console.error('Error updating Locker::', error);
            }
            setEditedTeamID('')
       },[lockerData , editedTeamID ,data]);
       
    const handleBothActions = () => {
        // Helper function to validate URL
        const isValidURL = (url) => {
          const urlRegex = /^(https?:\/\/)?([\w\-]+(\.[\w\-]+)+)(:[0-9]+)?(\/.*)?$/;
          return urlRegex.test(url.trim());
        };
      
        // Validation for title
        if (!data.lkrTitle.trim()) {
          toast.error('Please add a proper title');
          return;
        }
      
        // Validation for link
        if (!data.lkrLink.trim()) {
          toast.error('Please add a proper link');
          return;
        }
        if (!isValidURL(data.lkrLink)) {
          toast.error('Please enter a valid link (e.g., https://example.com)');
          return;
        }
      
        // Validation for User ID
        if (!data.lkrUserId.trim()) {
          toast.error('Please add a proper UID');
          return;
        }
      
        // Validation for password
        if (!data.lkrPassword) {
          toast.error('Please add a proper password');
          return;
        }
        if (!data.lkrPassword.trim()) {
          toast.error('Only spaces are not allowed for the password');
          return;
        }
      
        // Validation for description
        if (!data.lkrDes) {
          toast.error('Please add a proper description');
          return;
        }
      
        // Validation for tags
        if (data.lkrTag.length === 0) {
          toast.error('Please add at least one tag');
          return;
        }
      
        // Validation for staff
        if (data.lkrStaff.length === 0) {
          toast.error('Please add at least one staff');
          return;
        }
      
        // Check for duplicate locker name if `editedTeamID` is empty
        if (editedTeamID === '') {
          const isDuplicate = lockerData.some(
            (locker) => locker.title === data.lkrTitle && locker.id !== editedTeamID
          );
          if (isDuplicate) {
            toast.error('Locker name already exists. Please choose a different name.');
            return;
          }
      
          // If new locker, handle submission and close modal
          handleSubmit();
          handleFormModalClose();
        } else {
          // If editing an existing locker, update the data in the database and close modal
          updateDataInDatabase(data);
          handleFormModalClose();
        }
      };
    
  useEffect(() => {
        // This effect will run whenever lockerData or staffData changes
        if (editedTeamID) {
            handleEditClick(editedTeamID);
        }
    }, [lockerData, staffData]);
      
    const rows = [
        {
            columns: [
                { size: { lg: 12 }, label: 'Title', type: 'text', name: 'lkrTitle', id: 'lkrTitle', placeholder: 'Enter title...', value: data.lkrTitle, errormsg: '', },
                { size: { lg: 12 }, label: 'Link', type: 'text', name: 'lkrLink', id: 'lkrLink', placeholder: 'Enter link...', value: data.lkrLink, errormsg: '', },
                { size: { lg: 6 }, label: 'User ID', type: 'text', name: 'lkrUserId', id: 'lkrUserId', placeholder: 'Enter user id...', value: data.lkrUserId, errormsg: '', },
                { size: { lg: 6 }, label: 'Password', type: 'password', name: 'lkrPassword', id: 'lkrPassword', placeholder: 'Enter Password...', value: data.lkrPassword, errormsg: '', },
                { size: { md: 12 }, label: 'Description', type: 'textarea', name: 'lkrDes', formrow: '3', id: 'lkrDes', placeholder: 'Enter description...', value: data.lkrDes, errormsg: '', },
                { size: { md: 12 }, 
                  label: 'Tags', 
                  type: 'tagInputs', 
                  name: 'lkrTag', 
                  id: 'lkrTag', 
                  placeholder: 'Enter tags and Press Enter...', 
                  value: data.lkrTag, 
                  errormsg: '', 

                },
                {
                    size: { md: 12 }, 
                    label: 'Assign to Staff', 
                    type: 'multiSelect', 
                    name: 'lkrStaff', 
                    id: 'lkrStaff', 
                    value: data.lkrStaff, 
                    placeholder: 'Select Staff',
                    errormsg: '',
                    options: staffData,
                    getOptionLabel: (option) => option.label,
                    getOptionValue: (option) => option.value,

                },
            ]
        }
    ];
    const Buttons = [
        { variant: "primary", text: "Ok", onClick: handleBothActions },
        { variant: "secondary", text: "Cancel", onClick: handleFormModalClose },
    ]

    return (
        <>
            <div className="page-wrapper">
                <div className='page-content'>
                    <Container>
                        <Row>
                            <Col md={12}>
                                <Card>
                                    <Card.Body>
                                        <div className='d-flex align-items-center justify-content-between mb-4'>
                                            <Card.Title className='mb-0'>Locker</Card.Title>
                                            <Button variant="light mb-sm-0 mb-3" onClick={handleLockerModal}>
                                                <Icon path={mdiPlus} className="btn-icon me-1" /> Add Locker
                                            </Button>
                                        </div>

                                        {/* {loading && <div className="loader"></div>} */}
                                        <Table responsive bordered className='table-nowrap' id='mainDatatableLocker'>
                                            <thead className='table-light'></thead>
                                        </Table>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer />
            </div>

            {/* From Modal */}
            <FormModal title={editedTeamID ? "Edit Locker" : "Add Locker"} modalSize="lg" formModalShow={formModalShow} Buttons={Buttons} handleFormModalClose={handleFormModalClose} rows={rows} handleInputChange={handleInputChange} handleSubmit={handleBothActions} />
        </>
    )
}
