import React, { useState, useEffect } from 'react';
import { Card, Col, Row, Table, Button, Form, InputGroup, Modal } from 'react-bootstrap';
import SimpleBarReact from "simplebar-react";
// Datatable
import JSZip from 'jszip';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import DataTable from 'datatables.net-dt';
import { toast } from "react-toastify";
import { CustomerProvider, useCustomer } from '../vendor/getVendor';
import TempData from '../../Utils/temp_data.json';

// icon
import Icon from '@mdi/react';
import { mdiPlus, mdiReceiptTextOutline } from '@mdi/js';

import { postToAPI, getFromAPI, deleteFromAPI, showAlert, handleDateFormat, formatISODateWithTimezone, putToAPI, Backend_BASE_URL } from '../../Utils/utils';
import ListViewTable from "../../Utils/listview";
import QuickVendorModal from '../../Utils/QuickVendorModal';
import ViewInvoice from '../../Utils/ViewInvoice';
import { fetchTaxData } from '../../Utils/function_list';
import QuickProductModal from '../../Utils/QuickProductModal';

function PurchaseInvoice() {
    const [TempDataOption] = useState(TempData);

    const { vendor, fetchCustomerData } = useCustomer();
    const [PIData, setPIData] = useState();
    const [Products, setProducts] = useState();
    const [totalProductQuantity, setTotalProductQuantity] = useState(0);
    const [totalItemDiscount, setTotalItemtotalItemDiscount] = useState(0);
    const [vendorTax, setVendorTax] = useState("");
    const [vendorTaxType, setvendorTaxType] = useState(1);
    const [Tax, setTax] = useState([]);
    const [ShippigTaxTypeDisable, setShippigTaxTypeDisable] = useState(true);
    const [OtherTaxTypeDisable, setOtherTaxTypeDisable] = useState(true);
    const [invoiceNotesData, setInvoiceNotesData] = useState("");
    const [PaymentModes, setPaymentModes] = useState();
    const [isEdit, setisEdit] = useState(false);
    const [PIEditID, setPIEditID] = useState(null);
    // get curret date
    const date = new Date();
    const futureDate = date.getDate();
    date.setDate(futureDate);
    const defaultValue = date.toLocaleDateString('en-CA');

    const FetchPIData = () => {
        getFromAPI("FtsPurchaseOrderInvoice/").then(response => { setPIData(response) })
            .catch(error => {
                console.error('Error:', error);
            });
    }

    const FetchProducts = () => {
        getFromAPI("getProductsWithBranchData/").then(response => { setProducts(response) })
            .catch(error => {
                console.error('Error:', error);
            });
    }

    const FetchPaymentModes = () => {
        getFromAPI("FtsPaymentModes/").then(response => { setPaymentModes(response) })
            .catch(error => {
                console.error('Error:', error);
            });
    }

    const initialInputData = {
        po_id: 111,
        reference_number: "",
        vendor: 0,
        pi_date: handleDateFormat(new Date()),
        invoice_date: handleDateFormat(new Date()),
        sub_total: 0.0,
        discount: 0,
        overall_discount: 0,
        overall_discount_type: 0,
        total_tax: 0,
        total: 0.0,
        shipping_charge: 0,
        shipping_tax_type: 1,
        shipping_tax_id: 1,
        shipping_taxable_amount: "",
        shipping_tax_amount: "",
        shipping_tax_value: 0,
        total_shipping_tax: 0,
        other_charges: 0,
        other_charges_tax_type: 1,
        other_tax_id: 0,
        other_taxable_amount: "",
        other_tax_amount: "",
        other_charges_tax_value: 0,
        total_other_charges: 0,
        final_invoice_amount: 0.0,
        payment_type: 4,
        amount_paid: 0,
        amount_due: 0,
        amount_repaid: 0,
        transaction_discount: 0,
        sms_sent: 0,
        ispaid: 0,
        account_id: 0,
        po_credit_note_id: 0,
        status: 0,
        delivery: 0,
        lr_no: 0,
        courier_company: "",
        packet_no: 0,
        receiver_name: "",
        isprinted: 0,
        isview: 0,
        poi_tax_id: 0,
        poi_tax_amount: 0,
        short_link: "",
        long_link: "",
        reference_view_number: "",
        advance_payment: 0,
        created_branch_id: 1,
        created_user_id: 1,
        last_modified_user_id: 1,
        created_date_time: new Date(),
        last_modified_date_time: new Date(),
        isdeleted: 0,
        narration: "",
        roundoff: 0,
    }

    const [inputData, setInputData] = useState(initialInputData);

    const initialProductRow = {
        onfloor: true,
        serialNumber: '',
        product: '',
        unitPrice: '',
        bQty: 0,
        rQty: 0,
        fQty: 0,
        disc: 0,
        disc_type: 0,
        taxAmount: 0,
        tax_type: 2,
        total: 0,
        tax: 0,
        tax_id: 0,
        taxable_amount: 0,
        discount_amount: 0,
        tax_1_amount: 0,
        tax_2_amount: 0
    }

    const initialFromInfoData = [
        { label: 'ID', value: '' },
        { label: 'Dilivery Date', value: '' },
        { label: 'Invoice Date', value: '' },
        { label: 'Vendor Name', value: '' },
        { label: 'Address', value: '' },
        { label: 'Mobile Number', value: '' },
        { label: 'Email', value: '' },
        { label: 'GSTIN', value: '' }
    ];
    const initialToInfoData = [
        { label: 'Name', value: '' },
        { label: 'Address', value: '' },
        { label: 'Mobile Number', value: '' },
        { label: 'GSTIN', value: '' }
    ];

    const [fromInfoData, setFromInfoData] = useState(initialFromInfoData);
    const [toInfoData, setToInfoData] = useState(initialToInfoData);
    const [PIProductsData, setPIProductsData] = useState();
    const [PITotalSummaryData, setPITotalSummaryData] = useState();

    const [initialProductRows, setInitialProductRows] = useState(Array(10).fill({ ...initialProductRow }));

    const [showModal, setPrListModal] = useState(false);
    const handleShowModalPrList = () => setPrListModal(true);
    const handleCloseModalPrList = () => setPrListModal(false);

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
          event.preventDefault(); // Prevent form submission or default action
        //   handleShowModalPrList();
        }
      };
    
    const ProductColumnsConfig = [
        { label: 'S/N', field: 'serialNumber', type: 'autoIncrement', columnClass: 'lvw-2' },
        { 
            label: 'Description', 
            field: 'product', 
            type: 'comboBox', 
            defualt: 'None',
            options: Products && Products.map((product) => ({id: product.id, name: product.description})),
            KeyPressFunction: handleKeyPress
        },
        { label: 'Unit Price', field: 'unitPrice', type: 'number', columnClass: 'lvw-10' },
        { label: 'B.qty', field: 'bQty', type: 'number', columnClass: 'lvw-5' },
        { label: 'R.qty', field: 'rQty', type: 'number', columnClass: 'lvw-5' },
        { label: 'F.qty', field: 'fQty', type: 'number', columnClass: 'lvw-5' },
        { label: 'Disc', field: 'disc', type: 'number', columnClass: 'lvw-5' },
        {
            label: 'Disc Type',
            field: 'disc_type',
            columnClass: 'lvw-10',
            type: 'select',
            defualt: 'None',
            options: [{value: 0, label: "% Off"}, {value: 1, label: "Rs. Off"}]
        },
        {
            label: 'Tax(%)',
            field: 'tax_id',
            columnClass: 'lvw-10',
            type: 'select',
            defualt: 'None',
            options: [{ value: 0, label: "None" }, ...Tax.map(t => ({ value: parseInt(t.id), label: t.name }))]
        },
        {
            label: 'Tax Type',
            field: 'tax_type',
            type: 'select',
            defualt: 'None',
            options: [{value: 2, label: "Inclusive"},{value: 1, label: "Exclusive"}]
        },
        { label: 'Tax Amount', field: 'taxAmount', type: 'number', columnClass: 'lvw-10' },
        { label: 'Total', field: 'total', type: 'number', columnClass: 'lvw-10' },
        { label: 'On Floor', field: 'onfloor', type: 'checkbox', columnClass: 'lvw-2' }
    ];

    const handleAddRow = () => {
        const newRow = { ...initialProductRow }
        setInitialProductRows([...initialProductRows, newRow]);
    };

    const [productsRows, setProductsRows] = useState([]);

    const handleProductChange = (index, field, value) => {
        const updatedRows = initialProductRows.map((row, i) => {
            if (i === index) {
                const updatedRow = { ...row, [field]: value };

                if (field === 'product') {
                    const selectedProduct = Products.find((product) => product.id === value);
                    if (selectedProduct) {
                        updatedRow.unitPrice = selectedProduct.product_purchase_price;
                        updatedRow.disc = selectedProduct.discount_value;
                        updatedRow.disc_type = selectedProduct.discount_type;
                        if (vendorTax !== '') {
                            updatedRow.tax_type = vendorTaxType;
                            updatedRow.tax = vendorTax.taxrate;
                            updatedRow.tax_id = vendorTax.id;
                        } else { 
                            updatedRow.tax_type = selectedProduct.tax_type;
                            if (selectedProduct.tax_value != null) {
                                let tax = Tax.find((tax) => tax.id === selectedProduct.tax_value);
                                updatedRow.tax = tax ? parseFloat(tax.taxrate) : "";
                                updatedRow.tax_id = tax ? tax.id : 0;
                            } else {
                                updatedRow.tax_id = 0;
                                updatedRow.tax = 0;
                            }
                        }
                    }
                }
                
                if (['bQty', 'rQty', 'fQty', 'disc', 'taxAmount', 'total'].includes(field)) {
                    updatedRow[field] = value === "" ? 0 : value;

                    if (field === 'bQty') {
                        updatedRow.rQty = value === "" ? 0 : value;
                    }
                }

                if (field === 'tax_type') {
                    updatedRow.tax_type = parseInt(value);
                }

                if (field === 'tax_id') {
                    if (value !== 0) {
                        updatedRow.tax_id = parseInt(value);
                        let tax = Tax.find((tax) => tax.id === parseInt(value));
                        updatedRow.tax = tax ? parseFloat(tax.taxrate) : 0;
                    } else {
                        updatedRow.tax_id = 0;
                        updatedRow.tax = 0;
                    }
                }

                if (field === 'disc_type') {
                    updatedRow.disc_type = parseInt(value);
                }

                // Update total when discount or other fields change
                if (['disc_type', 'disc', 'unitPrice', 'bQty', 'rQty', 'tax_type', 'tax', 'tax_id'].includes(field)) {
                    if (updatedRow.rQty === 0) {
                        updatedRow.taxAmount = 0;
                        updatedRow.total = 0;
                    } else {
                        const taxData = Tax.find(tax => parseInt(tax.id) === parseInt(updatedRow.tax_id));
                        const qty = updatedRow.rQty || 1;

                        // Calculate discount based on type (percentage or Rs off)
                        const discountAmount = updatedRow.disc_type === 0 
                            ? (updatedRow.unitPrice * qty * updatedRow.disc) / 100 
                            : updatedRow.disc * qty;
                        updatedRow.discount_amount = discountAmount;

                        // Calculate tax amount (for exclusive tax only)
                        let taxAmount = 0;
                        if (taxData) {
                            taxAmount = updatedRow.tax_type === 1
                            ? ((updatedRow.unitPrice - (updatedRow.disc_type === 0 ? updatedRow.unitPrice * updatedRow.disc / 100 : updatedRow.disc)) * parseFloat(updatedRow.tax)) / 100
                            : (updatedRow.unitPrice - (updatedRow.disc_type === 0 ? updatedRow.unitPrice * updatedRow.disc / 100 : updatedRow.disc)) * parseFloat(updatedRow.tax) / (100 + parseFloat(updatedRow.tax));
                        }
                        
                        // Calculate total based on tax type
                        if (updatedRow.tax_type === 1) { // Tax is exclusive
                            updatedRow.total = ((updatedRow.unitPrice * qty) - discountAmount + (taxAmount * qty)).toFixed(2);
                        } else { // Tax is inclusive
                            updatedRow.total = ((updatedRow.unitPrice * qty) - discountAmount).toFixed(2);
                        }

                        const discountedItemPrice = updatedRow.unitPrice - (updatedRow.disc_type === 0 ? updatedRow.unitPrice * updatedRow.disc / 100 : updatedRow.disc);

                        updatedRow.taxAmount = (taxAmount * qty).toFixed(2); // Store tax amount for display
                        
                        const taxableAmount = discountedItemPrice - ((discountedItemPrice) * (parseFloat(updatedRow.tax) / (100 + parseFloat(updatedRow.tax))));

                        updatedRow.taxable_amount = updatedRow.tax_type === 2 ? taxableAmount * qty : (updatedRow.unitPrice * qty) - discountAmount;

                        if (taxData && taxData.iscombined === 2) {
                            const childTaxes = taxData.child_ids.split(',');
                            const child1 = Tax.find((tax) => tax.id === parseInt(childTaxes[0])).taxrate;
                            const child2 = Tax.find((tax) => tax.id === parseInt(childTaxes[1])).taxrate;

                            const child1_amount = updatedRow.tax_type === 1
                            ? ((updatedRow.unitPrice - (updatedRow.disc_type === 0 ? updatedRow.unitPrice * updatedRow.disc / 100 : updatedRow.disc)) * parseFloat(child1)) / 100
                            : (updatedRow.unitPrice - (updatedRow.disc_type === 0 ? updatedRow.unitPrice * updatedRow.disc / 100 : updatedRow.disc)) * parseFloat(child1) / (100 + parseFloat(child1));

                            updatedRow.tax_1_amount = child1_amount * qty;

                            const child2_amount = updatedRow.tax_type === 1
                            ? ((updatedRow.unitPrice - (updatedRow.disc_type === 0 ? updatedRow.unitPrice * updatedRow.disc / 100 : updatedRow.disc)) * parseFloat(child2)) / 100
                            : (updatedRow.unitPrice - (updatedRow.disc_type === 0 ? updatedRow.unitPrice * updatedRow.disc / 100 : updatedRow.disc)) * parseFloat(child2) / (100 + parseFloat(child2));

                            updatedRow.tax_2_amount = child2_amount * qty;
                        }
                    }
                }

                return updatedRow;
            } else {
                return row;
            }
        });

        setInitialProductRows(updatedRows);

        // Update total quantity
        const totalProductQTY = updatedRows.reduce((acc, row) => acc + (parseFloat(row.rQty) || 0) + (parseFloat(row.fQty) || 0), 0);
        setTotalProductQuantity(totalProductQTY);

        // Calculate sub-total for products
        const subTotal = parseFloat(updatedRows.reduce((acc, row) => acc + (parseFloat(row.total) || 0), 0));

        const TotalTax = parseFloat(updatedRows.reduce((acc, row) => acc + (parseFloat(row.taxAmount) || 0), 0));

        // Calculate total item discount value
        const totalDiscount = updatedRows.reduce((totalDiscount, row) => {
            const discountAmount = row.disc_type === 0 
                        ? (row.unitPrice * row.rQty * row.disc) / 100 
                        : row.disc * row.rQty;
            return totalDiscount + discountAmount;
        }, 0);
        setTotalItemtotalItemDiscount(totalDiscount);
        // TODO: calculate final invoice amount and total after payment selection and overall discount application
        setInputData(prevState => ({ ...prevState, sub_total: subTotal.toFixed(2), total: subTotal.toFixed(2), final_invoice_amount: subTotal.toFixed(2), total_tax: TotalTax.toFixed(2), discount: totalDiscount.toFixed(2) }));

        // Filter rows that have description, unit price, and at least one quantity
        const validProducts = updatedRows.filter(row =>
            row.product && row.unitPrice &&
            (row.bQty || row.rQty || row.fQty)
        );
        setProductsRows(validProducts);
    };

    useEffect(() => {
        CalculateGrandTotal();
    }, [inputData.shipping_tax_type, inputData.shipping_tax_value, inputData.total_shipping_tax, inputData.other_charges_tax_type, inputData.other_charges_tax_value, inputData.total_other_charges, productsRows, inputData.payment_type]);

    const CalculateGrandTotal = () => {
        const ProductsTotalAmount = parseFloat(productsRows.reduce((acc, row) => acc + (parseFloat(row.total) || 0), 0));

        var freightCharge = 0;
        if (!isNaN(inputData.shipping_tax_value)) {
            freightCharge = parseInt(inputData.shipping_tax_type) === 1 ? (parseFloat(inputData.total_shipping_tax) + (parseFloat(inputData.total_shipping_tax) * parseFloat(inputData.shipping_tax_value) / 100))  : parseFloat(inputData.total_shipping_tax);
        }

        var otherTax = 0;
        if (!isNaN(inputData.other_charges_tax_value)) {
            otherTax = parseInt(inputData.other_charges_tax_type) === 1 ? (parseFloat(inputData.total_other_charges) + (parseFloat(inputData.total_other_charges) * parseFloat(inputData.other_charges_tax_value) / 100)) : parseFloat(inputData.total_other_charges);
        }

        var GrandTotal = ProductsTotalAmount + freightCharge + otherTax;

        if (parseInt(inputData.overall_discount) != 0) {
            const overallDiscount = parseFloat(inputData.overall_discount_type) === 0 ? (parseFloat(GrandTotal) * parseFloat(inputData.overall_discount) / 100) : parseFloat(inputData.overall_discount);

            GrandTotal = GrandTotal - overallDiscount;
        }

        if (parseInt(inputData.payment_type) === 4) {
            setInputData(prevState => ({ ...prevState, amount_due: GrandTotal, amount_paid: 0 }));
        } else {
            setInputData(prevState => ({ ...prevState, amount_due: 0, amount_paid: GrandTotal }));
        }

        setInputData(prevState => ({ ...prevState, final_invoice_amount: GrandTotal }));
    }

    useEffect(() => {
        handleOverallDiscountChange();
    }, [inputData.overall_discount, inputData.overall_discount_type])

    const handleOverallDiscountChange = () => {
        const updatedProductRows = productsRows.map(updatedRow => {
            const qty = updatedRow.rQty;

            // Set discount to 0
            updatedRow.disc = 0;

            // Calculate tax amount based solely on unitPrice without considering discount
            const taxAmount = updatedRow.tax_type === 1
                ? (updatedRow.unitPrice * parseFloat(updatedRow.tax)) / 100
                : 0;

            // If tax is exclusive (tax_type === 1), add tax to the total
            if (updatedRow.tax_type === 1) { 
                updatedRow.total = (updatedRow.unitPrice * qty) + (taxAmount * qty);
            } else { 
                // If tax is inclusive (tax_type === 2), no additional tax added to the total
                updatedRow.total = (updatedRow.unitPrice * qty);
            }

            // Update the tax amount for the product row
            updatedRow.taxAmount = taxAmount * qty;

            return updatedRow;
        });

        const subTotal = parseFloat(updatedProductRows.reduce((acc, row) => acc + (parseFloat(row.total) || 0), 0));

        const TotalTax = parseFloat(updatedProductRows.reduce((acc, row) => acc + (parseFloat(row.taxAmount) || 0), 0));
        
        const totalItemDiscount = inputData.overall_discount_type === 0 ? parseFloat(inputData.overall_discount) / 100 * subTotal : parseFloat(inputData.overall_discount);

        setInputData(prevState => ({ ...prevState, sub_total: subTotal, total: subTotal, final_invoice_amount: subTotal, total_tax: TotalTax, discount: totalItemDiscount }));
        setProductsRows(updatedProductRows);

        setTotalItemtotalItemDiscount(totalItemDiscount);
    };

    // Change card title
    const handleClick = event => {
        var buttonID = event.currentTarget.id;
        var button = document.getElementById(buttonID);
        var cardtitle = document.getElementById('cardTitle1');
        var buttonText = button.textContent;
        cardtitle.innerHTML = buttonText;
    };

    const GeneratePINumber = async (SetNextNumber) => {
        try {
            // Temporarely Fetch the PO number from the API 
            const response = await fetch(Backend_BASE_URL + 'api/create_prefix', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    prefix_type: 'pi_prefix_type',
                    prefix: 'pi_prefix',
                    number_format: 'pi_number_format',
                    next_number: 'next_pi_number',
                    current_time: defaultValue,
                    setnextinvoicenumber: SetNextNumber
                }) // Send parameters in the body as JSON
            });
            const data = await response.json();

            // Extract the PO number from the response data
            const piNumber = data.data.genereted_prifix_number;
            setInputData(prevState => ({ ...prevState, reference_view_number: piNumber }))
        } catch (error) {
            console.error('Error:', error);
            toast.error('An unexpected error occurred');
        }
    }

    const handleVendorChange = (e) => {
        const vID = parseInt(e.target.value);
        setInputData(prevState => ({ ...prevState, vendor: vID }))

        let ven = vendor.find(v => v.id === vID);
        let vendorTax = Tax.find((tax) => tax.id === ven.tax);
        setVendorTax(vendorTax || "");
        setvendorTaxType(ven.tax_type || 1);

    };

    const validateListViewProducts = () => {
        const validProducts = initialProductRows.filter(row => row.product !== '');

        if (validProducts.length === 0) {
            toast.error('Please Enter details of atleast one product', 'error')
            return false;
        }

        for (const [index, product] of validProducts.entries()) {
            if (!product.unitPrice || isNaN(product.unitPrice) || product.unitPrice <= 0) {
                toast.error(`Row ${index + 1}: Unit Price is required and must be a positive number.`);
                return false;
            }
            if (product.bQty <= 0) {
                toast.error(`Row ${index + 1}: B. Qty must be greater than 0.`);
                return false;
            }
        }

        return true;
    };

    const handleGeneratePI = async (e) => {
        if (!inputData.vendor) {
            toast.error('Please Select vendor', 'error')
            return;
        }
        if (!inputData.reference_number.trim()) {
            toast.error('Please Enter Proper Bill Number', 'error')
            return;
        }
        if (!inputData.invoice_date) {
            toast.error('Please Select bill date', 'error')
            return;
        }
        if (!inputData.pi_date) {
            toast.error('Please Select PI date', 'error')
            return;
        }
        if (!validateListViewProducts()) return;

        // Generate PI refrence number for current invoice with setting next number in db 
        GeneratePINumber(1);

        try {
            // Insert data into table
            const finalInputData = { ...inputData, products: productsRows }; // Add products to final inputdata
            if (isEdit && PIEditID) {
                const putResponse = await putToAPI(`FtsPurchaseOrderInvoice/${PIEditID}/`, finalInputData);
                if (putResponse.status) {
                    setisEdit(false);
                    setPIEditID(null);
                    UpdateDataTable();
                    setInputData(initialInputData);
                    setVendorTax('');
                    // Generate PI refrence number for next invoice without setting next number in db
                    GeneratePINumber(0);
                    setInitialProductRows(Array(10).fill({ ...initialProductRow }));
                    if(e.target.innerText === 'Generate Invoice' || e.target.innerText === 'Update Invoice') {
                        document.getElementById('pills-view-invoice-tab').click();
                    }
                    toast.success('Purchase order invoice updated Successfully');
                } else {
                    toast.error('Failed to update Purchase order invoice');
                }
            } else {
                const postResponse = await postToAPI("FtsPurchaseOrderInvoice/", finalInputData);
                if (postResponse.status) {
                    setisEdit(false);
                    setPIEditID(null);
                    UpdateDataTable();
                    setInputData(initialInputData);
                    setVendorTax('');
                    // Generate PI refrence number for next invoice without setting next number in db
                    GeneratePINumber(0);
                    setInitialProductRows(Array(10).fill({ ...initialProductRow }));
                    if(e.target.innerText === 'Generate Invoice') {
                        document.getElementById('pills-view-invoice-tab').click();
                    }
                    toast.success('Purchase order invoice Added Successfully');
                } else {
                    toast.error('Failed to add Purchase order invoice');
                }
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error('An unexpected error occurred');
        }
    }

    // TODO: implimentation of this function gloabally or in utility
    const getStatusFromStatusID = (id) => {
        let status, color;
        switch (id) {
            case 0:
                status = "Pending";
                color = "danger";
                break;

            case 1:
                status = "";
                color = "";
                break;

            case 2:
                status = "";
                color = "";
                break;
        }

        return [status, color];
    }

    const HandlePIDelete = (POID) => {
        try {
            showAlert('Do you really want to delete this record?', 'confirm', async (result) => {
                if (result) {
                    try {
                        const response = await deleteFromAPI(`FtsPurchaseOrderInvoice/${POID}/`);
                        if (response.status) {
                            toast.success('Purchase invoice deleted successfully', 'success');
                            handleCancelPI();
                            FetchPIData();
                            UpdateDataTable();
                        }
                    } catch (error) {
                        console.error('Error deleting purchase invoice:', error);
                        toast.error('purchase invoice Not Deleted', 'error');
                    }
                }
            }, { confirmButtonText: 'Delete', cancelButtonText: 'Cancel', title: 'Are you sure?', confirmButtonColor: '#dc3545' });
        } catch (error) {
            console.error('Error deleting purchase invoice:', error);
            toast.error('Error deleting purchase invoice', 'error');
        }
    }

    const UpdateDataTable = () => {
        const PIDT = $('#mainDatatablePurchaseInvoice').DataTable();
        getFromAPI("FtsPurchaseOrderInvoice/")
            .then(response => {
                setPIData(response);
                let DTData = response.map(row => [
                    row.reference_view_number,
                    handleDateFormat(row.invoice_date),
                    row.reference_number,
                    row.vendor.company_name,
                    row.total,
                    row.status,
                    row.id
                ]);
                PIDT.clear().rows.add(DTData).draw();
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }

    // datatable
    DataTable.Buttons.jszip(JSZip);
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    useEffect(() => {
        if (PIData && vendor.length > 0 && !$.fn.dataTable.isDataTable('#mainDatatablePurchaseInvoice')) {
            $('#mainDatatablePurchaseInvoice').DataTable({
                dom: "Bflrtip",
                buttons: [
                    {
                        extend: 'excel',
                        className: 'btn btn-outline-secondary',
                        text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon me-1"><path d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M12.9 14.5L15.8 19H14L12 15.6L10 19H8.2L11.1 14.5L8.2 10H10L12 13.4L14 10H15.8L12.9 14.5Z" style="fill: currentcolor;"></path></svg> Export to Excel',
                        exportOptions: {
                            columns: ':not(:last-child)',
                        },
                    },
                    {
                        extend: 'pdf',
                        className: 'btn btn-outline-secondary',
                        text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon me-1"><path d="M6,2A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2H6M6,4H13V9H18V20H6V4M8,12V14H16V12H8M8,16V18H13V16H8Z" style="fill: currentcolor;"></path></svg> Export to Pdf',
                        exportOptions: {
                            columns: ':not(:last-child)',
                        },
                        customize: function (doc) {
                            // Ensure pageSize and pageMargins are defined with fallback values
                            var pageWidth = (doc.pageSize && doc.pageSize.width) ? doc.pageSize.width : 545.28; // Default A4 width in points

                            var pageMargins = doc.pageMargins || [40, 40, 40, 40]; // Default margins if not set

                            // Adjust the page width to account for the margins
                            var availablePageWidth = pageWidth - pageMargins[0] - pageMargins[2];

                            // Get the number of columns in the table
                            var table = doc.content[1].table;
                            var columnCount = table.body[0].length;

                            // Set the width of each column as a number (without 'px')
                            table.widths = Array(columnCount).fill(availablePageWidth / columnCount);
                        
                            // Set table borders
                            table.body.forEach(function(row) {
                                row.forEach(function(cell) {
                                    cell.border = [true, true, true, true]; // Top, left, bottom, right
                                });
                            });
                            
                            doc.content[1].layout = {
                                hLineWidth: function (i, node) {
                                    return 1;
                                },
                                vLineWidth: function (i, node) {
                                    return 1;
                                },
                                hLineColor: function (i, node) {
                                    return '#000000'; // Border color for horizontal lines
                                },
                                vLineColor: function (i, node) {
                                    return '#000000'; // Border color for vertical lines
                                },
                                paddingLeft: function (i, node) { return 4; }, // Padding inside the cells
                                paddingRight: function (i, node) { return 4; },
                                paddingTop: function (i, node) { return 4; },
                                paddingBottom: function (i, node) { return 4; }
                            };

                            doc.styles.tableHeader.fontSize = 10;
                            doc.defaultStyle.fontSize = 10;
                        }
                    }
                ],
                data: PIData.map(row => [
                    row.reference_view_number,
                    handleDateFormat(row.invoice_date),
                    row.reference_number,
                    row.vendor.company_name,
                    row.total,
                    row.status,
                    row.id
                ]),
                columns: [
                    { title: "PI#" },
                    { title: "Bill Date" },
                    { title: "Bill No" },
                    { title: "Vendor Name" },
                    { title: "Total" },
                    {
                        title: "Status",
                        render: function (data, type, row) {
                            const [status, color] = getStatusFromStatusID(row[5]);
                            return (`<span class="badge bg-soft-success" >Full delivery</span>`)
                        }
                    },
                    {
                        title: "Action",
                        createdCell: function (td) {
                            // Add a class to the 'Action' column cells
                            td.classList.add('py-1');
                        },
                        render: function (data, type, row) {
                            return `
                        <button class="btn btn-light btn-sm pi-viewinvoice" data-pi_id="${row[6]}">View Invoice</button>
                        <button class="btn btn-light btn-sm pi-update" data-pi_id="${row[6]}">Update</button>
                        <button class="btn btn-light btn-sm pi-delete" data-pi_id="${row[6]}">Delete</button>
                      `;
                        }
                    }
                ],
                autoWidth: false,
                language: {
                    search: '',
                    searchPlaceholder: "Search...",
                    paginate: {
                        previous: '«',
                        next: '»'
                    },
                }
            });

            if (document.getElementsByClassName("dt-buttons").length) {
                document.getElementById("mainDatatablePurchaseInvoice_wrapper").classList.add("dtl-right");
            } else {
                document.getElementById("mainDatatablePurchaseInvoice_wrapper").classList.remove("dtl-right");
            }

            // Event delegation to handle delete action
            document.querySelector('#mainDatatablePurchaseInvoice tbody').addEventListener('click', function (event) {
                if (event.target && event.target.classList.contains('pi-delete')) {
                    const pi_id = event.target.getAttribute('data-pi_id');
                    HandlePIDelete(pi_id);
                }
                if (event.target && event.target.classList.contains('pi-viewinvoice')) {
                    const pi_id = event.target.getAttribute('data-pi_id');
                    handleShowInvoice(pi_id);
                }
                if (event.target && event.target.classList.contains('pi-update')) {
                    const pi_id = event.target.getAttribute('data-pi_id');
                    handleUpdatePI(pi_id);
                }
            });
        }
    }, [PIData, vendor]);

    useEffect(() => {
        FetchProducts();
        // Generate PI number for refrence without setting next number in db
        GeneratePINumber(0);
        fetchCustomerData();
        FetchPIData();
        fetchTaxData(setTax);
        FetchPaymentModes();
    }, []);

    const columns = [
        { header: '#', tableText: 'srno' },
        { header: 'Product', tableText: 'productName' },
        { header: 'Quantity (Consumable)', tableText: 'qtyConsumable' },
        { header: 'Quantity (Retail)', tableText: 'qtyRetail' },
        { header: 'Unit Price', tableText: 'unitPrice' },
        { header: 'Discount', tableText: 'discount' },
        { header: 'Texable', tableText: 'texable' },
        { header: 'CGST(%)', tableText: 'cGstPer' },
        { header: 'CGST(Rs)', tableText: 'cGstRs' },
        { header: 'SGST(%)', tableText: 'sGstPer' },
        { header: 'SGST(Rs)', tableText: 'sGstRs' },
        { header: 'IGST(%)', tableText: 'iGstPer' },
        { header: 'IGST(Rs)', tableText: 'iGstRs' },
        { header: 'Total', tableText: 'total' }
    ];

    // Add Invoice modal
    const [invoiceModalShow, setInvoiceModalShow] = useState(false);
    const handleShowInvoice = (PIid) => {
        getFromAPI(`getPOIDataWithProducts/${PIid}/`).then(res => {
            console.log("res ==> ", res);
            const paymentType = PaymentModes.find((pm) => parseInt(pm.id) === parseInt(res.PIdata.payment_type)).name;
            const taxableAmount = parseInt(res.PIdata.final_invoice_amount) - parseInt(res.PIdata.total_tax);
            // TODO: Set branch data 
            setFromInfoData( [
                { label: 'ID', value: res.PIdata.reference_view_number },
                { label: 'Dilivery Date', value: res.PIdata.pi_date },
                { label: 'Invoice Date', value: res.PIdata.invoice_date },
                { label: 'Vendor Name', value: res.PIdata.vendor.company_name },
                { label: 'Address', value: res.PIdata.vendor.primary_address },
                { label: 'Mobile Number', value: res.PIdata.vendor.phone_number1 },
                { label: 'Email', value: res.PIdata.vendor.email },
                { label: 'GSTIN', value: res.PIdata.vendor.gst_number }
            ]);
            let ViewInvoiceProductsList = res.PIProducts.map((item, i) => {
                // TODO: calculate taxebale amount
                const discount = item.discount_value > 0 ? parseInt(item.discount_type) === 1 ? item.discount_value + ' Rs. off' : item.discount_value + '% off' : 0;

                var iGSTperc = 0;
                var cGSTperc = 0;
                var sGSTperc = 0;
                var iGSTval = 0;
                var cGSTval = 0;
                var sGSTval = 0;

                if (item.item_tax_id != 0) {
                    const tax = Tax.find((tax) => tax.id === item.item_tax_id);
                    if (tax.iscombined === 2) {
                        const childTaxes = tax.child_ids.split(',');
                        cGSTperc = Tax.find((tax) => tax.id === parseInt(childTaxes[0])).taxrate;
                        sGSTperc = Tax.find((tax) => tax.id === parseInt(childTaxes[1])).taxrate;
                        cGSTval = item.tax_1_amount;
                        sGSTval = item.tax_2_amount;
                    } else {
                        iGSTperc = tax.taxrate;
                        iGSTval = item.total_tax_amount;
                    }
                }

                return {
                    srno: i+1, productName: item.product.description, qtyConsumable: item.received_consumable_quantity, qtyRetail: item.received_retail_quantity, unitPrice: item.unit_price, discount: discount, texable: item.taxable_amount, cGstPer: cGSTperc + '%', cGstRs: cGSTval, sGstPer: sGSTperc + '%', sGstRs: sGSTval, iGstPer: iGSTperc + '%', iGstRs: iGSTval, total: item.total_amount 
                }
            })
            setPIProductsData(ViewInvoiceProductsList);
            setPITotalSummaryData([
                { totalSummaryTitle: 'Taxable Amount', totalSummaryAmount: taxableAmount },
                { totalSummaryTitle: 'Total Discount', totalSummaryAmount: res.PIdata.discount },
                { totalSummaryTitle: 'Total Tax', totalSummaryAmount: res.PIdata.total_tax },
                { totalSummaryTitle: 'Invoice Total', totalSummaryAmount: res.PIdata.final_invoice_amount },
                { totalSummaryTitle: 'Overall Discount', totalSummaryAmount: res.PIdata.overall_discount },
                { totalSummaryTitle: 'LR. No.', totalSummaryAmount: res.PIdata.lr_no },
                { totalSummaryTitle: 'Courier Company Name', totalSummaryAmount: res.PIdata.courier_company },
                { totalSummaryTitle: 'Packet Number', totalSummaryAmount: res.PIdata.packet_no },
                { totalSummaryTitle: 'Freight Charges', totalSummaryAmount: res.PIdata.total_shipping_tax },
                { totalSummaryTitle: 'Other Charges', totalSummaryAmount: res.PIdata.total_other_charges },
                { totalSummaryTitle: 'Payment Type', totalSummaryAmount: paymentType },
                { totalSummaryTitle: 'Amount Paid', totalSummaryAmount: res.PIdata.amount_paid },
                { totalSummaryTitle: 'Amount Due', totalSummaryAmount: res.PIdata.amount_due },
                { totalSummaryTitle: 'Amount Repaid', totalSummaryAmount: res.PIdata.amount_repaid },
                { totalSummaryTitle: 'Receicer Name', totalSummaryAmount: res.PIdata.receiver_name }
            ]);
            setInvoiceNotesData(res.Notes.notes);
            setInvoiceModalShow(true);
        });
    }
    const handleCloseInvoice = () => setInvoiceModalShow(false);

    const handleUpdatePI = (PIid) => {
        getFromAPI(`getPOIDataWithProducts/${PIid}/`).then(res => {
            setPIEditID(PIid);
            setisEdit(true);
            let vendorTax = Tax.find((tax) => tax.id === res.PIdata.vendor.tax);
            setVendorTax(vendorTax || "");
            setInputData({
                po_id: res.PIdata.po_id,
                reference_number: res.PIdata.reference_number,
                vendor: res.PIdata.vendor.id,
                pi_date: handleDateFormat(res.PIdata.pi_date),
                invoice_date: handleDateFormat(res.PIdata.invoice_date),
                sub_total: res.PIdata.sub_total,
                discount: res.PIdata.discount,
                overall_discount: parseInt(res.PIdata.overall_discount),
                overall_discount_type: parseInt(res.PIdata.overall_discount_type),
                total_tax: res.PIdata.total_tax,
                total: res.PIdata.total,
                shipping_charge: res.PIdata.shipping_charge,
                shipping_tax_type: parseInt(res.PIdata.shipping_tax_type),
                shipping_tax_id: res.PIdata.shipping_tax_id,
                shipping_taxable_amount: res.PIdata.shipping_taxable_amount,
                shipping_tax_amount: res.PIdata.shipping_tax_amount,
                shipping_tax_value: res.PIdata.shipping_tax_value,
                total_shipping_tax: res.PIdata.total_shipping_tax,
                other_charges: res.PIdata.other_charges,
                other_charges_tax_type: parseInt(res.PIdata.other_charges_tax_type),
                other_tax_id: res.PIdata.other_tax_id,
                other_taxable_amount: res.PIdata.other_taxable_amount,
                other_tax_amount: res.PIdata.other_tax_amount.other_charges_tax_value,
                other_charges_tax_value: res.PIdata.other_charges_tax_value,
                total_other_charges: res.PIdata.total_other_charges,
                final_invoice_amount: res.PIdata.final_invoice_amount,
                payment_type: parseInt(res.PIdata.payment_type),
                amount_paid: res.PIdata.amount_paid,
                amount_due: res.PIdata.amount_due,
                amount_repaid: res.PIdata.amount_repaid,
                transaction_discount: res.PIdata.transaction_discount,
                sms_sent: res.PIdata.sms_sent,
                ispaid: res.PIdata.ispaid,
                account_id: res.PIdata.account_id,
                po_credit_note_id: res.PIdata.po_credit_note_id,
                status: res.PIdata.status,
                delivery: res.PIdata.delivery,
                lr_no: res.PIdata.lr_no,
                courier_company: res.PIdata.courier_company,
                packet_no: res.PIdata.packet_no,
                receiver_name: res.PIdata.receiver_name,
                isprinted: res.PIdata.isprinted,
                isview: res.PIdata.isview,
                poi_tax_id: res.PIdata.poi_tax_id,
                poi_tax_amount: res.PIdata.poi_tax_amount,
                short_link: res.PIdata.short_link,
                long_link: res.PIdata.long_link,
                reference_view_number: res.PIdata.reference_view_number,
                advance_payment: res.PIdata.advance_payment,
                created_branch_id: res.PIdata.created_branch_id,
                created_user_id: res.PIdata.created_user_id,
                last_modified_user_id: res.PIdata.last_modified_user_id,
                created_date_time: formatISODateWithTimezone(res.PIdata.created_date_time),
                last_modified_date_time: formatISODateWithTimezone(new Date()),
                isdeleted: res.PIdata.isdeleted,
                narration: res.Notes.notes || '',
            });

            const ProductsList = res.PIProducts.map((productItem, i) => {
                const productType = parseInt(productItem.product.type);
                return {
                onfloor: productItem.isreturn === 0,
                serialNumber: i+1,
                product: productItem.product.id,
                unitPrice: productItem.unit_price,
                bQty: productType === 1 ? productItem.received_consumable_quantity: productItem.received_retail_quantity,
                rQty: productType === 1 ? productItem.received_consumable_quantity: productItem.received_retail_quantity,
                fQty: productType === 1 ? productItem.received_free_consumable_quantity: productItem.received_free_retail_quantity,
                disc: productItem.discount_value,
                disc_type: productItem.discount_type,
                taxAmount: productItem.total_tax_amount,
                tax_type: productItem.tax_type,
                total: productItem.total_amount,
                tax: productItem.tax_value,
                tax_id: productItem.item_tax_id,
                taxable_amount: productItem.taxable_amount,
                discount_amount: productItem.discount_amount,
                tax_1_amount: productItem.tax_1_amount,
                tax_2_amount: productItem.tax_2_amount,
            }});

            setProductsRows(ProductsList)

            const totalProductQTY = ProductsList.reduce((acc, row) => acc + (parseFloat(row.rQty) || 0) + (parseFloat(row.fQty) || 0), 0);
            setTotalProductQuantity(totalProductQTY);

            setInitialProductRows(ProductsList);

            document.getElementById('pills-add-invoice-tab').click();
            
            
        });
    }

    // Add vendot modal
    const [vendorModalShow, setVendorModalShow] = useState(false);
    const handleShowVendor = () => setVendorModalShow(true);
    const handleCloseVendor = () => setVendorModalShow(false);

    // Add product list modal
    const [searchTerm, setSearchTerm] = useState("");
    const productData = [
        { name: "Apple iPhone 13", price: 999, stock: 10 },
        { name: "Samsung Galaxy S21", price: 849, stock: 15 },
        { name: "Google Pixel 6", price: 599, stock: 20 },
        { name: "Sony WH-1000XM4 Headphones", price: 299, stock: 8 },
        { name: "Dell XPS 13 Laptop", price: 1099, stock: 5 },
        { name: "Apple MacBook Air", price: 999, stock: 12 },
        { name: "Bose SoundLink Speaker", price: 129, stock: 25 },
        { name: "Sony PlayStation 5", price: 499, stock: 4 },
        { name: "Apple iPad Air", price: 599, stock: 18 },
    ];
    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };
    const filteredData = productData.filter((product) =>
        product.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleCancelPI = () => {
        setisEdit(false);
        setPIEditID(null);
        UpdateDataTable();
        setInputData(initialInputData);
        setVendorTax('');
        // Generate PI refrence number for next invoice without setting next number in db
        GeneratePINumber(0);
        setInitialProductRows(Array(10).fill({ ...initialProductRow }));
        document.getElementById('pills-view-invoice-tab').click();
    }

    return (
        <>
            <div className="d-sm-flex justify-content-between align-items-center mb-3">
                <Card.Title className='mb-sm-0' id="cardTitle1">View Purchase Invoice</Card.Title>
                <div className="custom-top-nav-tab mt-3 mt-lg-0">
                    <ul className="nav nav-pills btn-list" id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" onClick={handleClick} data-text="View Purchase Invoice" id="pills-view-invoice-tab" data-bs-toggle="pill" data-bs-target="#pills-view-invoice" type="button" role="tab" aria-controls="pills-view-invoice" aria-selected="true">
                                <Icon path={mdiReceiptTextOutline} className="btn-icon me-1" />View Purchase Invoice
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" onClick={handleClick} data-text="Add Purchase Invoice" id="pills-add-invoice-tab" data-bs-toggle="pill" data-bs-target="#pills-add-invoice" type="button" role="tab" aria-controls="pills-add-invoice" aria-selected="false">
                                <Icon path={mdiPlus} className="btn-icon me-1" />Add Purchase Invoice
                            </button>
                        </li>
                    </ul>
                </div>
            </div>

            <div className="tab-content" id="pills-tabContent">
                <div className="tab-pane fade show active" id="pills-view-invoice" role="tabpanel" aria-labelledby="pills-view-invoice-tab" tabIndex="0">
                    <Table className='table-nowrap' responsive bordered id='mainDatatablePurchaseInvoice'>
                        <thead className='table-light'>
                        </thead>
                    </Table>
                </div>
                <div className="tab-pane fade" id="pills-add-invoice" role="tabpanel" aria-labelledby="pills-add-invoice-tab" tabIndex="0">
                    <Form>
                        <Row>
                            <Col sm={3} xxl={1}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>PI No</Form.Label>
                                    <Form.Control type="text" value={inputData.reference_view_number} placeholder="Enter pi no..." name='piNo' id='piNo' disabled />
                                </div>
                            </Col>
                            <Col sm={9} xxl={3}>
                                <div className="mb-3 d-flex">
                                    <div className="custom-form-input w-100">
                                        <Form.Label>Vendor Name</Form.Label>
                                        <Form.Select name='vendorName' id='vendorName' onChange={handleVendorChange} value={inputData.vendor} >
                                            <option>Select Vendor</option>
                                            {vendor && vendor.map((v) => (
                                                <option key={v.id} value={v.id}>
                                                    {v.company_name}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </div>
                                    <Button variant="light custom-form-input-btn ms-2 mt-2" onClick={handleShowVendor}>
                                        <Icon path={mdiPlus} size={1} />
                                    </Button>
                                </div>
                            </Col>
                            <Col sm={6} lg={3} xxl={2}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Vendor Tax</Form.Label>
                                    <Form.Control type="text" value={vendorTax && vendorTax.name} placeholder="Tax" name='vendorTax' id='vendorTax' disabled />
                                </div>
                            </Col>
                            <Col sm={6} lg={3} xxl={2}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Bill No</Form.Label>
                                    <Form.Control type="text" value={inputData.reference_number} placeholder="Receipt no" name='billNo' id='billNo' onChange={e => setInputData({ ...inputData, reference_number: e.target.value })} />
                                </div>
                            </Col>
                            <Col sm={6} lg={3} xxl={2}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Bill Date</Form.Label>
                                    <Form.Control type="date" value={inputData.invoice_date} name='billDate' id='billDate' onChange={e => setInputData({ ...inputData, invoice_date: handleDateFormat(e.target.value) })} />
                                </div>
                            </Col>
                            <Col sm={6} lg={3} xxl={2}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>PI Date</Form.Label>
                                    <Form.Control type="date" value={inputData.pi_date} name='piDate' id='piDate' onChange={e => setInputData({ ...inputData, pi_date: handleDateFormat(e.target.value) })} />
                                </div>
                            </Col>
                        </Row>
                    </Form>

                    <ul className='list-inline text-md-end text-muted fs-14 mb-2'>
                        <li className='list-inline-item me-5 mb-2'>
                            GST No : <span className='text-muted-dark'>0</span>
                        </li>
                        <li className='list-inline-item mb-2'>
                            Advance Payment : <span className='text-muted-dark'>0</span>
                        </li>
                    </ul>

                    {/* <div className="table-max-height"> */}
                    
                        <ListViewTable
                            initialRows={initialProductRows}
                            columnsConfig={ProductColumnsConfig}
                            onAddRow={handleAddRow}
                            handleOnChange={handleProductChange}
                        />
                    {/* </div> */}

                    <ul className='list-inline fw-medium text-dark fs-16 mb-2'>
                        <li className='list-inline-item mb-2 me-5'>Total Product : {productsRows.length}</li>
                        <li className='list-inline-item mb-2'>Total Qty : {totalProductQuantity}</li>
                    </ul>

                    <Form>
                        <Row>
                            <Col md={6} xl={3}>
                                <div className="mb-3 custom-form-input custom-form-input-sm">
                                    <Form.Label>LR. No.</Form.Label>
                                    <Form.Control type="number" value={inputData.lr_no} name='lrNo' id='lrNo' onChange={e => setInputData({ ...inputData, lr_no: e.target.value })} />
                                </div>
                                <div className="mb-3 custom-form-input custom-form-input-sm">
                                    <Form.Label>Courier Company Name</Form.Label>
                                    <Form.Control type="text" value={inputData.courier_company} name='courierCompanyName' id='courierCompanyName' onChange={e => setInputData({ ...inputData, courier_company: e.target.value })} />
                                </div>
                                <Row className='align-items-end'>
                                    <Col xs={8}>
                                        <InputGroup className="mb-3 custom-form-input custom-form-input-sm">
                                            <Form.Control type="number" value={inputData.total_shipping_tax} name='freightCharges' id='freightCharges' onChange={e => {
                                                setInputData({ ...inputData, total_shipping_tax: e.target.value });
                                        }} />
                                            <Form.Label>Freight Charges</Form.Label>
                                            <Form.Select size='sm' onChange={e => {
                                                e.target.value === "" ? setShippigTaxTypeDisable(true) : setShippigTaxTypeDisable(false);
                                                let taxID = e.target.value;
                                                let taxrate = e.target.options[e.target.selectedIndex].getAttribute('data-taxrate');
                                                setInputData({ ...inputData, shipping_tax_id: taxID ? parseInt(taxID) : 0, shipping_tax_value: taxrate ? parseFloat(taxrate) : 0 });
                                            }}>
                                                <option value="">Select Tax</option>
                                                {Tax && Tax.map((data) =>
                                                    <option key={data.id} value={data.id} data-taxrate={data.taxrate}>{data.name}</option>
                                                )}
                                            </Form.Select>
                                        </InputGroup>
                                    </Col>
                                    <Col xs={4}>
                                        <div className="mb-3 custom-form-input custom-form-input-sm">
                                            <Form.Label>Tax Type</Form.Label>
                                            <Form.Select disabled={ShippigTaxTypeDisable} value={inputData.shipping_tax_type} onChange={e => {
                                                setInputData({ ...inputData, shipping_tax_type: e.target.value });
                                        }}>
                                                <option value="1">Exclusive</option>
                                                <option value="2">Inclusive</option>
                                            </Form.Select>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='align-items-end'>
                                    <Col xs={8}>
                                        <InputGroup className="mb-3 custom-form-input custom-form-input-sm">
                                            <Form.Control type="number" value={inputData.total_other_charges} name='otherCharges' id='otherCharges' onChange={e => {
                                                setInputData({ ...inputData, total_other_charges: e.target.value });
                                                }} />
                                            <Form.Label>Other Charges</Form.Label>
                                            <Form.Select size='sm' value={inputData.other_tax_id} onChange={e => {
                                                e.target.value === "" ? setOtherTaxTypeDisable(true) : setOtherTaxTypeDisable(false);
                                                let taxID = e.target.value;
                                                let taxrate = e.target.options[e.target.selectedIndex].getAttribute('data-taxrate');
                                                setInputData({ ...inputData, other_tax_id: taxID ? parseInt(taxID) : 0, other_charges_tax_value : taxrate ? parseFloat(taxrate) : 0});
                                                }} >
                                            <option value="">Select Tax</option>
                                                {Tax.map((data, i) =>
                                                    <option key={i} value={data.id} data-taxrate={data.taxrate}>{data.name}</option>
                                                )}
                                            </Form.Select>
                                        </InputGroup>
                                    </Col>
                                    <Col xs={4}>
                                        <div className="mb-3 custom-form-input custom-form-input-sm">
                                            <Form.Label>Tax Type</Form.Label>
                                            <Form.Select disabled={OtherTaxTypeDisable} value={inputData.other_charges_tax_type} onChange={e => {
                                                setInputData({ ...inputData, other_charges_tax_type: e.target.value });
                                                }}>
                                                <option value="1">Exclusive</option>
                                                <option value="2">Inclusive</option>
                                            </Form.Select>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm={6} xl={3}>
                                <div className="mb-3 custom-form-input custom-form-input-sm">
                                    <Form.Label>Payment Type</Form.Label>
                                    <Form.Select value={inputData.payment_type} name='paymentType' id='paymentType' onChange={e => setInputData({ ...inputData, payment_type: e.target.value })} >
                                        {PaymentModes && PaymentModes.map((data) =>
                                            <option key={data.id} value={data.id} >{data.name}</option>
                                        )}
                                    </Form.Select>
                                </div>
                                <div className="mb-3 custom-form-input custom-form-input-sm">
                                    <Form.Label>Receiver Name</Form.Label>
                                    <Form.Control type="text" value={inputData.receiver_name} name='receiverName' id='receiverName' onChange={e => setInputData({ ...inputData, receiver_name: e.target.value })} />
                                </div>
                                <div className="mb-3 custom-form-input custom-form-input-sm">
                                    <Form.Label>Packet Number</Form.Label>
                                    <Form.Control type="number" value={inputData.packet_no} name='packetNumber' id='packetNumber' onChange={e => setInputData({ ...inputData, packet_no: e.target.value })} />
                                </div>
                                <div className="mb-3 custom-form-input custom-form-input-sm">
                                    <Form.Label>Amount Paid</Form.Label>
                                    <Form.Control type="number" value={inputData.amount_paid} name='amountPaid' id='amountPaid' onChange={e => setInputData({ ...inputData, amount_paid: e.target.value })} />
                                </div>
                            </Col>
                            <Col sm={6} xl={3}>
                                <div className="mb-3 custom-form-input custom-form-input-sm">
                                    <Form.Label>Total Tax</Form.Label>
                                    <Form.Control type="number" value={inputData.total_tax} name='totalTax' id='totalTax' onChange={e => setInputData({ ...inputData, total_tax: e.target.value })} disabled />
                                </div>
                                <InputGroup className="mb-3 custom-form-input custom-form-input-sm">
                                    <Form.Control type='number' value={inputData.overall_discount} name='overallDiscount' id='overallDiscount' onChange={e => setInputData({...inputData, overall_discount: e.target.value})} />
                                    <Form.Label>Overall Discount</Form.Label>
                                    <Form.Select size='sm' value={inputData.overall_discount_type} onChange={e => setInputData({...inputData, overall_discount_type:parseInt(e.target.value)})}>
                                        <option value="0">% Off</option>
                                        <option value="1">Rs. Off</option>
                                    </Form.Select>
                                </InputGroup>
                                <div className="mb-3 custom-form-input custom-form-input-sm">
                                    <Form.Label>Advance Payment</Form.Label>
                                    <Form.Control type="number" value={inputData.advance_payment} name='advancePayment' id='advancePayment' onChange={e => setInputData({ ...inputData, advance_payment: e.target.value })} />
                                </div>
                                <div className="mb-3 custom-form-input custom-form-input-sm">
                                    <Form.Label>Amount Due</Form.Label>
                                    <Form.Control type="number" value={inputData.amount_due} name='amountDue' id='amountDue' onChange={e => setInputData({ ...inputData, amount_due: e.target.value })} />
                                </div>
                            </Col>
                            <Col md={6} xl={3}>
                                <div className="mb-3 custom-form-input custom-form-input-sm">
                                    <Form.Label>Invoice Total</Form.Label>
                                    <Form.Control type="number" value={inputData.sub_total} name='invoiceTotal' id='invoiceTotal' disabled />
                                </div>
                                <div className="mb-3 custom-form-input custom-form-input-sm">
                                    <Form.Label>Total Item Discount</Form.Label>
                                    <Form.Control type="number" value={inputData.discount} name='totalItemDiscount' id='totalItemDiscount' disabled />
                                </div>
                                <div className="mb-3 custom-form-input custom-form-input-sm">
                                    <Form.Label>Advance Payment Amount</Form.Label>
                                    <Form.Control type="number" value={inputData.advance_payment} name='advancePaymentAmount' id='advancePaymentAmount' onChange={e => setInputData({ ...inputData, advance_payment: e.target.value })} />
                                </div>
                                <div className="mb-3 custom-form-input custom-form-input-sm">
                                    <Form.Label>Round Off</Form.Label>
                                    <Form.Control type="number" name='roundOff' id='roundOff' disabled />
                                </div>
                                <div className="mb-3 custom-form-input custom-form-input-sm">
                                    <Form.Label>Grand Total</Form.Label>
                                    <Form.Control type="number" value={inputData.final_invoice_amount} name='grandTotal' id='grandTotal' disabled />
                                </div>
                            </Col>
                        </Row>
                        <Row className='align-items-end'>
                            <Col xl={5} xxl={6}>
                                <div className="mb-3 mb-xl-0 custom-form-input custom-form-input-sm">
                                    <Form.Label>Narration</Form.Label>
                                    <Form.Control type="text" name='narration' id='narration' value={inputData.narration} onChange={e => setInputData({ ...inputData, narration: e.target.value })} />
                                </div>
                            </Col>
                            <Col xl={7} xxl={6} className='text-end'>
                                <div className="btn-list">
                                    {/* <Button variant="primary">Save & Print Barcode</Button>{' '} */}
                                    <Button variant="success" onClick={handleGeneratePI}> {isEdit && PIEditID ? 'Update' : 'Generate' } and New</Button>{' '}
                                    <Button variant="info" onClick={handleGeneratePI}>{ isEdit && PIEditID ? 'Update' : 'Generate' } Invoice</Button>{' '}
                                    <Button variant="secondary" onClick={handleCancelPI}>Cancel</Button>{' '}
                                    {isEdit && PIEditID && (<Button variant="danger" onClick={() => HandlePIDelete(PIEditID)} >Delete{' '}</Button>)}
                                    
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>

            <QuickProductModal addProductShow={showModal} addProductClose={handleCloseModalPrList} />

            {/* Product List Modal */}
            {/* <Modal centered show={showModal} onHide={handleCloseModalPrList} size='lg'>
                <Modal.Header className='custom-close-btn' closeButton></Modal.Header>
                <Modal.Body>
                    <h5 className='mb-4'>Product List</h5>

                    <Form>
                        <Form.Group className="">
                            <Form.Control
                                type="text"
                                placeholder="Search"
                                onChange={handleSearch}
                                value={searchTerm}
                            />
                        </Form.Group>
                    </Form>

                    {searchTerm && filteredData.length > 0 && (
                        <div className='prlist-table-modal'>
                            <SimpleBarReact style={{ maxHeight: 300 }}>
                                <Table bordered hover className='mb-0'>
                                    <thead className="table-light">
                                        <tr>
                                            <th>Product Name</th>
                                            <th>Price</th>
                                            <th>Stock</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredData.map((product, index) => (
                                            <tr key={index}>
                                                <td>{product.name}</td>
                                                <td>{product.price}</td>
                                                <td>{product.stock}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </SimpleBarReact>
                        </div>
                    )}
                </Modal.Body>
            </Modal> */}

            {/* Add vendor modal */}
            <QuickVendorModal addVendorShow={vendorModalShow} addVendorClose={handleCloseVendor} /> 

            {/* View Invoice Modal */}
            <ViewInvoice invoiceTitle="Purchase Invoice" invoiceShow={invoiceModalShow} handleCloseInvoice={handleCloseInvoice} columns={columns} data={PIProductsData} fromInfo={fromInfoData} toInfo={toInfoData} totalSummary={PITotalSummaryData} invoiceNotes={invoiceNotesData} />
        </>
    )
}

export default function PurchaseInvoiceWithProvider() {
    return (
        <CustomerProvider>
            <PurchaseInvoice />
        </CustomerProvider>
    );
}
