import React from 'react';
import { Form } from 'react-bootstrap';

export default function SelectElement({ handleOnChange, selectName, selectId, selectValue, selectClass, selectDisabled, selectOptions }) {
    return (
        <>
            <Form.Select
                name={selectName}
                id={selectId}
                size='sm'
                value={selectValue}
                className={selectClass || ''}
                disabled={selectDisabled}
                onChange={(e) => handleOnChange(e)}
            >
                {selectOptions.map((option, optIndex) => (
                    <option key={optIndex} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </Form.Select>
        </>
    )
}
