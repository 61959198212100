import React, { useState, useEffect } from 'react';
import Footer from '../layout/Footer';
import Details from './Details';
import { Container, Row, Col, Card, Form, Button, Nav } from 'react-bootstrap';
import SimpleBarReact from "simplebar-react";
import { toast, ToastContainer } from "react-toastify";
import { postToAPI, getFromAPI,formatISODateWithTimezone } from '../../Utils/utils';
import { CustomerContext1 } from './vendorContext';
import { CustomerProvider, useCustomer } from './getVendor';

// icon
import Icon from '@mdi/react';
import { mdiMagnify, mdiAccountDetails, mdiPlus } from '@mdi/js';

import VendoeProduct from "./vendor-product-list-data.json";
import { fetchVendorData } from '../../Utils/function_list';
import { fetchBankData,fetchCountryData,fetchTaxData,fetchCutsomerData,fetchBranchData } from '../../Utils/function_list';

function Vendor() {


    const date = new Date();
    const futureDate = date.getDate();
    date.setDate(futureDate);
    const defaultValue = date.toLocaleDateString('en-CA');

    const [country, setCountry] = useState([]);
    const [selectedVendorId, setselectedVendorId] = useState('');
    const [State, setState] = useState([]);
    const [city, setCity] = useState([]);
    const [Tax, setTax] = useState([]);
    const { vendor, fetchCustomerData } = useCustomer();
    const [Bank, setBank] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [VendorProducts, setVendorProducts] = useState([]);
    const [branchData, setBranchData] = useState([]);
    const getLoginBranchId = localStorage.getItem('createdBranchId');

    // Initialize branch input data state
    const [branchInputData, setBranchInputData] = useState({
            bcountryCode: '',
            bStateCode: '',
            bcityCode: ''
    });
        
    const initialInputData = {
        name: '',
        company_name: '',
        phone_number1: '',
        email: '',
        gst_number: '',
        country: 0,
        state: 0,
        city: branchInputData.bcityCode,
        tax: 0,
        pan_card_number: '',
        opening_balance: 0.0,
        current_balance: 0.0,
        bank_branch_name: '',
        bank_branch_ifsc_code: '',
        bank_account_number: 0,
        website: '',
        phone_number2: '',
        primary_address: '',
        tax_id: 0,
        tax_type: 0,
        isactive: 1,
        isdeleted: 0,
        created_branch_id: 1,
        created_date_time: formatISODateWithTimezone(new Date()),
        last_modified_date_time: formatISODateWithTimezone(new Date()),
        created_user_id: 1,
        last_modified_user_id: 1
    };

    // Fetch initial data
    useEffect(() => {
        fetchBranchData(setBranchData);
        fetchCountryData(setCountry);
        fetchBankData(setBank);
        fetchTaxData(setTax);
    }, []);
    

    // Add new state for tax options
    const [taxOption1] = useState([
        { id: 1, name: 'B2G', code: 'b2c' },
        { id: 2, name: 'B2B', code: 'b2b' },
        { id: 3, name: 'B2B-IGST', code: 'b2b-igst' },
        { id: 4, name: 'B2C-IGST', code: 'b2c-igst' }
    ]);

    const [taxOption2] = useState([
        { id : 1,name:'Regular Tax' , code: 'regular'},
        { id : 2,name:'Composite Tax' , code: 'composite'},
        { id : 3, name : 'No Tax' , code: 'no'}
    ]);

    const [taxTypeOptions] = useState([
        {id : 1,name:'Exculsive',code: 'exculsive'},
        {id : 2,name:'Inclusive',code: 'inclusive'},
    ]);

    const [inputData, setInputData] = useState({ ...initialInputData });

    const filteredvendorData = vendor.filter(customer =>
        customer.company_name.toLowerCase().includes(searchTerm.toLowerCase())
    );


    useEffect(() => {
        const sidebarItems = document.querySelectorAll(".left-link-item"); // Use querySelectorAll for better targeting
    
        // Loop over all sidebar items
        sidebarItems.forEach((item) => {
            item.addEventListener('click', function () {
                // Remove active class from all sidebar links
                const activeLink = document.querySelector(".left-link-item a.active");
                if (activeLink) activeLink.classList.remove("active");
    
                // Add active class to the clicked item
                const link = this.querySelector('a');
                if (link) link.classList.add('active');
    
                // Update selected vendor ID
                const vendorId = this.getAttribute('data-id');
                setselectedVendorId(vendorId);
    
                // Trigger click on the "View Vendor" tab
                const viewVendorTab = document.getElementById("pills-view-vendor-tab");
                if (viewVendorTab) {
                    viewVendorTab.click();  // Ensure the tab is switched programmatically
                }
    
                // Trigger click on the "pills-details-vendor" element
                const detailsVendorTab = document.getElementById("pills-details-tab");
                if (detailsVendorTab) {
                    detailsVendorTab.click();  // Ensure the second tab is switched programmatically
                }
            });
        });
    
        // Cleanup listeners on component unmount
        return () => {
            sidebarItems.forEach((item) => {
                item.removeEventListener('click', () => {});
            });
        };
    }, [filteredvendorData]);
    
        // Effect to set the first vendor as default when vendor data changes
        useEffect(() => {
            if (vendor.length > 0 && !selectedVendorId) {
                // Set the first vendor's ID as the selected customer ID
                setselectedVendorId(filteredvendorData[0].id); // Assuming each vendor object has an 'id' property
            }
        }, [vendor]);
    
    // Handle input specifically for Mobile Number and Email
    const handleMobileChange = (e) => {
        const { name, value } = e.target;
        // Remove spaces from the input value
        setInputData((prev) => ({
            ...prev,
            [name]: value.replace(/\s+/g, ''), // Remove all spaces
        }));
    };

    useEffect(() => {
        const fetchProductsData = async () => {
            try {
                const response = await getFromAPI(`FtsItems/?vendor_id=${selectedVendorId}`);
                setVendorProducts(response);
            } catch (error) {
                return null;
            }
        };

        fetchProductsData();
    }, [selectedVendorId]);

    useEffect(() => {
        const fetchCountryData = async () => {
            try {
                const response = await getFromAPI("FtsCountries/");
                setCountry(response); // Save country data
            } catch (error) {
                return null;
            }
        };

        fetchCountryData();
    }, []);

    useEffect(() => {
        const fetchBankData = async () => {
            try {
                const response = await getFromAPI('FtsBanks/');
                setBank(response); // Save tax data
            } catch (error) {
                return null;
            }
        };

        fetchBankData();
    }, []);


    useEffect(() => {
        const fetchTaxData = async () => {
            try {
                const response = await getFromAPI('FtsTaxes/');
                setTax(response); // Save tax data
            } catch (error) {
                return null;
            }
        };

        fetchTaxData();
    }, []);

    useEffect(() => {
        fetchCustomerData();
    }, [selectedVendorId]);

    const handleCountryChange = async (e) => {
        const selectedCountry = e.target.value;
        setInputData(prevState => ({ ...prevState, country: parseInt(selectedCountry) }));

        try {
            const response = await getFromAPI(`FtsState/?country_code=${selectedCountry}`);
            setState(response); // Save state data
            setCity([]); // Reset city data
        } catch (error) {
            return null;
        }
    };

    const handleStateChange = async (e) => {
        const selectedState = e.target.value;
        setInputData(prevState => ({ ...prevState, state: parseInt(selectedState) }));

        try {
            const response = await getFromAPI(`FtsCity/?state_id=${selectedState}`);
            setCity(response); // Save city data
            // Set the first city as default if cities are available
            if (response && response.length > 0) {
                setInputData(prevState => ({
                    ...prevState,
                    city: response[0].id
                    }));
            }
        } catch (error) {
            return null;
        }
    };

    // Function to determine appropriate tax based on states
     const determineAppropiateTax = (customerState, branchState) => {
         if (customerState && branchState) {
            if (customerState === branchState) {
                return taxOption1.find(tax => tax.code === 'b2b-igst')?.id || '';
            } else {
                return taxOption1.find(tax => tax.code === 'b2b')?.id || '';
            }
     }
         return taxOption1.find(tax => tax.code === 'b2b')?.id || '';;
    };

  // Modify the useEffect for branch data to include tax handling
const initialData = () => {
    if (branchData.length) {
        const filteredBranchData = branchData.find(
            branch => branch.id === parseInt(getLoginBranchId, 10)
        );

        if (filteredBranchData) {
            const { country, state, city } = filteredBranchData;

            // Set initial branch input data
            setBranchInputData({
                bcountryCode: country,
                bStateCode: state,
                bcityCode: city,
            });

            // Set initial tax based on branch state
            const initialTax = determineAppropiateTax(state, state);

            // Update input data with branch and tax details
            setInputData(prev => ({
                ...prev,
                country: country,
                state: state,
                city: city,
                tax: initialTax,
            }));

            // Trigger country and state changes
            handleCountryChange({ target: { value: country } });
            handleStateChange({ target: { value: state } });
        }
    }
};

        // Function to extract state code and PAN from GST number
    const handleGSTChange = async (e) => {
        const gstNumber = e.target.value;
        setInputData(prevState => ({ ...prevState, gst_number: gstNumber }));

        if (gstNumber.length >= 15) { // Standard GST number length is 15
            const stateCode = gstNumber.substring(0, 2);
            const panNumber = gstNumber.substring(2, 12);

            // Update PAN automatically
            setInputData(prevState => ({
                ...prevState,
                pan_card_number: panNumber
            }));

            try {
                // First get the country code for India (assuming GST is Indian)
                const countryResponse = await getFromAPI('FtsCountries/?calling_code=91');
                if (countryResponse.length > 0) {
                    const countryCode = countryResponse[0].calling_code;
                    
                    // Set country to India
                    setInputData(prevState => ({
                        ...prevState,
                        country: countryCode
                    }));

                    // Fetch state data using the state code from GST
                    const stateResponse = await getFromAPI(`FtsState/?state_id=${stateCode}`);
                    if (stateResponse.length > 0) {
                        const stateData = stateResponse[0];
                        // Update tax based on GST state code
                        const newTax = determineAppropiateTax(stateData.state_code, branchInputData.bStateCode);
                        
                        // Update state
                        setInputData(prevState => ({
                            ...prevState,
                            state: stateData.state_id,
                            pan_card_number: panNumber,
                            tax : newTax,
                            tax_id : 1,
                            tax_type : 2
                        }));

                        // Fetch and set cities for the selected state
                        const cityResponse = await getFromAPI(`FtsCity/?state_id=${stateData.state_code}`);
                        setCity(cityResponse);
                        // Set the first city as default if cities are available
                        if (cityResponse && cityResponse.length > 0) {
                            setInputData(prevState => ({
                                ...prevState,
                                city: cityResponse[0].id
                            }));
                        }
                    }
                }
            } catch (error) {
                toast.error('Error processing GST number');
                return null;
            }
        }
    };

    const handleSubmit = async () => {
        if (!inputData.company_name || !inputData.company_name.trim()) {  // Fix: Add parentheses to trim()
            toast.error('Please Enter Company Name:', 'error');
            return;
        }
        if (!inputData.name || !inputData.name.trim()) {  // Fix: Add parentheses to trim()
            toast.error('Please Enter Contact Person Name:', 'error');
            return;
        }

        if (!inputData.phone_number1) {
            toast.error('Please Enter Mobile Number:', 'error');
            return;
        }
    
        try {
            const response = await postToAPI("add_vendor/", inputData);
            if (response.status) {
                toast.success('Vendor Added Successfully', 'success');
                fetchCustomerData(); // Update the vendor list
                setInputData(initialInputData);
            } else {
                toast.error('Failed to add vendor');
            }
        } catch (error) {
            toast.error('Email or Mobile Number Already Exist');
        }
    };
    
    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleCancel = (e) => {
        setInputData(initialInputData)
    };

    const handleTabClick = () => {
        initialData();
    };
    
    const [vendorProductData] = useState(VendoeProduct);
    return (
        <>
            <CustomerContext1.Provider value={selectedVendorId}>
                <div className="page-wrapper">
                    <div className='page-content'>
                        <Container>
                            <Row>
                                <Col md={12}>
                                    <Card>
                                        <Card.Body>
                                            <div className='d-lg-flex justify-content-between align-items-center'>
                                                <Form className='custom-form-width'>
                                                    <div className='custom-search-bar'>
                                                        <Icon path={mdiMagnify} size={1} className="custom-search-input-icon" />
                                                        <Form.Control className='' type="text" placeholder="Search vendor..." name='vendorSearch' id='vendorSearch' value={searchTerm}
                                                            onChange={handleSearchChange} />
                                                    </div>
                                                </Form>
                                                <div className="custom-top-nav-tab mt-3 mt-lg-0">
                                                    <ul className="nav nav-pills btn-list" id="pills-tab" role="tablist">
                                                        <li className="nav-item" role="presentation">
                                                            <button className="nav-link active" id="pills-view-vendor-tab" data-bs-toggle="pill" data-bs-target="#pills-view-vendor" type="button" role="tab" aria-controls="pills-view-vendor" aria-selected="true">
                                                                <Icon path={mdiAccountDetails} className="btn-icon me-1" />View Vendor
                                                            </button>
                                                        </li>
                                                        <li className="nav-item" role="presentation">
                                                            <button className="nav-link" id="pills-new-vendor-tab" data-bs-toggle="pill" data-bs-target="#pills-new-vendor" type="button" role="tab" aria-controls="pills-new-vendor" aria-selected="false" onClick={handleTabClick}>
                                                                <Icon path={mdiPlus} className="btn-icon me-1" />New Vendor
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>

                            <Row>
                                <Col lg={4} xxl={3}>
                                    <Card className='procust-left-list leftbar-list'>
                                        <SimpleBarReact>
                                            <Card.Body>
                                                <div className='left-list-bar'>
                                                    {filteredvendorData
                                                        .sort((a, b) => a.company_name.localeCompare(b.company_name)) // Sort in ascending order
                                                        .map((data, i) => (
                                                            <Nav.Item className='left-link-item' key={i} data-id={data.id}> {/* Add data-id attribute */}
                                                                <Nav.Link className={i === 0 ? "active" : ""}>
                                                                    <h5 className='product-tab-title'>{data.company_name}</h5>
                                                                </Nav.Link>
                                                            </Nav.Item>
                                                        ))}
                                                </div>
                                            </Card.Body>
                                        </SimpleBarReact>
                                    </Card>
                                </Col>
                                <Col lg={8} xxl={9}>

                                    <div className="tab-content custom-top-nav-tab" id="pills-tabContent">
                                        <div className="tab-pane fade show active" id="pills-view-vendor" role="tabpanel" aria-labelledby="pills-view-vendor-tab" tabIndex="0">
                                            <Card className='right-content-details'>
                                                <Card.Body>
                                                    <ul className="nav nav-pills nav-fill btn-list mb-3" id="pills-tab" role="tablist">
                                                        <li className="nav-item" role="presentation">
                                                            <button className="nav-link active" id="pills-details-tab" data-bs-toggle="pill" data-bs-target="#pills-details" type="button" role="tab" aria-controls="pills-details" aria-selected="true">Details</button>
                                                        </li>
                                                        <li className="nav-item" role="presentation">
                                                            <button className="nav-link" id="pills-product-list-tab" data-bs-toggle="pill" data-bs-target="#pills-product-list" type="button" role="tab" aria-controls="pills-product-list" aria-selected="false">Product List</button>
                                                        </li>
                                                        <li className="nav-item" role="presentation">
                                                            <button className="nav-link" id="pills-po-history-tab" data-bs-toggle="pill" data-bs-target="#pills-po-history" type="button" role="tab" aria-controls="pills-po-history" aria-selected="false">PO History</button>
                                                        </li>
                                                    </ul>

                                                    <div className="tab-content" id="pills-tabContent">
                                                        <div className="tab-pane fade show active" id="pills-details" role="tabpanel" aria-labelledby="pills-details-tab" tabIndex="0">
                                                            <Details setselectedVendorId={setselectedVendorId}/>
                                                        </div>
                                                        <div className="tab-pane fade" id="pills-product-list" role="tabpanel" aria-labelledby="pills-product-list-tab" tabIndex="0">
                                                            <Card.Title >Product List</Card.Title>
                                                            <Row>
                                                                <Col md={6}>
                                                                    <ul className='list-unstyled vendor-product-list-item mb-0'>
                                                                        {VendorProducts.map((item, i) =>
                                                                            <li key={i}>- {item.description}</li>
                                                                        )}
                                                                    </ul>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        <div className="tab-pane fade" id="pills-po-history" role="tabpanel" aria-labelledby="pills-po-history-tab" tabIndex="0">
                                                            <Card.Title className='mb-0'>PO History</Card.Title>
                                                        </div>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                        <div className="tab-pane fade" id="pills-new-vendor" role="tabpanel" aria-labelledby="pills-new-vendor-tab" tabIndex="0">
                                            <Card className='right-content-details'>
                                                <Card.Body>
                                                    <Card.Title>Add Vendor</Card.Title>
                                                    <Form>
                                                        <Row>
                                                            <Col md={6}>
                                                                <div className="mb-3 custom-form-input">
                                                                    <Form.Label>
                                                                        Company Name <span className='text-danger'>*</span>
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        name='companyName'
                                                                        id='companyName'
                                                                        placeholder="Enter company name..."
                                                                        value={inputData.company_name}
                                                                        onChange={e => setInputData({ ...inputData, company_name: e.target.value })}
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col md={6}>
                                                                <div className="mb-3 custom-form-input">
                                                                    <Form.Label>
                                                                        Contact Person <span className='text-danger'>*</span>
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        placeholder="Enter contact person..."
                                                                        name='contactPerson'
                                                                        id='contactPerson'
                                                                        value={inputData.name}
                                                                        onChange={e => setInputData({ ...inputData, name: e.target.value })}
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col md={6}>
                                                                <div className="mb-3 custom-form-input">
                                                                    <Form.Label>
                                                                        Mobile Number <span className='text-danger'>*</span>
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        type="number"
                                                                        placeholder="Please Enter Mobile Number..."
                                                                        name='phone_number1'
                                                                        id='phone_number1'
                                                                        value={inputData.phone_number1}
                                                                        onChange={handleMobileChange}
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col md={6}>
                                                                <div className="mb-3 custom-form-input">
                                                                    <Form.Label>
                                                                        Email ID
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        type="email"
                                                                        placeholder="Enter email..."
                                                                        name='contactPerson'
                                                                        id='contactPerson'
                                                                        value={inputData.email}
                                                                        onChange={e => setInputData({ ...inputData, email: e.target.value })}
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col md={12}>
                                                                <div className="mb-3 custom-form-input">
                                                                    <Form.Label>Address</Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        placeholder="Enter your address..."
                                                                        name='address'
                                                                        id='address'
                                                                        value={inputData.primary_address}
                                                                        onChange={e => setInputData({ ...inputData, primary_address: e.target.value })}
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col sm={6} md={4} xxl={3}>
                                                                <div className="mb-3 custom-form-input">
                                                                    <Form.Label>GST</Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        placeholder="Enter GST"
                                                                        name='gst_number'
                                                                        id='gst_number'
                                                                        value={inputData.gst_number}
                                                                        onChange={handleGSTChange}
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col sm={6} md={4} xxl={3}>
                                                                <div className="mb-3 custom-form-input">
                                                                    <Form.Label>Country</Form.Label>
                                                                    <Form.Select
                                                                        
                                                                        name='country'
                                                                        id='country'
                                                                        value={inputData.country}
                                                                        onChange={handleCountryChange}
                                                                    >
                                                                        <option value="">Select Country</option>
                                                                        {country.map(item => (
                                                                            <option key={item.id} value={item.calling_code}>{item.short_name}</option>
                                                                        ))}
                                                                    </Form.Select>
                                                                </div>
                                                            </Col>
                                                            <Col sm={6} md={4} xxl={3}>
                                                                <div className="mb-3 custom-form-input">
                                                                    <Form.Label>State</Form.Label>
                                                                    <Form.Select
                                                                        
                                                                        name='state'
                                                                        id='state'
                                                                        value={inputData.state}
                                                                        onChange={handleStateChange}
                                                                    >
                                                                        <option value="">Select State</option>
                                                                        {State.map(item => (
                                                                            <option key={item.id} value={item.state_id}>{item.state_name}</option>
                                                                        ))}
                                                                    </Form.Select>
                                                                </div>
                                                            </Col>
                                                            <Col sm={6} md={4} xxl={3}>
                                                                <div className="mb-3 custom-form-input">
                                                                    <Form.Label>City</Form.Label>
                                                                    <Form.Select
                                                                        
                                                                        name='city'
                                                                        id='city'
                                                                        value={inputData.city}
                                                                        onChange={e => setInputData({ ...inputData, city: parseInt(e.target.value) })}

                                                                    >
                                                                        <option value="">Select City</option>
                                                                        {city.map(item => (
                                                                            <option key={item.id} value={item.id}>{item.city_name}</option>
                                                                        ))}
                                                                    </Form.Select>
                                                                </div>
                                                            </Col>
                                                            <Col sm={6} md={4} xxl={3}>
                                                                <div className="mb-3 custom-form-input">
                                                                    <Form.Label>Tax</Form.Label>
                                                                    <Form.Select
                                                                        
                                                                        name='tax'
                                                                        id='tax'
                                                                        value={inputData.tax}
                                                                        onChange={e => setInputData({ ...inputData, tax: e.target.value })}

                                                                    >
                                                                        {taxOption1.map(item => (
                                                                            <option key={item.id} value={item.id}>{item.name}</option>
                                                                        ))}
                                                                    </Form.Select>
                                                                </div>
                                                            </Col>
                                                            <Col sm={6} md={4} xxl={3}>
                                                                <div className="mb-3 custom-form-input">
                                                                    <Form.Label>PAN</Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        className='text-up'
                                                                        placeholder="Enter PAN number..."
                                                                        name='panNumber'
                                                                        id='panNumber'
                                                                        value={inputData.pan_card_number}
                                                                        onChange={e => setInputData({ ...inputData, pan_card_number: e.target.value })}

                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col sm={6} md={4} xxl={3}>
                                                                <div className="mb-3 custom-form-input">
                                                                    <Form.Label>Opening Balance</Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        placeholder="Enter balance..."
                                                                        name='openingBalance'
                                                                        id='openingBalance'
                                                                        value={inputData.opening_balance}
                                                                        onChange={e => setInputData({ ...inputData, opening_balance: e.target.value })}

                                                                    />
                                                                </div>
                                                            </Col>
                                                            {inputData.gst_number && (
                                                                <>
                                                                    <Col sm={6} md={4} xxl={3}>
                                                                        <div className="mb-3 custom-form-input">
                                                                            <Form.Label>Tax</Form.Label>
                                                                            <Form.Select
                                                                                name="tax_id"
                                                                                id="tax_id"
                                                                                value={inputData.tax_id}
                                                                                onChange={e => setInputData({ ...inputData, tax_id: e.target.value })}
                                                                            >
                                                                                {taxOption2.map(item => (
                                                                                    <option key={item.id} value={item.id}>
                                                                                        {item.name}
                                                                                    </option>
                                                                                ))}
                                                                            </Form.Select>
                                                                        </div>
                                                                    </Col>
                                                                    <Col sm={6} md={4} xxl={3}>
                                                                        <div className="mb-3 custom-form-input">
                                                                            <Form.Label>Tax Type</Form.Label>
                                                                            <Form.Select
                                                                                name="tax_type"
                                                                                id="tax_type"
                                                                                value={inputData.tax_type}
                                                                                onChange={e => setInputData({ ...inputData, tax_type: e.target.value })}
                                                                            >
                                                                                {taxTypeOptions.map(item => (
                                                                                    <option key={item.id} value={item.id}>
                                                                                        {item.name}
                                                                                    </option>
                                                                                ))}
                                                                            </Form.Select>
                                                                        </div>
                                                                    </Col>
                                                                </>
                                                            )}
                                                            <Col sm={6} md={4} xxl={3}>
                                                                <div className="mb-3 custom-form-input">
                                                                    <Form.Label>Website</Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        placeholder="Enter your website..."
                                                                        name='website'
                                                                        id='website'
                                                                        value={inputData.website}
                                                                        onChange={e => setInputData({ ...inputData, website: e.target.value })}

                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col sm={6} md={4} xxl={3}>
                                                                <div className="mb-3 custom-form-input">
                                                                    <Form.Label>Alternate Number</Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        placeholder="Enter alternate number..."
                                                                        name='alternateNumber'
                                                                        id='alternateNumber'
                                                                        value={inputData.phone_number2}
                                                                        onChange={e => setInputData({ ...inputData, phone_number2: e.target.value })}

                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col sm={6} md={4} xxl={3}>
                                                                <div className="mb-3 custom-form-input">
                                                                    <Form.Label>Bank Name</Form.Label>
                                                                    <Form.Select
                                                                        
                                                                        name='bankName'
                                                                        id='bankName'
                                                                        value={inputData.bank_branch_name}
                                                                        onChange={e => setInputData({ ...inputData, bank_branch_name: e.target.value })}

                                                                    >
                                                                        <option value="">Select Bank Name</option>
                                                                        {Bank.map(item => (
                                                                            <option key={item.id} value={item.bank_id}>{item.bank_name}</option>
                                                                        ))}
                                                                    </Form.Select>
                                                                </div>
                                                            </Col>
                                                            <Col sm={6} md={4} xxl={3}>
                                                                <div className="mb-3 custom-form-input">
                                                                    <Form.Label>IFSC Code</Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        placeholder="Enter IFSC code..."
                                                                        name='ifscCode'
                                                                        id='ifscCode'
                                                                        value={inputData.bank_branch_ifsc_code}
                                                                        onChange={e => setInputData({ ...inputData, bank_branch_ifsc_code: e.target.value })}
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col sm={6} md={4} xxl={3}>
                                                                <div className="mb-3 custom-form-input">
                                                                    <Form.Label>Account Number</Form.Label>
                                                                    <Form.Control
                                                                        type="number"
                                                                        placeholder="Enter account number..."
                                                                        name='bank_account_number'
                                                                        id='bank_account_number'
                                                                        value={inputData.bank_account_number}
                                                                        onChange={e => setInputData({ ...inputData, bank_account_number: e.target.value })}

                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </Card.Body>
                                                <div className='custom-card-footer text-end'>
                                                    <div className="btn-list text-end">
                                                        <Button variant="primary" type="submit" onClick={handleSubmit}>Add</Button>
                                                        <Button variant="secondary" onClick={handleCancel}>Cancel</Button>
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <Footer />
                </div>
            </CustomerContext1.Provider>
        </>
    )
}

export default function CustomersWithProvider() {
    return (
        <CustomerProvider>
            <Vendor />
        </CustomerProvider>
    );
}