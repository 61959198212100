import React, { useEffect, useState } from 'react';
import Footer from '../../layout/Footer';
import { Container, Row, Col, Card, Form, Table, Modal, Button } from 'react-bootstrap';
import JSZip from 'jszip';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import DataTable from 'datatables.net-dt';
import { getFromAPI, formatISODateWithTimezone, handleDateFormat } from '../../../Utils/utils';

export default function VendorLedgerReport({ tableName = "Leave Report" }) {
    const [productData, setProductData] = useState([]);
    const [productName, setProductName] = useState('');
    const [productId, setProductId] = useState(0);
    const [timeDuration, setTimeDuration] = useState("1");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [adminLeaveData,setAdminApprovalLeaveData] = useState([]);
    const useEmail = localStorage.getItem('email');
    const [leaveStatus, setLeaveStatus] = useState("All");
    const [showDateRangeModal, setShowDateRangeModal] = useState(false); // For modal visibility

    const handleLeaveStatusChange = (e) => {
        setLeaveStatus(e.target.value);
    };
    
    useEffect(() => {
        const filteredData = adminLeaveData.filter((data) => {
            // Filter by Leave Status
            const statusMatch =
                leaveStatus === "All" || data.leave_status === leaveStatus;
    
            // Filter by Time Duration
            const today = handleDateFormat(new Date());
            const yesterday = new Date(Date.now() - 86400000).toISOString().split("T")[0];

            const leaveStartDate = data.leave_start_date.split("T")[0];
            const leaveEndDate = data.leave_end_date.split("T")[0];    
            const timeMatch = (() => {
                switch (timeDuration) {
                    case "1": // All Time
                        return true;
                    case "2": // Yesterday
                        return leaveStartDate <= yesterday && leaveEndDate >= yesterday;
                    case "3": // Today
                        return leaveStartDate <= today && leaveEndDate >= today;
                    case "4": // Monthly
                        const [year, month] = today.split("-");
                        return leaveStartDate.startsWith(`${year}-${month}`) || leaveEndDate.startsWith(`${year}-${month}`);
                    case "5": // Yearly
                        const [currentYear] = today.split("-");
                        return leaveStartDate.startsWith(currentYear);
                    case "6": // Financial Year
                        const startOfFinancialYear = new Date(
                            new Date().getFullYear(),
                            3,
                            1
                        )
                            .toISOString()
                            .split("T")[0];
                        const endOfFinancialYear = new Date(
                            new Date().getFullYear() + 1,
                            2,
                            31
                        )
                            .toISOString()
                            .split("T")[0];
                        return (
                            leaveStartDate >= startOfFinancialYear &&
                            leaveEndDate <= endOfFinancialYear
                        );
                    // case "7": // Specific Date Range
                    //     const start = new Date(startDate).toISOString().split("T")[0];
                    //     const end = new Date(endDate).toISOString().split("T")[0];
                    //     return (
                    //         leaveStartDate >= start &&
                    //         leaveEndDate <= end
                    //     );
                    default:
                        return true;
                }
            })();
    
            return statusMatch && timeMatch;
        });
    
        // Update DataTable with filtered data
        if ($.fn.dataTable.isDataTable('#mainDatatableLeaveList')) {
            $('#mainDatatableLeaveList').DataTable().clear().rows.add(
                filteredData.map((data) => [
                    data.leave_employee_name,
                    data.leave_type,
                    `${data.leave_start_date} to ${data.leave_end_date}`,
                    data.leave_total_days,
                    data.leave_status,
                    data.id
                ])
            ).draw();
        }
    }, [adminLeaveData, leaveStatus, timeDuration, startDate, endDate]);
    
    console.log("useEmail:",useEmail)
    const fetchAdminApprovedLeave = async () => {
        try {
            const response = await getFromAPI(`FtsLeave/?leave_report_to_email=${useEmail}`);
            console.log("Response: ",response)
            if (response) {
                setAdminApprovalLeaveData(response);
            } else {
                setAdminApprovalLeaveData([])
            }
        } catch (error) {
            console.error("Error fetching admin-approved leave:", error);
        }
     };

     useEffect(() => {
        fetchAdminApprovedLeave();
    }, []);

    const handleDateRangeSubmit = () => {
        setShowDateRangeModal(false); // Close the modal on submit
        fetchTransactionData(); // Fetch data for the specific date range
    };

    const fetchData = async () => {
        try {
            const response = await getFromAPI(`FtsItems/?description=${productName}`);
            if (response && response.length > 0) {
                setProductId(response[0].id);
            } else {
                setProductId(0);
                setProductData([]);
            }
        } catch (error) {
            console.error('Error fetching product data:', error);
            setProductId(0);
            setProductData([]);
        }
    };

    useEffect(() => {
        if (productName) {
            fetchData();
        } else {
            setProductData([]);
        }
    }, [productName]);

    const fetchTransactionData = async () => {
        try {
            const response = await getFromAPI(`FtsWarehouseTransaction/?product_id=${productId}`);
            
            // Get today's and yesterday's dates in 'YYYY-MM-DD' format
            const today = handleDateFormat(new Date());
            const yesterday = new Date(Date.now() - 86400000).toISOString().split("T")[0]; // 86400000 ms = 1 day
    
            const filteredData = response.filter(item => {
                const transactionDate = item.created_date_time.split(" ")[0]; // Extract the date part in 'YYYY-MM-DD' format    
                switch (timeDuration) {
                    case "1": // All Time
                        return true;
                    case "2": // Yesterday
                        return transactionDate.startsWith(yesterday);
                    case "3": // Today
                        let todayDate = today;
                        return transactionDate.startsWith(today)

                    case "4": // Monthly
                        const [year, month] = today.split("-");
                        return transactionDate.startsWith(`${year}-${month}`);
                    case "5": // Yearly
                        const [currentYear] = today.split("-");
                        return transactionDate.startsWith(currentYear);
                    case "6": // Financial Year (assuming April-March as financial year)
                        const startOfFinancialYear = new Date(new Date().getFullYear(), 3, 1).toISOString().split("T")[0];
                        const endOfFinancialYear = new Date(new Date().getFullYear() + 1, 2, 31).toISOString().split("T")[0];
                        return transactionDate >= startOfFinancialYear && transactionDate <= endOfFinancialYear;
                    case "7": // Specific Date Range
                        const start = new Date(startDate).toISOString().split("T")[0];
                        const end = new Date(endDate).toISOString().split("T")[0];
                        return transactionDate >= start && transactionDate <= end;
                    default:
                        return true;
                }
            });
           console.log("Filtred Data:",filteredData)
            setProductData(filteredData);
        } catch (error) {
            console.error('Error fetching transaction data:', error);
            setProductData([]);
        }
    };
    
    useEffect(() => {
        if (productId) {
            fetchTransactionData();
        }
    }, [productId, timeDuration, startDate, endDate]);

    const handleTimeDurationChange = (e) => {
        setTimeDuration(e.target.value);
        setStartDate("");
        setEndDate("");
    };


    useEffect(() => {
        return () => {
            if ($.fn.dataTable.isDataTable('#mainDatatableLeaveList')) {
                $('#mainDatatableLeaveList').DataTable().destroy();
            }
        };
    }, [adminLeaveData]);

    useEffect(() => {
    if (!$.fn.dataTable.isDataTable('#mainDatatableLeaveList')) {
        $('#mainDatatableLeaveList').DataTable({
            dom: "Bflrtip",
            buttons: [
                {
                    extend: 'excel',
                    className: 'btn btn-outline-secondary',
                    text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon me-1"><path d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M12.9 14.5L15.8 19H14L12 15.6L10 19H8.2L11.1 14.5L8.2 10H10L12 13.4L14 10H15.8L12.9 14.5Z" style="fill: currentcolor;"></path></svg> Export to Excel',
                    filename: 'myRetails_Leave', // Set the filename for the Excel export
                    title: 'myRetails Leave' // Remove title from the Excel sheet if not needed
                }
            ],
            data: adminLeaveData.map((data) => [
                data.leave_employee_name,
                data.leave_type,
                `${data.leave_start_date} to ${data.leave_end_date}`,
                data.leave_total_days,
                data.leave_status,
                data.id
            ]),
            columns: [
                { title: "Employee Name" },
                { title: "Leave Type" },
                { title: "Leave Period" },
                { title: "Days/Hours Taken" },
                { title: "Leave Status" },
                
            ],
            autoWidth: false,
            language: {
                search: '',
                searchPlaceholder: "Search...",
                paginate: {
                    previous: '«',
                    next: '»'
                },
            }
        });
    }

    // Update the wrapper element class based on the existence of buttons
    const wrapperElement = document.getElementById("mainDatatableLeaveList_wrapper");
    if (wrapperElement) {
        const hasButtons = document.getElementsByClassName("dt-buttons").length > 0;
        wrapperElement.classList.toggle("dtl-right", hasButtons);
    }

}, [adminLeaveData]);


    return (
        <div className="page-wrapper">
            <div className='page-content'>
                <Container>
                    <Row>
                        <Col>
                            <Card>
                                <Card.Body>
                                    <Card.Title>{tableName}</Card.Title>
                                    <Form>
                                        <div className='d-md-flex justify-content-md-between align-items-center'>
                                            <div className="mb-3 custom-form-input custom-form-input-sm min-w me-sm-3 me-0">
                                                <Form.Label>Time Duration</Form.Label>
                                                <Form.Select name='timeDuration' id='timeDuration' value={timeDuration} onChange={handleTimeDurationChange}>
                                                    <option value ="1">All Time</option>
                                                    <option value="2">Yesterday</option>
                                                    <option value="3">Today</option>
                                                    <option value="4">Monthly</option>
                                                    <option value="5">Yearly</option>
                                                    <option value="6">Financial Year</option>
                                                    {/* <option value="7">Specific Date Range</option> */}
                                                </Form.Select>
                                            </div>
                                            
                                            <div className="mb-3 custom-form-input custom-form-input-sm min-w">
                                                <Form.Label>Leave Status</Form.Label>
                                                <Form.Select
                                                    name="leaveStatus"
                                                    id="leaveStatus"
                                                    value={leaveStatus}
                                                    onChange={handleLeaveStatusChange}
                                                >
                                                    <option value="All">All</option>
                                                    <option value="Approved">Approved</option>
                                                    <option value="Pending">Pending</option>
                                                    <option value="Rejected">Rejected</option>
                                                </Form.Select>
                                            </div>
                                        </div>
                                    </Form>

                                    {/* Date Range Modal */}
                                    <Modal show={showDateRangeModal} onHide={() => setShowDateRangeModal(false)}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Select Date Range</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <Form.Group controlId="startDate">
                                                <Form.Label>Start Date</Form.Label>
                                                <Form.Control
                                                    type="date"
                                                    value={startDate}
                                                    onChange={(e) => setStartDate(e.target.value)}
                                                />
                                            </Form.Group>
                                            <Form.Group controlId="endDate" className="mt-3">
                                                <Form.Label>End Date</Form.Label>
                                                <Form.Control
                                                    type="date"
                                                    value={endDate}
                                                    onChange={(e) => setEndDate(e.target.value)}
                                                />
                                            </Form.Group>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={() => setShowDateRangeModal(false)}>
                                                Close
                                            </Button>
                                            <Button variant="primary" onClick={handleDateRangeSubmit}>
                                                Apply Date Range
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                    <Card className='border shadow-none mb-0'>
                                        <Card.Body>
                                            <Table responsive bordered className='table-nowrap' id='mainDatatableLeaveList'>
                                                <thead className='table-light'>
                                                    <tr>
                                                        <th>Employee Name</th>
                                                        <th>Leave Type</th>
                                                        <th>Leave Period</th>
                                                        <th>Days/Hours Taken</th>
                                                        <th>Leave Status</th>
                                                    </tr>
                                                </thead>
                                            </Table>
                                        </Card.Body>
                                    </Card>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Footer />
        </div>
    );
}
