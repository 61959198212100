import React, { useEffect, useState } from 'react'; 
import FormModal from '../../Utils/FormModal';
import { useNavigate, useParams } from 'react-router-dom';
import { getFromAPI , postToAPI , putToAPI , deleteFromAPI, sendNotification } from "../../Utils/utils";
import { toast } from "react-toastify";
import { Navigate, useLocation } from "react-router-dom";

export default function LeaveApproval({leaveId, leaveApprovalModalShow, handleFormModalClose}) {
    const [leaveidData, setleaveidData] = useState([]);
    const navigate = useNavigate();
   // const [token, set_Token] = useState([]);
    const [showButtons, setShowButtons] = useState(true);
    const useEmail = localStorage.getItem('email');
    const [previous_token, setPrevious_token] = useState([]);

    const { id,token } = useParams();
    if (id) {
        leaveApprovalModalShow = true;
    }

    // Get leave data from leave id
    const initialLockerData = {
        leaveEmployeeName: '',
        leaveEmployeeId: '',
        leaveType: '',
        leaveDes: '',
        leave_start_date: '',
        leave_end_date: '',
        leave_part_selection: '',
        leave_rejection_reason: '',
        leave_description: '',
        leave_total_days: '',
    };

    // input value
    const [data, setData] = useState(initialLockerData);

    // Add/Edit modal
    const location = useLocation();
    const UrlsParams = new URLSearchParams(location.search);
    const [reportingToId, setReportingToId] = useState(null); // State to store reportingToId
    const [reportToEmail, setReportToEmail] = useState("");
    const [leaveStatus, setLeaveStatus] = useState(''); // Track leave status

    useEffect(() => {
        // Check if both tokens exist and are the same
        if (token && previous_token && token === previous_token) {
            setShowButtons(true);
        } else {
            setShowButtons(false);
        }
    }, [token, previous_token]);

    useEffect(() => {
        const fetchStaff = async () => {
            try {
                const response = await getFromAPI(`leave_data/?email=${useEmail}`);
                
                // Check if response and necessary fields are available
                if (response?.staff_admin_data?.staffid) {
                    setReportingToId(response.staff_admin_data.staffid); // Set the reporting ID
                    setReportToEmail(response.staff_admin_data.email); // Set the reporting
                } else {
                    console.warn("Response or staff_admin_data.staffid is missing", response);
                }
            } catch (error) {
                console.error("Error fetching staff data:", error);
            }
        };

        fetchStaff();
    }, [useEmail]);

    const handleInputChange = (index, field, value) => {
        setData({ ...data, [field]: value });
    };
    const handleSubmit = (e) => {
        e.preventDefault();
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const endpoint = id ? `FtsLeave/?id=${id}` : `FtsLeave/?id=${leaveId}`;
                const response = await getFromAPI(endpoint);
                setLeaveStatus(response[0].leave_status)

                if (response) {
                    setleaveidData(response[0]); // Set the first object from the response array
                    setData({
                        leaveEmployeeName: response[0].leave_employee_name,
                        leaveEmployeeId: id,
                        leaveType: response[0].leave_type,
                        leaveDes: response[0].leave_description,
                        leave_start_date: response[0].leave_start_date,
                        leave_end_date: response[0].leave_end_date,
                        leave_part_selection: response[0].leave_part_selection,
                        leave_rejection_reason: response[0].leave_rejection_reason,
                        leave_total_days: response[0].leave_total_days,
                        leave_total_hours: response[0].leave_total_hours,
                        leave_token: response[0].leave_token,
                    });
                    setPrevious_token(response[0].leave_token);
                } else {
                    toast.error("No leave data found in the response:", response);
                }
            
            } catch (error) {
                console.error("Error fetching leave data:", error);
            }
        };

        fetchData();
    }, [leaveId]);

    const leaveTableHeader = [
        'Start Date', 'Leave Type', 'Total Hours/Days',
    ];
    
    const calculateLeaveRowData = () => {
        const leaveRowData = [];
        const startDate = new Date(data.leave_start_date);
        const endDate = new Date(data.leave_end_date);
        let leavePartSelection = data.leave_part_selection;
        
        // Parse leavePartSelection if it's a valid string
        if (typeof leavePartSelection === 'string' && leavePartSelection.trim()) {
            try {
                leavePartSelection = JSON.parse(leavePartSelection.replace(/'/g, '"')); // Replace single quotes with double quotes
            } catch (e) {
                console.error("Error parsing leavePartSelection:", e);
                leavePartSelection = [];
            }
        } else {
            console.warn("Invalid or empty leavePartSelection:", leavePartSelection);
            leavePartSelection = [];
        }
    
        // Ensure leavePartSelection is treated as an array
        const leaveTypes = Array.isArray(leavePartSelection) ? leavePartSelection : [];    
        if (startDate && endDate && startDate <= endDate) {
            let currentDate = new Date(startDate);
    
            while (currentDate <= endDate) {
                let currentLeaveType = leaveTypes.shift(); // Process one leave type per date
                if (!currentLeaveType) {
                    currentLeaveType = "Full Day"; // Default to "Full Day" if no leave type is left
                }
    
                let leaveType = '';
                let totalHours = '';
    
                if (currentLeaveType.trim() === "Full Day") {
                    leaveType = 'Full Day';
                    totalHours = '8 Hours';
                } else if (currentLeaveType.trim() === "First Half") {
                    leaveType = 'First Half';
                    totalHours = '4 Hours';
                } else if (currentLeaveType.trim() === "Second Half") {
                    leaveType = 'Second Half';
                    totalHours = '4 Hours';
                }
    
                if (leaveType) {
                    // Add row data for the current leave type
                    leaveRowData.push({
                        startDate: currentDate.toISOString().split('T')[0],
                        leaveType: leaveType,
                        totalHours: totalHours,
                    });
                }
    
                // Move to the next day
                currentDate.setDate(currentDate.getDate() + 1);
            }
        } else {
            console.error("Invalid date range:", startDate, endDate);
        }
    
        return leaveRowData;
    };
    
    const handleApproval = async (status) => {
        // Update the leave status (Approved/Rejected)
        try {
            const updatedData = { ...data, leave_status: status };
            await putToAPI(`FtsLeave/${id}/`, updatedData); // Assuming `putToAPI` is the function to update the leave status
            setLeaveStatus(status); // Update the leave status
            toast.success(`Leave ${status} successfully!`);
            handelCloseLeaveDataModal(); // Close the modal after updating
            setShowButtons(false); // Disable both buttons after approval/rejection

        } catch (error) {
            console.error("Error updating leave status:", error);
            toast.error("Error updating leave status.");
        }
    };

    // Call this method to get the leave row data
    const leaveTableRowData = calculateLeaveRowData();
        
    // This method is used to close the leave data modal
    const handelCloseLeaveDataModal = () => {
        navigate('/leave');
    };
    
        
    const rows = [
        {
            columns: [
                { size: { lg: 12 }, label: 'Employee Name', type: 'text', name: 'leaveEmployeeName', id: 'leaveEmployeeName', placeholder: 'Enter title...', value: data.leaveEmployeeName, errormsg: '', disabled: true },
                { size: { lg: 6 }, label: 'Employee ID', type: 'text', name: 'leaveEmployeeID', id: 'leaveEmployeeID', placeholder: 'Enter title...', value: id, errormsg: '', disabled: true },
                { size: { lg: 6 }, label: 'Leave Type', type: 'text', name: 'leaveType', id: 'leaveType', placeholder: 'Enter title...', value: data.leaveType, errormsg: '', disabled: true },
                { type: 'table', tableSize: 'md', thead: leaveTableHeader, tbodydata: leaveTableRowData, },
                { size: { md: 12 }, label: 'Leave Description', type: 'textarea', name: 'leaveDes', formrow: '3', id: 'leaveDes', placeholder: 'Enter message...', value: data.leaveDes, errormsg: '', disabled: true },
            ]
        }
    ];

      // Conditionally render the buttons only if the leave status is 'Pending'
      const Buttons = leaveStatus === 'Pending' ? [
        { 
            variant: "primary", 
            text: "Approved", 
            onClick: () => handleApproval("Approved"),
        },
        { 
            variant: "secondary", 
            text: "Rejected", 
            onClick: () => handleApproval("Rejected"),
        }
    ] : [];

    return (
        <>
            {/* From Modal */}
            <FormModal title="Leave Details" modalSize="lg" formModalShow={leaveApprovalModalShow} Buttons={Buttons} handleFormModalClose={id ? handelCloseLeaveDataModal : handleFormModalClose} rows={rows} handleInputChange={handleInputChange} handleSubmit={handleSubmit} />
        </>
    );
}
