import React,{useMemo,useState,useEffect} from 'react';
import { Row, Col, Card, Form, Button } from 'react-bootstrap';
import *as XLSX from 'xlsx';
import { getFromAPI,postToAPI,formatISODateWithTimezone } from '../../Utils/utils';
import { toast } from 'react-toastify';
import { CustomerProvider, useProduct } from './getProductData';


export default function ImportProduct() {
   const { customerData, fetchproductData1 } = useProduct();
   const[data,setData] = useState([
    {
        description: '',
        type: '',
        rate: 0.0,
        group_id: 0,
        product_code: '',
        product_category_id: 0,
        vendor_id: 0,
        weight: 1,
        unit_id: 0,
        product_purchase_price: 0.0,
        product_minimum_sell_price: 0.0,
        maximum_retail_price: 0.0,
        product_mu_percentage: 0,
        long_description: "",
        rating_id: 0,
        hsn_code: '',
        brand_id: 0,
        image: '',
        barcode_type: '',
        barcode_number: '',
        license_number: '',
        isservice: 0,
        minimum_consumption: 0.0,
        ismeasurable: 0,
        has_sub_product: 0,
        isproductimported: 0,
        iscoldstorage_require: 0,
        isonline: 0,
        created_branch_id: 1,
        created_user_id: 1,
        last_modified_user_id: 1,
        last_modified_date_time: formatISODateWithTimezone(new Date()),
        isdeleted: 0,
        reserve_varchar1: "",
        reserve_varchar2: "",
        reserve_float1: 0.0,
        reserve_float2: 0.0,
        reserve_int1: 0,
        reserve_int2: 0,
        reserve_double1: 0.0,
        branches: [],
        product_sell_price: 0,
        discount_value: 0,
        discount_type: 0,
        tax_value: 0,
        tax_type: 0,
        opening_consumable_qty: 0,
        opening_retail_qty: 0,
        alert_consumable_quantity: 0,
        alert_retail_quantity: 0,

    },
   ]);
   const[importedData,setImportedData] = useState([]);
   const [isDataValid, setIsDataValid] = useState(true); // New state for button disabled status.
   const [fileInputKey, setFileInputKey] = useState(0); // Track the key for file input

   const convertDecimalToTime = (decimal) => {
    const hours = Math.floor(decimal * 24);
    const minutes = Math.floor((decimal * 24 - hours) * 60);
    const seconds = Math.round((((decimal * 24 - hours) * 60) - minutes) * 60);
  
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };
  // Function to convert Excel date serial number to YYYY-MM-DD format
  const convertDateToYMD = (serial) => {
    const startDate = new Date(1899, 11, 30); // Excel date serial number start date
    const date = new Date(startDate.getTime() + serial * 24 * 60 * 60 * 1000);
    
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const day = date.getDate().toString().padStart(2, '0');
  
    return `${year}-${month}-${day}`;
  };
  

   const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file && /\.(xlsx|xls|csv)$/.test(file.name)) {
        const reader = new FileReader();

        reader.onload = (event) => {
            const arrayBuffer = event.target.result;

            try {
                const workbook = XLSX.read(arrayBuffer, { type: 'array' });
                const firstSheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[firstSheetName];
                const importedData = XLSX.utils.sheet_to_json(worksheet);
                
                const headerMapping = {
                    'Product Code': 'product_code',
                    'Description': 'description',
                    'Product Type': 'type',
                    'Product Category': 'product_category_id',
                    'Vendor Id': 'vendor_id',
                    'Weight': 'weight',
                    'Unit': 'unit_id',
                    'Cost Price': 'product_purchase_price',
                    'Selling Price': 'product_minimum_sell_price',
                    'MRP': 'maximum_retail_price',
                    'MU Percentage': 'product_mu_percentage',
                    'Long Description': 'long_description',
                    'Rating Code': 'rating_id',
                    'HSN Code': 'hsn_code',
                    'Brand': 'brand_id',
                    'Image': 'image',
                    'Barcode Type': 'barcode_type',
                    'Barcode Number': 'barcode_number',
                    'License Number': 'license_number',
                    'Is Service': 'isservice',
                    'Minimum Consumption': 'minimum_consumption',
                    'Measurable': 'ismeasurable',
                    'Sub Product': 'has_sub_product',
                    'Product Imported': 'isproductimported',
                    'Cold Storage Required': 'iscoldstorage_require',
                    'Online': 'isonline',
                    'Created Branch': 'created_branch_id',
                    'Created User': 'created_user_id',
                    'Last Modified User': 'last_modified_user_id',
                    'Last Modified Date/Time': 'last_modified_date_time',
                    'Is Deleted': 'isdeleted',
                    'Reserve varchar1': 'reserve_varchar1',
                    'Reserve varchar2': 'reserve_varchar2',
                    'Reserve float1': 'reserve_float1',
                    'Reserve float2': 'reserve_float2',
                    'Reserve int1': 'reserve_int1',
                    'Reserve int2': 'reserve_int2',
                    'Reserve double1': 'reserve_double1',
                    'Product Sell Price': 'product_sell_price',
                    'Discount Value': 'discount_value',
                    'Discount Type': 'discount_type',
                    'Tax Value': 'tax_value',
                    'Tax Type': 'tax_type',
                    'Opening Consumable Qty': 'opening_consumable_qty',
                    'Opening Retail Qty': 'opening_retail_qty',
                    'Alert Consumable Quantity': 'alert_consumable_quantity',
                    'Alert Retail Quantity': 'alert_retail_quantity',
                    'Branches': 'branches'
                };
                

                // Map and trim data
                const trimmedImportedData = importedData.map(entry => {
                    const trimmedEntry = {};
                    Object.keys(entry).forEach(key => {
                        if (headerMapping[key]) {
                            trimmedEntry[headerMapping[key]] = entry[key];
                        }
                    });
                    return trimmedEntry;
                });

                setImportedData(trimmedImportedData);
                setIsDataValid(true);

            } catch (error) {
                console.error('Error reading file:', error);
                toast.error('Error reading Excel file');
            }
        };

        reader.readAsArrayBuffer(file); // Read the file as array buffer
    } else {
        toast.error('Please upload a valid Excel or CSV file.');
    }
};

    // Function to generate and download sample CSV
    const downloadSampleCSV = () => {
        const sampleData = [
            {   
                'Description': 'description',
                'Product Type': 1,
                'Product Category': 1,
                'Vendor Id': 1,
                'Weight': 0,
                'Unit': 1,
                'Cost Price': 80,
                'Selling Price': 100,
                'MRP': 120,
                'MU Percentage':10,
                'Long Description': 'this is long description',
                'Rating Code': 0,
                'HSN Code': '001',
                'Brand': 0,
                'Image': '',
                'Barcode Type': 'NA',
                'Barcode Number': 'NA',
                'License Number': 'NA',
                'Is Service': 0,
                'Minimum Consumption': 0,
                'Measurable': 0,
                'Sub Product': '0',
                'Product Imported': 1,
                'Cold Storage Required': 0,
                'Online': 0,
                'Created Branch': 0,
                'Created User': 0,
                'Last Modified User': 0,
                'Last Modified Date/Time': "2024-11-13T12:10:35.424089",
                'Is Deleted': 0,
                'Reserve varchar1': '',
                'Reserve varchar2': '',
                'Reserve float1': '',
                'Reserve float2': '',
                'Reserve int1': '',
                'Reserve int2': '',
                'Reserve double1': '',
                'Product Sell Price': 100,
                'Discount Value': 10,
                'Discount Type': 1,
                'Tax Value': 5,
                'Tax Type': 1,
                'Opening Consumable Qty': '100',
                'Opening Retail Qty': '100',
                'Alert Consumable Quantity': '10',
                'Alert Retail Quantity': '10',
                'Branches': 'myBusiness'
            }
        ];

        const worksheet = XLSX.utils.json_to_sheet(sampleData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sample Data");
        
        // Generate buffer
        XLSX.writeFile(workbook, 'sample_product_data.csv');
    };

    const handleAcceptData = async () => {
        // Check if there is any data to import
        if (importedData.length === 0) {
            toast.error('Please select a valid CSV file before importing.');
            return; // Exit the function early if no data is present
        }
    
        try {
            // Check for duplicate descriptions in importedData
            for (const product of importedData) {
                const existingProductResponse = await getFromAPI(`FtsItems/?description=${encodeURIComponent(product.description)}&isdeleted=0`);
                
                if (existingProductResponse.length > 0) {
                    toast.error(`A product with the Product Name "${product.description}" already exists. Please enter a unique description.`);
                    return; // Exit if a duplicate product is found
                }
            }
    
            // Proceed with import if no duplicates were found
            const response = await postToAPI('FtsItems/', { data: importedData }, {
                headers: { 'Content-Type': 'application/json' },
            });
    
            if (response.status) {
                setData([...data, ...importedData]);
                setImportedData([]);
                toast.success('Data verified and saved successfully');
                fetchproductData1();
                setFileInputKey(prevKey => prevKey + 1); // Increment key to reset input
            } else {
                toast.error('Failed to import products');
                setFileInputKey(prevKey => prevKey + 1); // Increment key to reset input
            }
        } catch (error) {
            toast.error('Failed to import products');
            setFileInputKey(prevKey => prevKey + 1); // Increment key to reset input
        }
    };
    
   const handleDeclineData = () => {
    setImportedData([]);
    setFileInputKey(prevKey => prevKey + 1); // Increment key to reset input
 };

    return (
        <>
            <Card className='right-content-details'>
                <Card.Body className='pb-0'>
                    <Card.Title>Import Products</Card.Title>
                    <Form.Group controlId="formFileImportProducts" className="mb-3">
                        <Form.Label>Select File</Form.Label>
                        <Form.Control 
                           type="file" 
                           name='importProducts' 
                           onChange={handleFileChange}
                           key={fileInputKey} // Use key to reset the file input
                           />
                    </Form.Group>
                    <Row className='justify-content-center'>
                        <Col xl={8} className="text-center">
                            <h5 className='mb-3'>Download a sample csv file that you can populate with your own data</h5>
                            <p className='text-muted lh-lg fs-14'>Use the button below to upload a csv file of your product list. Once you have selected a file to upload, your product list will be imported.
                                Duplicate products(based on product name) are not created by this process, so you can correct errors and upload the same file
                                more than once, if necessary.</p>

                        </Col>
                    </Row>
                </Card.Body>
                <div className='custom-card-footer'>
                    <div className="btn-list text-end">
                        <Button variant="primary"  onClick={downloadSampleCSV}>Download Sample</Button>{' '}
                        <Button variant="success" onClick={handleAcceptData}>Import</Button>{' '}
                        <Button variant="secondary" onClick = {handleDeclineData}>Cancel</Button>{' '}
                    </div>
                </div>
            </Card>
        </>
    )
}
