import React, { useContext, useState, useEffect } from 'react';
import { Card, Modal, Form, Row, Col, Button, Table } from 'react-bootstrap';

import ExpenseCategoryData from "./expense-category-data.json";

// Icon
import Icon from '@mdi/react';
import { mdiSquareEditOutline, mdiTrashCanOutline } from '@mdi/js';
import { toast } from 'react-toastify';
import { ExpenseCategoryProvider, useExpenseCategory } from './getExpenseCategory';
import { ExpenseCategoryContext1 } from './expenseCategoryContext';
import { getFromAPI, postToAPI, putToAPI, deleteFromAPI, formatISODateWithTimezone, handleDateFormat, showAlert } from '../../../Utils/utils';
import FormModal from '../../../Utils/FormModal';

export default function ViewExpenseCategory({setExpenseCategoryId}) {
    const { expenseCategoryData, fetchExpenseCategoryData } = useExpenseCategory();
    const expenseCategoryId = useContext(ExpenseCategoryContext1);
    const [tableData, setTableData] = useState([]); // Initialize state with an empty array

    const initialInputData = {
        category_name: '',
        description: '',
        isexpense: 1,
        created_branch_id: 0,
        created_user_id: 0,
        last_modfied_user_id: 0,
        created_date_time: formatISODateWithTimezone(new Date()),
        last_modified_date_time: formatISODateWithTimezone(new Date()),
        isdeleted: 0
    }

    const [inputData, setInputData] = useState({ ...initialInputData });

    useEffect(() => {
        const fetchExpenseCategoryDetails = async () => {
            try {
                const response = await getFromAPI(`FtsExpenseCategory/?id=${expenseCategoryId}`);
                if (response && response.length > 0) {
                    const fetchedData = response[0];
                    setInputData({
                        category_name: fetchedData.category_name,
                        description: fetchedData.description
                    });
                }
            } catch (error) {
                return null;
            }
        };

        if (expenseCategoryId) {
            fetchExpenseCategoryDetails();
        }
    }, [expenseCategoryId]);

    const fetchExpenseCategoryTable = async () => {
        try {
            const response = await getFromAPI(`FtsExpenseCategory/?id=${expenseCategoryId}`);

            // Map API response to match the table data structure
            const mappedData = [
                { tableheading1: "Name :", tablesubtext1: response[0].category_name },
                { tableheading1: "Description :", tablesubtext1: response[0].description },
                { tableheading1: "Created User Id :", tablesubtext1: response[0].created_user_id },
                { tableheading1: "Created Date Time :", tablesubtext1: response[0].created_date_time.replace('T', ' ').replace('Z', '')  }
            ]

            setTableData(mappedData); // Update state with the mapped data
        } catch (error) {
            return null;
        }
    };

    useEffect(() => {
        fetchExpenseCategoryData();
        fetchExpenseCategoryTable();
    }, [expenseCategoryId]);

    const handleDeleteExpenseCategory = async (itemId) => {
        try {
            const response = await deleteFromAPI(`FtsExpenseCategory/${itemId}/`);
            if (response) {
                toast.success('Expense Category deleted successfully', 'success');
                fetchExpenseCategoryData(); // Update the customer list
                if(expenseCategoryData.length > 0) {
                    setExpenseCategoryId(expenseCategoryData[0].id); // Set the first customer's id as the default for editing
                }
                else
                {
                    setExpenseCategoryId(''); // Set the default id to null if no customers are available
                }

            }
        } catch (error) {
            toast.error('Expense Category Not Deleted', 'error');
            return null;
        }

    };

    // Handle Delete Expense Category Entry
    const handleDelete = async (Id) => {
        try {
            showAlert('Are you sure you want to delete this Expense Category?', 'confirm', (result) => {
                if (result) {
                    // User confirmed the deletion
                    handleDeleteExpenseCategory(Id);
                } else {
                    // User canceled the deletion
                    // You can handle this case if needed
                }
            });
        } catch (error) {
            toast.error('Error deleting Customer', 'error');
            return null;
        }
    };

    const handleSaveChanges = async () => {

        try {
            await putToAPI(`FtsExpenseCategory/${expenseCategoryId}/`, inputData);
            toast.success('Expense Category details updated successfully', 'success');
            handleFormModalClose();
            fetchExpenseCategoryData();
            fetchExpenseCategoryTable();
        } catch (error) {
            toast.error('Failed to update Expense Category  details', 'error');
            return null;
        }
    };

    // Edit modal
    const [formModalShow, setShowFormModal] = useState(false);
    const handleExpnsCategoryEdit = () => setShowFormModal(true);
    const handleFormModalClose = () => setShowFormModal(false);

    const handleInputChange = (index, field, value) => {
        setInputData({ ...inputData, [field]: value });
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        setShowFormModal(false);
    };

    const rows = [
        {
            columns: [
                { size: { lg: 12 }, label: 'Expense Category Name', type: 'text', name: 'category_name', id: 'category_name', placeholder: 'Enter expense category name...', value: inputData.category_name, onChange: e => setInputData({ ...inputData, category_name: e.target.value }), errormsg: '', },
                { size: { lg: 12 }, label: 'Expense Description', type: 'textarea', rows: 4, name: 'description', id: 'description', placeholder: 'Type expense description...', value: inputData.description, onChange: e => setInputData({ ...inputData, description: e.target.value }), errormsg: '', },
            ]
        }
    ];
    const Buttons = [
        { variant: "primary", text: "Update", onClick: handleSaveChanges },
        { variant: "secondary", text: "Cancel", onClick: handleFormModalClose },
    ]

    return (
        <>
            <Card className='right-content-details'>
                <Card.Body>
                    <div className='d-flex justify-content-between align-items-center mb-3'>
                        <Card.Title className='mb-0'>View Expense Category</Card.Title>
                        <div className='d-flex'>
                            <div className="btn-list">
                                <Button variant="soft-info p-0" onClick={handleExpnsCategoryEdit} disabled={!expenseCategoryId}>
                                    <div className='avatar avatar-sm'>
                                        <Icon path={mdiSquareEditOutline} className='avatar-icon' />
                                    </div>
                                </Button>{' '}
                                <Button variant="soft-danger p-0" onClick={() => handleDelete(expenseCategoryId)} disabled={!expenseCategoryId}>
                                    <div className='avatar avatar-sm'>
                                        <Icon path={mdiTrashCanOutline} className='avatar-icon' />
                                    </div>
                                </Button>{' '}
                            </div>
                        </div>
                    </div>

                    <Table responsive className='table-lg border table-nowrap mb-0'>
                        <tbody>
                            {tableData.map((data, i) =>
                                <tr key={i}>
                                    <th>{data.tableheading1}</th>
                                    <td>{data.tablesubtext1}</td>

                                </tr>
                            )}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>

            {/* From Modal */}
            <FormModal title="Edit Expense Category" formModalShow={formModalShow} Buttons={Buttons} handleFormModalClose={handleFormModalClose} rows={rows} handleInputChange={handleInputChange} handleSubmit={handleSubmit} />
        </>
    )
}
