import React, { useEffect, useState } from 'react';
import Footer from '../../layout/Footer';
import { Container, Row, Col, Card, Form, Table } from 'react-bootstrap';
import JSZip from 'jszip';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import DataTable from 'datatables.net-dt';
import { getFromAPI, formatISODateWithTimezone, handleDateFormat } from '../../../Utils/utils';

export default function VendorLedgerReport({ tableName = "Vendor Ledger Report" }) {
    const [productData, setProductData] = useState([]);
    const [productName, setProductName] = useState('');
    const [productId, setProductId] = useState(0);
    const [timeDuration, setTimeDuration] = useState("1");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    const fetchData = async () => {
        try {
            const response = await getFromAPI(`FtsItems/?description=${productName}`);
            if (response && response.length > 0) {
                setProductId(response[0].id);
            } else {
                setProductId(0);
                setProductData([]);
            }
        } catch (error) {
            console.error('Error fetching product data:', error);
            setProductId(0);
            setProductData([]);
        }
    };

    useEffect(() => {
        if (productName) {
            fetchData();
        } else {
            setProductData([]);
        }
    }, [productName]);

    const fetchTransactionData = async () => {
        try {
            const response = await getFromAPI(`FtsWarehouseTransaction/?product_id=${productId}`);
            
            // Get today's and yesterday's dates in 'YYYY-MM-DD' format
            const today = handleDateFormat(new Date());
            console.log("Today: ",today)
            const yesterday = new Date(Date.now() - 86400000).toISOString().split("T")[0]; // 86400000 ms = 1 day
            console.log("Yesterday: ",yesterday)
    
            const filteredData = response.filter(item => {
                const transactionDate = item.created_date_time.split(" ")[0]; // Extract the date part in 'YYYY-MM-DD' format    
                switch (timeDuration) {
                    case "1": // All Time
                        return true;
                    case "2": // Yesterday
                        return transactionDate.startsWith(yesterday);
                    case "3": // Today
                        let todayDate = today;
                        return transactionDate.startsWith(today)

                    case "4": // Monthly
                        const [year, month] = today.split("-");
                        return transactionDate.startsWith(`${year}-${month}`);
                    case "5": // Yearly
                        const [currentYear] = today.split("-");
                        return transactionDate.startsWith(currentYear);
                    case "6": // Financial Year (assuming April-March as financial year)
                        const startOfFinancialYear = new Date(new Date().getFullYear(), 3, 1).toISOString().split("T")[0];
                        console.log("Financial Year:",startOfFinancialYear)
                        const endOfFinancialYear = new Date(new Date().getFullYear() + 1, 2, 31).toISOString().split("T")[0];
                        return transactionDate >= startOfFinancialYear && transactionDate <= endOfFinancialYear;
                    case "7": // Specific Date Range
                        const start = new Date(startDate).toISOString().split("T")[0];
                        const end = new Date(endDate).toISOString().split("T")[0];
                        return transactionDate >= start && transactionDate <= end;
                    default:
                        return true;
                }
            });
           console.log("Filtred Data:",filteredData)
            setProductData(filteredData);
        } catch (error) {
            console.error('Error fetching transaction data:', error);
            setProductData([]);
        }
    };
    
    useEffect(() => {
        if (productId) {
            fetchTransactionData();
        }
    }, [productId, timeDuration, startDate, endDate]);

    useEffect(() => {
        initializeDataTable();
    }, [productData]);

    const handleProductNameChange = (e) => {
        setProductName(e.target.value);
    };

    const handleTimeDurationChange = (e) => {
        setTimeDuration(e.target.value);
        setStartDate("");
        setEndDate("");
    };

    // Define a mapping for transaction type codes to their names
    const TransactionTypeNames = {
        1: "Opening Qty",
        2: "Purchase Invoice",
        3: "Internal Branch Transfer",
        4: "Transfer Order",
        5: "Invoice Retail",
        6: "Invoice Consumable Service",
        7: "Return Stock",
        8: "Check Out",
        9: "Adjustment",
        10: "Challan",
        11: "Purchase Return",
        12: "Sales Return",
        13: "Convert Stock",
    };
    const initializeDataTable = () => {
        const tableId = '#mainDatatableProductLReport';

        if ($.fn.DataTable.isDataTable(tableId)) {
            $(tableId).DataTable().clear().destroy();
        }

        let currentBalance = 0;
        const tableData = productData.map((data, index) => {
            const quantity = data.quantity;
            let debit = 0;
            let credit = 0;

            if (quantity < 0) {
                debit = Math.abs(quantity);
            } else if (quantity > 0) {
                credit = quantity;
            }

            currentBalance += credit - debit;
            const transactionTypeWithDescription = `${TransactionTypeNames[data.transaction_type] || "Unknown"}${data.description ? ` (${data.description})` : ''}`;

            return [
                index + 1,
                handleDateFormat(data.created_date_time),
                transactionTypeWithDescription,
                data.id || "N/A",
                debit,
                credit,
                currentBalance
            ];
        });

        $(tableId).DataTable({
            data: tableData,
            columns: [
                { title: "SR No." },
                { title: "Date" },
                { title: "Particular" },
                { title: "Reference" },
                { title: "Debit" },
                { title: "Credit" },
                { title: "Closing Balance" }
            ],
            dom: "Bflrtip",
            buttons: [
                { extend: 'pdf', className: 'btn btn-outline-secondary', text: 'Export to Pdf' },
                { extend: 'print', className: 'btn btn-outline-secondary', text: 'Export to Print' },
                { extend: 'excel', className: 'btn btn-outline-secondary', text: 'Export to Excel' }
            ],
            language: {
                search: '',
                searchPlaceholder: "Search...",
                paginate: {
                    previous: '«',
                    next: '»'
                },
            }
        });
    };

    return (
        <div className="page-wrapper">
            <div className='page-content'>
                <Container>
                    <Row>
                        <Col>
                            <Card>
                                <Card.Body>
                                    <Card.Title>{tableName}</Card.Title>
                                    <Form>
                                        <div className='d-md-flex justify-content-md-between align-items-center'>
                                            <div className="mb-3 custom-form-input custom-form-input-sm min-w me-sm-3 me-0">
                                                <Form.Label>Time Duration</Form.Label>
                                                <Form.Select name='timeDuration' id='timeDuration' value={timeDuration} onChange={handleTimeDurationChange}>
                                                    <option value ="1">All Time</option>
                                                    <option value="2">Yesterday</option>
                                                    <option value="3">Today</option>
                                                    <option value="4">Monthly</option>
                                                    <option value="5">Yearly</option>
                                                    <option value="6">Financial Year</option>
                                                    <option value="7">Specific Date Range</option>
                                                </Form.Select>
                                            </div>
                                            {timeDuration === "7" && (
                                                <>
                                                    <div className="mb-3 custom-form-input custom-form-input-sm min-w">
                                                        <Form.Label>Start Date</Form.Label>
                                                        <Form.Control
                                                            type="date"
                                                            name='startDate'
                                                            value={startDate}
                                                            onChange={(e) => setStartDate(e.target.value)}
                                                        />
                                                    </div>
                                                    <div className="mb-3 custom-form-input custom-form-input-sm min-w">
                                                        <Form.Label>End Date</Form.Label>
                                                        <Form.Control
                                                            type="date"
                                                            name='endDate'
                                                            value={endDate}
                                                            onChange={(e) => setEndDate(e.target.value)}
                                                        />
                                                    </div>
                                                </>
                                            )}
                                            <div className="mb-3 custom-form-input custom-form-input-sm min-w">
                                                <Form.Label>Product Name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name='productName'
                                                    id='productName'
                                                    placeholder="Enter product name..."
                                                    value={productName}
                                                    onChange={handleProductNameChange}
                                                />
                                            </div>
                                        </div>
                                    </Form>
                                    <Card className='border shadow-none mb-0'>
                                        <Card.Body>
                                            <Table responsive bordered className='table-nowrap' id='mainDatatableProductLReport'>
                                                <thead className='table-light'>
                                                    <tr>
                                                        <th>Sr No.</th>
                                                        <th>Date</th>
                                                        <th>Particular</th>
                                                        <th>Reference</th>
                                                        <th>Debits</th>
                                                        <th>Credits</th>
                                                        <th>Closing Balance</th>
                                                    </tr>
                                                </thead>
                                            </Table>
                                        </Card.Body>
                                    </Card>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Footer />
        </div>
    );
}
