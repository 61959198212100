import React, { useState } from 'react';
import { Card, Col, Container, Form, Row, Button } from 'react-bootstrap';
import { postToAPI } from '../../Utils/utils';
import Icon from '@mdi/react';
import { mdiEyeOffOutline, mdiAccountPlusOutline, mdiGoogle, mdiApple,mdiEyeOutline } from '@mdi/js';
import { toast } from 'react-toastify';
import Logo from '../../images/logo.png';
import { Link, useNavigate } from 'react-router-dom';

export default function Login() {
    const [inputData, setInputData] = useState({
        email: "",
        password: ""
    });
    const navigate = useNavigate(); // useNavigate for programmatic navigation
    const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility

    // Handle form submission
    const handleLogin = async (e) => {

    // Commit the belwo code for direct login
         e.preventDefault();

         // Check if both fields are filled
            if (!inputData.email) 
                 { toast.error("Please enter a valid email");
                   return;
                 }
             if (!inputData.password)
             {
                 toast.error("Please enter a valid password");
                  return;
                 }                
          

         try {
             // Make API call for login
             const response = await postToAPI("login/", {
                 email: inputData.email,
                 password: inputData.password
             });

         // Check if response is successful (status 200)
             if (response.status) {
                 navigate('/dashboard');
                  // Extract the user details from response
                const userData = response.data;
                // Save specific details to localStorage
                localStorage.setItem('firstname', userData.firstname);
                localStorage.setItem('staffid', userData.staffid);
                localStorage.setItem('email', userData.email);
                localStorage.setItem('admin', userData.admin);
                localStorage.setItem('loggedInStaffId',userData.staffid);
                localStorage.setItem('createdBranchId',userData.created_branch_id);
             }

         } catch (error) {
             // Show error message for login failure
             toast.error("Invalid email or password");
         }
    };
    
    // Handle input changes and prevent spaces
    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputData({ ...inputData, [name]: value.replace(/\s/g, '') });
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <>
            <div className="auth-page py-4 py-sm-5">
                <Container>
                    <Row className='justify-content-center'>
                        <Col sm={8} lg={5} xxl={4}>
                            <img src={Logo} alt="Logo" className='mx-auto d-block' />

                            <Card className='auth-card'>
                                <Card.Body>
                                    <Row className='justify-content-center'>
                                        <Col md={9}>
                                            <div className='text-center'>
                                                <h2 className='mb-3'>Welcome Back</h2>
                                                <p className='text-muted mb-5 fs-17'>Hey, Enter your details to sign in to your account.</p>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Form onSubmit={handleLogin}>
                                        <Row>
                                            <Col sm={12}>
                                                <div className="mb-3 custom-form-input">
                                                    <Form.Label>Email ID or User ID</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Enter Email or User..."
                                                        name='email'
                                                        id='email'
                                                        value={inputData.email}
                                                        onChange={handleChange}
                                                        
                                                    />
                                                </div>
                                                <div className="mb-3 custom-form-input">
                                                    <Form.Label>Password</Form.Label>
                                                    <Icon
                                                        path={showPassword ? mdiEyeOutline : mdiEyeOffOutline}
                                                        className="icon-size-18 input-ps-icon"
                                                        onClick={togglePasswordVisibility}
                                                        style={{ cursor: "pointer" }}
                                                    />                                                   
                                                     <Form.Control
                                                        type={showPassword ? "text" : "password"}
                                                        placeholder="Enter password..."
                                                        name='password'
                                                        id='password'
                                                        value={inputData.password}
                                                        onChange={handleChange}
                                                        
                                                    />

                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className='text-center'>
                                                <Button type="submit" className='btn btn-primary btn-lg btn-w-xxl'>
                                                    <Icon path={mdiAccountPlusOutline} className="btn-icon me-1" /> Login
                                                </Button>

                                                <Link to="#" className='text-muted d-block mt-4'>Forgot your password?</Link>

                                                <div className='or-text my-4'>
                                                    <p className='text-muted or-text-border'>Or sign in with</p>
                                                </div>

                                                <div className='btn-list'>
                                                    <Button variant="outline-dark icon-with-btn btn-lg">
                                                        <Icon path={mdiGoogle} className="icon-size-17 me-1" /> Google
                                                    </Button>{' '}
                                                    <Button variant="outline-dark icon-with-btn btn-lg">
                                                        <Icon path={mdiApple} className="icon-size-17 me-1" /> Apple
                                                    </Button>{' '}
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Card.Body>
                            </Card>

                            <div className='text-center mt-4'>
                                <p className='mb-0 text-muted'>Don't have an account? <Link to="/sign_up" className='text-primary fw-semibold ms-1'>Sign Up</Link></p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}
